import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .checkout-page {
        .checkout-page-area {
            padding: 80px 0 120px;

            .checkout-description-box {
               
                h3 {
                    color    : ${colors.black2};
                    font-weight : 600;
                    margin-bottom: 20px;

                    @media(max-width: 575px) {
                        font-size : 22px;
                    }
                }
                p {
                    font-size : 17px;
                    color : ${colors.text1};
                    font-weight: 500;
                    margin-bottom: 40px;
                }
                
            }

            .pricing {
                box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                text-align: center;
                //border: 1px solid #f0f0f0;
                color: #777;
                font-size: 14px;
                padding-left: 0;
                margin-bottom: 30px;
                max-width: 400px;
                margin-right: auto;
                margin-left: auto;
              font-family: 'Lato';
            }
            .pricing img {
                display: block;
                margin: auto;
                width: 32px;
            }

            .pricing i {
                display: block;
                margin: auto;
                font-size     : 20px;
                color         : ${colors.green};
                transition : all 0.2s ease;

            }

            .pricing li:first-child,
            .pricing li:last-child {
                padding: 20px 13px;
            }
            .pricing li {
                list-style: none;
                padding: 13px;
            }
            .pricing li + li {
                border-top: 1px solid #f0f0f0;
            }
            .pricing big {
                display: block;
                font-size: 32px;
            }
            .pricing .pricing-medium {
                font-size: 20px;
            }
            .pricing h3 {
                margin-bottom: 0;
              font-size: 36px;
            }
            .pricing span {
                font-size: 12px;
                color: #999;
                font-weight: normal;
            }
            .pricing li:nth-last-child(2) {
                padding: 30px 13px;
            }
            .pricing button {
                font-size: 18px;
                background : ${colors.green};
                color : #ffffff;
                padding: 12px 30px;
                font-weight: 500;
                border-radius: 5px;
                display : inline-block;
                border: none;
                i {
                    margin-right: 6px;
                }
                &:hover {
                    background : ${colors.green};
                }

                @media(max-width: 575px) {
                    font-size: 15px;
                    padding: 10px 20px;
                }
            }


            // .pricing button:hover {
            //     opacity: .9;
            // }
            // .pricing button:active {
            //     box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);
            // }
            /* pricing color */
            .p-green big,
            .p-green .pricing-medium,
            .p-green h3 {
                color: ${colors.green};
            }
            .p-green i {
                color: ${colors.green};
            }
            .p-green button {
                background: ${colors.green};
            }
            .p-yel big,
            .p-yel .pricing-medium,
            .p-yel h3 {
                color: ${colors.yellow};
            }
            .p-yel i {
                color: ${colors.yellow};
            }
            .p-yel button {
                background: ${colors.yellow};
            }
            .p-red big,
            .p-red .pricing-medium,
            .p-red h3 {
                color: ${colors.red};
            }
            .p-red i {
                color: ${colors.red};
            }
            .p-red button {
                background: ${colors.red};
            }
            .p-blue big,
            .p-blue .pricing-medium,
            .p-blue h3 {
                color: ${colors.blue};
            }
            .p-blue i {
                color: ${colors.blue};
            }
            .p-blue button {
                background: ${colors.blue};
            }





            a {
                font-family: inherit;
                font-size: inherit;
                color: inherit;
                padding-left:5px;
                padding-right: 5px;

                background-color: #4CAF50;
                color: white;
                padding: 2px 5px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                margin: 4px 2px;
                cursor: pointer;
                border-radius: 5px;
            }

            a:hover {
                transform: scale(0.95); /* slightly reduces the size of the button */
                box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3); /* adds a shadow around the button */
            }







            

        }

        
    }
`;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const loadGroups = createAsyncThunk(
    'refs/loadGroups',
    async () => {
        return fetch('/.netlify/functions/groups')
        .then(res => res.json());
    }
);

export const loadTags = createAsyncThunk(
    'refs/loadTags',
    async () => {
        return fetch('/.netlify/functions/tags')
        .then(res => res.json());
    }
);

export const loadCategories= createAsyncThunk(
    'refs/loadCategories',
    async () => {
        return fetch('/.netlify/functions/categories')
        .then(res => res.json());
    }
);

export const loadComplexity= createAsyncThunk(
    'refs/loadComplexity',
    async () => {
        return fetch('/.netlify/functions/complexity')
        .then(res => res.json());
    }
);

export const loadLessonsFaq = createAsyncThunk(
    'refs/loadLessonsFaq',
    async () => {
        return fetch('/.netlify/functions/lessons_faq')
        .then(res => res.json());
    }
);

const refsSlice = createSlice({
    name: 'refs',
    initialState: {
        statusGroups: "",
        statusTags: "",
        statusCategories: "",
        statusComplexity: "",
        statusLessonsFaq: "",
        groups: [],
        tags: [],
        categories: [],
        complexity: [],
        lessonsFaq: [],
    },

    extraReducers: {
        [loadGroups.pending]: (state) => {
            state.statusGroups = 'loading';
        },
        [loadGroups.fulfilled]: (state, action) => {
            state.groups = action.payload;
            state.statusGroups = 'success';
        },
        [loadGroups.rejected]: (state) => {
            state.statusGroups = 'failed';
        },
        [loadTags.pending]: (state, action) => {
            state.statusTags = 'loading';
        },
        [loadTags.fulfilled]: (state, action) => {
            state.tags = action.payload;
            state.statusTags = 'success';
        },
        [loadTags.rejected]: (state, action) => {
            state.statusTags = 'failed';
        },
        [loadCategories.pending]: (state, action) => {
            state.statusCategories = 'loading';
        },
        [loadCategories.fulfilled]: (state, action) => {
            state.categories = action.payload;
            state.statusCategories = 'success';
        },
        [loadCategories.rejected]: (state, action) => {
            state.statusCategories = 'failed';
        },
        [loadComplexity.pending]: (state, action) => {
            state.statusComplexity = 'loading';
        },
        [loadComplexity.fulfilled]: (state, action) => {
            state.complexity = action.payload;
            state.statusComplexity = 'success';
        },
        [loadComplexity.rejected]: (state, action) => {
            state.statusComplexity = 'failed';
        },
        [loadLessonsFaq.pending]: (state, action) => {
            state.statusLessonsFaq = 'loading';
        },
        [loadLessonsFaq.fulfilled]: (state, action) => {
            state.lessonsFaq = action.payload;
            state.statusLessonsFaq = 'success';
        },
        [loadLessonsFaq.rejected]: (state, action) => {
            state.statusLessonsFaq = 'failed';
        },
    }
})

export default refsSlice.reducer
import React from 'react';
import Lessons from './Lessons';

function MenuCategory({ category, showFilter, ...props }) {

    return (
        <Lessons {...props} category={category} showFilter={showFilter} />
    )
}

export default MenuCategory;
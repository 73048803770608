import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/groupsBox.js";
import { connect } from 'react-redux';
import i18n from '../i18n';
import { withRouter } from 'react-router';



const mapStateToProps = (state) => {
    return {
        groups: state.refs.groups,
        categories : state.refs.categories,
    }
}

const icons = {
    "0": "fab fa-waze",
    "1": "fab fa-github-alt",
    "2": "far fa-gem"
}

class GroupsBox extends Component {
    constructor(props) {
        super(props);
    }

    onGroupSelect = function(e){
        let category = this.props.categories && 
        this.props.categories.find(elem => elem.id === 'online')
        
        let target = e.target
        while (!target.getAttribute("group-id")){
            target = target.parentNode;
        }

        let groupId = target.getAttribute("group-id")
       
        const filter = localStorage.getItem("lessons-filter") ? JSON.parse(localStorage.getItem("lessons-filter")) : {};
        
        if (!filter.online){
            filter.online = {}
        }
        filter.online.groups = [groupId]
        localStorage.setItem("lessons-filter",JSON.stringify(filter))
        
        this.props.history.push(category.url)
    }
    render() {

        return (
            <Styles>
                {/* Icon Box */}
                <section className="group-box-area">
                    <Container>
                        <Row>
                            {
                                this.props.groups
                                .filter(elem => elem.onBoxPage)
                                .map((data, i) => (
                                    <Col md="4" key={i}>
                                        
                                        <div className="full-group-box" onClick={this.onGroupSelect.bind(this)} group-id={data.id}>
                                            <div className="group-box d-flex">
                                                <div className={'box-icon ' + 'box'+i}>
                                                    <i className={icons[i]}></i>
                                                </div>
                                                <div className="box-title">
                                                    <h6>{data.name[i18n.language]}</h6>
                                                    <p>{data.description[i18n.language]}</p>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </Col>
                                ))
                            }
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default withRouter(connect(mapStateToProps)(GroupsBox))

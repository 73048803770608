import React, { Component } from 'react';
import Datas from '../data/course/slider.json';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Swiper from 'react-id-swiper';
import { Styles } from "./styles/courseSlider.js";
import i18n from '../i18n'

class CourseSlider extends Component {

    constructor(props) {
        super(props);
    
        this.state = {courses: []};
      }
    
      componentDidMount() {
        fetch('/.netlify/functions/courses?onslider=true')
        .then(res => res.json())
        .then(results => this.setState({ courses: results }));
      }
    
   
    render() {
        
        const settings = {
            slidesPerView: 3,
            loop: true,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            spaceBetween: 30,
            watchSlidesVisibility: true,
            pagination: {
                el: '.slider-dot.text-center',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                992: {
                    slidesPerView: 3
                }
            },
            rebuildOnUpdate: true
        };

        return (
            <Styles>
                {/* Course Slider */}
                <section className="course-slider-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{Datas.title[i18n.language]}</h4>
                                </div>
                            </Col>
                            <Col md="12" className="course-slider">
                                <Swiper {...settings}>
                                    {
                                        this.state.courses.map((data, i) => (

                                            <div className="course-item" key={i}>
                                                <a href={data.promoPrice && data.promoPrice.link?data.promoPrice.link:data.link}>
                                                    <div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})` }}>
    
                                                        <div className="course-price">
                                                            <p>{data.promoPrice? data.promoPrice[i18n.language] : data.price[i18n.language]}</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div className="course-content">
                                                    <h6 className="heading"><a href={data.promoPrice && data.promoPrice.link?data.promoPrice.link:data.link}>{data.courseTitle[i18n.language]}</a></h6>
                                                    <div className="course-face d-flex justify-content-between">
                                                        <div className="duration">
                                                            <p><i className="las la-globe-africa"></i>{data.language.name[i18n.language]}</p>
                                                        </div>
                                                        <div className="rating">
                                                            <ul className="list-unstyled list-inline">
                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                <li className="list-inline-item"><i className="las la-star"></i></li>

                                                                {data.rating === "4.5" &&
                                                                    <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                                                }
                                                                {data.rating === "5" &&
                                                                    <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                }
                                                                <li className="list-inline-item">({data.rating})</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </Swiper>
                            </Col>
                            <Col md="12" className="text-center">
                            <div className="viewall-btn">
                                <Link to={process.env.PUBLIC_URL + "/courses"}>{Datas.coursesLink[i18n.language]}</Link>
                                <Link to={process.env.PUBLIC_URL + "/lessons"}>{Datas.lessonsLink[i18n.language]}</Link>
                            </div>
                        </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default CourseSlider

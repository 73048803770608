import React, { Component } from 'react';
import Datas from '../data/about-us/about-us.json';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/aboutUs.js";
import i18n from "../i18n"

class AboutUsTwo extends Component {
    render() {
        return (
            <Styles>
                {/* About Us 2 */}
                <section className="about-us2">
                    <Container>
                        <Row>
                            <Col md="8">
                                <div className="about-content">
                                    <h4 className="about-title">{Datas.secTitle[i18n.language]} <br /> {Datas.secTitle2[i18n.language]}</h4>
                                    <p className="about-para">
                                        {Datas.secDesc[i18n.language] &&
                                            Datas.secDesc[i18n.language].map((text) => (
                                                text ? <div>{text}</div> : <br />
                                            ))
                                        }
                                    </p>

                                </div>
                            </Col>
                            <Col md="4">
                                <div className="about-image">
                                    <img src={process.env.PUBLIC_URL + `/assets/images/${Datas.aboutImageOne}`} className="main-img1" alt="" />
                                </div>
                            </Col>
                            {/* <Col md="12" className="text-left">
                            <div className="viewfaq-btn">
                                <Link to={process.env.PUBLIC_URL + "/faq"}>{Datas.faqLink[i18n.language]}</Link>
                            </div>
                        </Col> */}
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default AboutUsTwo

import React, { Component } from 'react';
import Datas from '../../data/faq/faq.json';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import Header from '../../components/Header';
import { Styles } from './styles/faq.js';
import i18n from '../../i18n';
import Footer from '../../components/Footer';
import ModalImage from "react-modal-image";

class Faq extends Component {


    render() {
        const onFaqItemClick = function(event){
            let button = event.target;
            while (!button.classList.contains("accordion-button")){
                button = button.parentNode;
            }
//            console.log(button);

            button.classList.toggle("active");
            const content = button.parentNode.parentNode.parentNode.children[1].firstChild.firstChild;
           
            if (button.classList.contains("active")) {
                content.className = "accordion-content show";
                button.firstChild.firstChild.className = "las la-minus-circle"
            } else {
                content.className = "accordion-content";
                button.firstChild.firstChild.className = "las la-plus-circle"
            }

        };
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper faq-page">

                    {/* Header 2 */}
                    <Header />

                    {/* Faq Area */}
                    <section className="faq-area">
                        <Container>
                            <Row>
                                {
                                    Datas.secTitle &&
                                    <Col md="12">
                                            <div className="sec-title  text-center">
                                                <h4>{Datas.secTitle[i18n.language]}</h4>
                                            </div>
                                    </Col>
                                }
  
                      {/* третий вопрос */}                                          
                      <div className="faq-item">
                                                <Row><Col md="12">
                                                <button className="accordion-button" onClick={onFaqItemClick}>
                                                    <div className="accordion-icon"><i className="las la-plus-circle"></i></div>
                                                    <p>{Datas.questions.third.name[i18n.language]}</p>
                                                </button>
                                                </Col></Row>
                                                <Row><Col md="12">
                                                <div className="accordion-content">
    {i18n.language === 'en' &&
  <div className="faq-item-line"> 
<br/><a href="https://youtu.be/3h1Idowq67c"><img src={process.env.PUBLIC_URL + `/assets/images/faq/materials-01.jpg`} alt=""  className="youtube-img"/></a>
<br/>- video review about materials –
<br/>
<br/>✔️	Plasticine
<br/>✔️	Toothpicks
<br/>✔️	Stack (knife)
<br/>✔️	Pencil
<br/>✔️	Ruler
<br/>✔️	Wet wipes. For cleaning hands, tools and table surfaces.
<br/>
<br/>Many people ask about the table cover
<br/>1.	It is enough if the table is covered with ordinary oilcloth.
<br/>2.	If you still want a mat like mine (Cutting mat A2), you can find it in specialized stores or online
<br/><i>UK - <a href="https://amzn.to/2R24FWh">https://amzn.to/2R24FWh</a> </i>
<br/><i>US - <a href="https://amzn.to/3dBywwq">https://amzn.to/3dBywwq</a> </i>
<br/>
<br/>For making pictures, I use cardboard with a thickness of at least 2-3mm
<br/><i>UK - <a href="https://amzn.to/3cQkfwU">https://amzn.to/3cQkfwU</a></i> 
<br/><i>US - <a href="https://amzn.to/3wtYBpJ">https://amzn.to/3wtYBpJ</a> </i>
<br/>
<br/>
      </div>
    }

{i18n.language === 'ru' &&
   
   <div className="faq-item-line"> 
<br/><a href="https://youtu.be/3h1Idowq67c"><img src={process.env.PUBLIC_URL + `/assets/images/faq/materials-01.jpg`} alt=""  className="youtube-img"/></a> 
<br/>- видео-обзор про материалы – 
<br/>
<br/>✔️	Пластилин
<br/>✔️	Зубочистки
<br/>✔️	Стек (ножичек)
<br/>✔️	Карандаш
<br/>✔️	Линейка
<br/>✔️	Влажные салфетки. Используются для очистки рук, инструментов и поверхности стола.
<br/>
<br/>Многие спрашивают про защиту стола
<br/>1.	Достаточно, если стол покрыт обычной клеенкой.
<br/>2.	Если все же хочется коврик как у меня (Cutting mat A2), то его можно найти в специализированных магазинах или онлайн
<br/><i>UK - <a href="https://amzn.to/2R24FWh">https://amzn.to/2R24FWh</a></i>
<br/><i>US - <a hef="https://amzn.to/3dBywwq">https://amzn.to/3dBywwq</a></i>
<br/>
<br/>При лепке картин использую картон, толщиной не менее 2-3мм
<br/><i>UK - <a href="https://amzn.to/3cQkfwU">https://amzn.to/3cQkfwU</a> </i>
<br/><i>US - <a href="https://amzn.to/3wtYBpJ">https://amzn.to/3wtYBpJ</a> </i>
<br/>
<br/>
    </div>
    }
                                                </div>
                                                </Col></Row>
                                            </div>
                    {/* третий вопрос */}     

                    {/* первый вопрос */}                                          
                                            <div className="faq-item">
                                                <Row><Col md="12">
                                                <button className="accordion-button" onClick={onFaqItemClick}>
                                                    <div className="accordion-icon"><i className="las la-plus-circle"></i></div>
                                                    <p>{Datas.questions.first.name[i18n.language]}</p>
                                                </button>
                                                </Col></Row>
                                                <Row><Col md="12">
                                                <div className="accordion-content">
    {i18n.language === 'en' &&
  <div className="faq-item-line"> 
  <br/><a href="https://youtu.be/2PR-NvbFfuE"><img src={process.env.PUBLIC_URL + `/assets/images/faq/review-01.jpg`} alt="" className="youtube-img"/></a> 
  <br/>- video about hardness –
  <br/>
  <br/>In terms of hardness, plasticine can be:
  <br/>
  <br/>⭐ <b>SOFT</b> ⭐
  <br/>Suitable for making flat products - paintings, appliques, etc. It is easy to smear over the surface.
  <br/><i>UK - Jovi, Colourclay, Plasticine</i>
  <br/><i>US - Jovi, Plasticine, Prang</i>
  <br/>Extra soft plasticine – not suitable for figurines making.
  <br/><i>UK – Pelikan</i>
  <br/><i>US – Claytoon</i>
  <br/>
  <br/>
  <br/>⭐ <b>HARD</b> ⭐
  <br/>Great for sculpture and figurines.
  <br/>To knead/warm such plasticine, you need to make a lot of efforts. But, having taken the necessary shape and cooled down, it becomes almost "stone".
  <br/><i>UK - Giotto Pongo, Newplast</i>
  <br/><i>US -</i>
  <br/><i>Russia - Gamma, Ray</i>
  <br/>
  <br/><b>Of course, it is convenient when you have several packs at hand at once, but it is not necessary to buy all the sets of plasticine. One box is enough to start. For babies, take any soft plasticine of your choice.</b>
  <br/>
  <br/>
  <br/>See my channel for a full review with links.
  <br/><a href="https://youtu.be/w58HouEYT-k">- Review of plasticine in the UK / Europe – </a> 
  <br/><a href="https://youtu.be/Z26KEUVIZ_w">- Review of plasticine in America –</a>
  <br/>
  <br/>

      </div>
    }

{i18n.language === 'ru' &&
   
   <div className="faq-item-line"> 
   <br/> <a href="https://youtu.be/2PR-NvbFfuE"><img src={process.env.PUBLIC_URL + `/assets/images/faq/review-01.jpg`} alt=""  className="youtube-img"/></a>
   <br/> - видео про твердость – 
   <br/> 
   <br/> По жесткости пластилин можно разбить на несколько категорий:
   <br/>   
   <br/> ⭐ <b>МЯГКИЙ</b> ⭐
   <br/> Подходит для изготовления плоских изделий – картин, аппликаций и др. Его легко размазывать по поверхности.
   <br/> <i>UK – Jovi, Colourclay, Plasticine</i>
   <br/> <i>US –Jovi, Plasticine, Prang</i>
   <br/> Пластилин, который тает в руках. Работать с ним невозможно.
   <br/> <i>UK – Pelikan</i>
   <br/> <i>US – Claytoon</i>
   <br/> 
   <br/> ⭐ <b>СРЕДНЕЙ ЖЕСТКОСТИ</b> ⭐
   <br/> Тверже, чем пластилин с мягкой структурой, но работать с ним легче, чем с твердыми сортами. Идеально подойдет, чтобы делать фигурки с малышами. А взрослые смогут лепить и картины.
   <br/> <i>UK – Plastilina, Knetmasse, Giotto Patplum</i>
   <br/> <i>US – Crayola, Sargent</i>
   <br/> 
   <br/> ⭐ <b>ТВЁРДЫЙ</b> ⭐
   <br/> Великолепен для скульптуры и фигурок. 
   <br/> Чтобы размять/согреть такой пластилин необходимо приложить довольно много усилий. Но, приняв необходимую форму и остыв, становится практически "каменным"
   <br/> <i>UK – Giotto Pongo, Newplast</i>
   <br/> <i>US – твердых марок не обнаружено</i>
   <br/> <i>Russia – Гамма, Луч</i>
   <br/>
   <br/><b>Конечно, удобно, когда под рукой у тебя есть сразу несколько пачек, но покупать все наборы пластилина не обязательно. Для начала достаточно одной пачки. Для малышей берите любой мягкий пластилин, на ваш выбор.</b>
   <br/>
   <br/>
   <br/>Полный видео-обзор с ссылками на материал смотрите ниже
   <br/><a href="https://youtu.be/-qyidehmVro">- Обзор пластилина по Великобритании / Европе – </a>
   <br/><a href="https://youtu.be/Z26KEUVIZ_w">- Обзор пластилина по Америке –</a>
   <br/>
   <br/>

    </div>
    }




                                                </div>
                                                </Col></Row>
                                            </div>
                    {/* первый вопрос */}                                          


                    {/* втрой вопрос */}                                          
                    <div className="faq-item">
                                                <Row><Col md="12">
                                                <button className="accordion-button" onClick={onFaqItemClick}>
                                                    <div className="accordion-icon"><i className="las la-plus-circle"></i></div>
                                                    <p>{Datas.questions.second.name[i18n.language]}</p>
                                                </button>
                                                </Col></Row>
                                                <Row><Col md="12">
                                                <div className="accordion-content">
    {i18n.language === 'en' &&
  <div className="faq-item-line"> 
<br/>  <a href="https://youtu.be/MrCveN__yoY"><img src={process.env.PUBLIC_URL + `/assets/images/faq/alternative-01.jpg`} alt=""  className="youtube-img"/></a> 
<br/> - video about alternatives –
<br/>
<br/>✔️ In group lessons, it is best when all participants work with the same material because the specifics of working with different materials are different.
<br/>✔️ During private lessons, or if you are watching a course on tape, you can try experimenting.
<br/>
<br/>
      </div>
    }

{i18n.language === 'ru' &&
   
   <div className="faq-item-line"> 
   <br/><a href ="https://youtu.be/MrCveN__yoY"><img src={process.env.PUBLIC_URL + `/assets/images/faq/alternative-01.jpg`} alt=""  className="youtube-img"/></a>
   <br/>- видео про альтернативы – 
   <br/>
   <br/>✔️ На групповых занятиях лучше, когда все участники работают с одинаковым материалом. Потому что специфика работы с разными материалами отличается.
   <br/>✔️ Во время индивидуальных занятий, или если вы смотрите урок в записи, можно попробовать поэкспериментировать.
   <br/>   
   <br/>
    </div>
    }




                                                </div>
                                                </Col></Row>
                                            </div>
                    {/* второй вопрос */}     


                    {/* 4th вопрос */}                                          
                    <div className="faq-item">
                                                <Row><Col md="12">
                                                <button className="accordion-button" onClick={onFaqItemClick}>
                                                    <div className="accordion-icon"><i className="las la-plus-circle"></i></div>
                                                    <p>{Datas.questions.four.name[i18n.language]}</p>
                                                </button>
                                                </Col></Row>
                                                <Row><Col md="12">
                                                <div className="accordion-content">
    {i18n.language === 'en' &&
  <div className="faq-item-line"> 
    <br/>A good question, because the kid will hardly be able to make a beautiful figurine from a large heap of pieces piled together.
    <br/>
    <br/>Therefore, sorting by colors is simply necessary:
    <br/>✔️ First, the kid easily finds the color he/she needs.
    <br/>✔️ Secondly, you can see what color is coming at an end.
    <br/>✔️ Thirdly, the orderly storage of materials is the basis of aesthetic education.
    <br/>
    <br/><b>Wherein to store</b>
    <br/>💡 The product of a domestic manufacturer is packed in boxes with cells. Buy 3-4 identical sets at once and sort them according to the color principle.
    <br/>💡 Plastic organiser. Can be found in any hobby stores.
    <br/>💡 Zip packages. They are transparent, so you to see the contents. Besides, they are securely closed, so the material will not dry up and get dusty.
    <br/>💡 Plastic containers (or jars for sour cream). They have a tight-fitting lid and compact dimensions.
    <br/>
    <br/>
    <div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-01-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-01.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-02-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-02.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-03-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-03.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-04-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-04.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-05-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-05.jpg`} alt=""  className="inline-img"/>
</div>
    Please, remember that high temperature and dust are the main factors affecting the quality of plasticine.
    <br/>
    <br/>
      </div>
    }

{i18n.language === 'ru' &&
   
   <div className="faq-item-line"> 
<br/>Хороший вопрос, потому как вряд ли малыш сможет слепить красивую фигурку из большой кучи кусков, сваленых вместе.
<br/>
<br/>Поэтому сортировка по цветам просто необходима:
<br/>✔️ Во-первых ребёнок сразу находит тот цвет, который ему необходим.
<br/>✔️ Во-вторых, видно какой цвет закончился.
<br/>✔️ В-третьих, упорядоченное хранение материалов - основа эстетического воспитания.
<br/>
<br/><b>В чём хранить </b>
<br/>💡 Продукт отечественного производителя упакован в коробки с ячейками. Купите сразу 3-4 одинаковых набора и рассортируйте их по цветовому принципу. 
<br/>💡 Пластиковые органайзеры. Можно найти в любых хобби-магазинах.
<br/>💡 Зип-пакеты. Они прозрачные, что позволяет видеть содержимое, кроме того, надежно закрываются и не позволяют материалу пересыхать и пылиться.
<br/>💡 Пластиковые контейнеры (или баночки из-под сметаны). Имеют плотно прилегающую крышечку и компактные размеры.
<br/>
<br/>
<div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-01-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-01.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-02-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-02.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-03-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-03.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-04-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-04.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/store-05-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/store-05.jpg`} alt=""  className="inline-img"/>
</div>
Не забывайте, что главные факторы, влияющие на качество пластилина, это повышенная температура и пыль
<br/>
<br/>
    </div>
    }




                                                </div>
                                                </Col></Row>
                                            </div>
                    {/* 4th вопрос */}     

                    {/* 5th вопрос */}                                          
                    <div className="faq-item">
                                                <Row><Col md="12">
                                                <button className="accordion-button" onClick={onFaqItemClick}>
                                                    <div className="accordion-icon"><i className="las la-plus-circle"></i></div>
                                                    <p>{Datas.questions.five.name[i18n.language]}</p>
                                                </button>
                                                </Col></Row>
                                                <Row><Col md="12">
                                                <div className="accordion-content">
    {i18n.language === 'en' &&
  <div className="faq-item-line"> 
<br/>In fact, the options are not numerous:
<br/>💡 On a shelf / on a table
<br/>💡 In a box
<br/>
<br/>Since I am not only an artist, but also a RFL teacher, I think, it is boring and dully just to model figurines. But creating plasticine worlds and inventing stories is not only more exciting, but also more useful ;) 
<br/>This is where our imagination turns on and an ordinary box turns into...
<br/>
<br/>
<div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-01-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-01.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-02-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-02.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-03-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-03.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-04-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-04.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-05-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-05.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-06-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-06.jpg`} alt=""  className="inline-img"/>
</div>
<b>Tip:</b> To prolong the life of plasticine toys, cover them with colorless nail polish. This will give them strength and additionally fasten the parts.
<br/>
<br/>
      </div>
    }

{i18n.language === 'ru' &&
   
   <div className="faq-item-line"> 
<br/>Вариантов, на самом деле, не так уж и много:
<br/>💡 На полке / на столе
<br/>💡 В коробке
<br/>
<br/>Так как я не только художник, но и учитель РКИ, то на мой взгляд, просто так лепить фигурки - скучно и не интересно. А, вот создавать пластилиновые миры и придумывать истории не только увлекательней, но и полезней ;) 
<br/>Вот тут то и включается наша фантазия и обычная коробка превращается...
<br/>
<br/>
<div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-01-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-01.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-02-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-02.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-03-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-03.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-04-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-04.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-05-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-05.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/keeping-06-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/keeping-06.jpg`} alt=""  className="inline-img"/>
</div>
<b>Совет</b>: Чтобы продлить жизнь пластилиновыми игрушкам, покройте их бесцветным лаком для ногтей. Это позволит придать им прочность и дополнительно скрепить детали.
<br/>
<br/>
    </div>
    }




                                                </div>
                                                </Col></Row>
                                            </div>
                    {/* 5th вопрос */}     

                    {/* 6th вопрос */}                                          
                    <div className="faq-item">
                                                <Row><Col md="12">
                                                <button className="accordion-button" onClick={onFaqItemClick}>
                                                    <div className="accordion-icon"><i className="las la-plus-circle"></i></div>
                                                    <p>{Datas.questions.six.name[i18n.language]}</p>
                                                </button>
                                                </Col></Row>
                                                <Row><Col md="12">
                                                <div className="accordion-content">
    {i18n.language === 'en' &&
  <div className="faq-item-line"> 
<br/>☝️ Never throw away old plasticine crafts. Get a special box and put everything there. When a lot of toys accumulates, disassemble and sort them out (if possible) by color. Mix each piece up. You will get rich, complex shades that can be reused for new crafts.
<br/>
<br/><b>When the colors can't be separated</b>
<br/>
<br/>💡 If not mix the piece completely, you get "marble stains". With this effect, you can make:
<br/>- A picture frame.
<br/>- Space or sea objects in a picture.
<br/>- Perfect figurines of dragons and dinosaurs.
<br/>- You can build a fortress or a house from the multi-colored "pebbles".
<br/>
<br/>💡 When mixed up very well, there will be a huge piece of one tone suitable for creation of:
<br/>- A base for papier-mache.
<br/>- Folk toys. Carefully coat the plasticine figurines with flour (or tooth powder) and paint with gouache.
<br/>- Model a large base of a craft, and decorate it with fresh, colored plasticine.
<br/>- It is easy to create and come up with new ideas with it - you are no longer distracted by color, but look at the volume and scale.
<br/>
<br/>
<div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/reuse-01-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/reuse-01.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/reuse-02-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/reuse-02.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/reuse-03-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/reuse-03.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/reuse-04-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/reuse-04.jpg`} alt=""  className="inline-img"/>
</div>
<br/>
<br/>
      </div>
    }

{i18n.language === 'ru' &&
   
   <div className="faq-item-line"> 
<br/>☝️ Никогда не выбрасывайте старые поделки из пластилина. Заведите специальную коробку и складывайте всё туда. Когда игрушек накопится много, разберите и рассортируйте их (по возможности) по цветам. Перемешайте каждый комочек. Получатся богатые, сложные оттенки, которые можно переиспользовать для новых поделок.
<br/>
<br/><b>Когда цвета нереально разделить </b>
<br/>
<br/>💡 Если не перемешивать кусок до конца, получаются «мраморные разводы». С таким эффектом можно сделать:
<br/>- Рамку для картины
<br/>- Космические или морские предметы на картине.
<br/>- Прекрасно выходят фигурки драконов и динозавров.
<br/>- Из разноцветных "камушков" можно построить крепость или дом.
<br/>
<br/>💡 Если хорошо перемешать этот пластилин, будет огромный кусок одного тона, из которого можно:
<br/>- Сделать основу для папье-маше.
<br/>- Слепить народные игрушки. Тщательно обмажьте пластилиновые фигурки мукой (или зубным порошком) и раскрасьте гуашью.
<br/>- Смоделировать большую основу поделки, а сверху украсить ее свежим, цветным пластилином.
<br/>- С ним легко творить и придумывать новые идеи - ты уже не отвлекаешься на цвет, а смотришь на объём и масштаб.
<br/>
<br/>
<div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/reuse-01-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/reuse-01.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/reuse-02-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/reuse-02.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/reuse-03-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/reuse-03.jpg`} alt=""  className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/reuse-04-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/reuse-04.jpg`} alt=""  className="inline-img"/>
</div>
<br/>
<br/>
    </div>
    }




                                                </div>
                                                </Col></Row>
                                            </div>
                    {/* 6th вопрос */}     


                    {/* 7th вопрос */}                                          
                    <div className="faq-item">
                                                <Row><Col md="12">
                                                <button className="accordion-button" onClick={onFaqItemClick}>
                                                    <div className="accordion-icon"><i className="las la-plus-circle"></i></div>
                                                    <p>{Datas.questions.seven.name[i18n.language]}</p>
                                                </button>
                                                </Col></Row>
                                                <Row><Col md="12">
                                                <div className="accordion-content">
    {i18n.language === 'en' &&
  <div className="faq-item-line"> 
  <div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-00-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-00.jpg`} alt="" className="inline-img"/>
</div>
<br/>When making plasticine pictures with kids, the background is often not painted over, because this work is boring and tedious.
<br/>
<br/>Of course, it would not matter if greasy spots did not appear over time. They can both come from the reverse side, and frame the figurine itself. And this is expected, as wax is one of the components of plasticine. 
<br/>
<br/>There are several rules, follow them and you will never meet the problem of greasy spots.
<br/>
<br/>❌ Do not draw on normal-type paper, it is too thin and crumples when you start smearing plasticine.
<br/>❌ Do not use rough cardboard. Of course, it is better than paper, and nothing will definitely appear on the back side. But over time, greasy spots appear around the character!!!
<br/>
<br/>✔️ Prepare the surface, as professional artists do. Cover the cardboard with gelatin glue or acrylic primer. You can find it in art stores or at Amazon.
<br/>✔️ In the same way, the surface can be primed with ordinary PVA glue.
<br/>✔️ Cover the cardboard over with self-adhesive film. It can be of incredible shades and you can choose a color palette for any topic. Of course, you can also use ordinary tape, but mind, that it forms joints that are uncomfortable to work with.
<br/>✔️ Optional, the sheets can be laminated.
<br/>✔️ Try to paint over the work surface with wax crayons. This will make the picture bright and eliminate the appearance of spots around the character.
<br/>✔️ Draw on unusual surfaces: on plastic lids, plates, plexiglass, etc.
<br/>
<br/>
<div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-01-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-01.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-02-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-02.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-03-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-03.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-05-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-05.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-07-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-07.jpg`} alt="" className="inline-img"/>
</div>

      </div>
    }

{i18n.language === 'ru' &&
   
   <div className="faq-item-line"> 
  <div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-00-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-00.jpg`} alt="" className="inline-img"/>
</div>
<br/>Делая пластилиновые картины с малышами, часто не закрашивается фон, - потому что работа эта скучная и нудная.
<br/>
<br/>Конечно, это не беда, если бы со временем не проступали жирные пятна. Они могут как проступать с обратной стороны, так и обрамлять саму фигурку. И это ожидаемо, потому что одним из компонентов пластилина является воск. 
<br/>
<br/>Есть несколько правил, следуя которым вы никогда не столкнетесь с проблемой жирных пятен.
<br/>
<br/>❌ Не рисуйте на обычной бумаге, она слишком тонкая и мнется, когда начинаешь размазывать пластилин.
<br/>❌ Не используйте необработанный картон. Конечно, он лучше бумаги, и с обратной стороны точно ничего не проступит. Но со временем жирные пятна появляются вокруг персонажа!!!
<br/>
<br/>✔️ Подготовьте поверхность, как это делают профессиональные художники. Покройте картон желатиновым клеем или акриловым грунтом. Найти его можно в художественных магазинах или на Amazon.
<br/>✔️ Точно так же грунтовку поверхности можно произвести обычным клеем ПВА.
<br/>✔️ Обклейте картонку самоклеящейся пленкой. Она бывает невероятных оттенков и подобрать цветовую палитру можно на любую тему. Конечно, можно для этого использовать и обычный скотч, но учтите, что он образует стыки, работать с которыми не комфортно.
<br/>✔️ Как вариант, листы можно ламинировать.
<br/>✔️ Попробуйте закрасить рабочую поверхность восковыми мелками. Это сделает работу яркой и избавит от появления пятен вокруг персонажа.
<br/>✔️ Рисуйте на необычных поверхностях: на пластмассовых крышках, тарелках, оргстекле и т.д.
<br/>
<br/>
<div style={{display:"flow-root"}}>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-01-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-01.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-02-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-02.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-03-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-03.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-05-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-05.jpg`} alt="" className="inline-img"/>
    <ModalImage hideDownload small={process.env.PUBLIC_URL + `/assets/images/faq/oil-07-small.jpg`} large={process.env.PUBLIC_URL + `/assets/images/faq/oil-07.jpg`} alt="" className="inline-img"/>
</div>

    </div>
    }




                                                </div>
                                                </Col></Row>
                                            </div>
                    {/* 7th вопрос */}     


                    {/* 8th вопрос */}                                          
                    <div className="faq-item">
                                                <Row><Col md="12">
                                                <button className="accordion-button" onClick={onFaqItemClick}>
                                                    <div className="accordion-icon"><i className="las la-plus-circle"></i></div>
                                                    <p>{Datas.questions.eight.name[i18n.language]}</p>
                                                </button>
                                                </Col></Row>
                                                <Row><Col md="12">
                                                <div className="accordion-content">
    {i18n.language === 'en' &&
  <div className="faq-item-line"> 
<br/>Plasticine needs heat to be softened. But the heat should be dry. Put it in a warm and cozy place.
<br/>
<br/>✔️ <b>Warm it up with your hands</b>
<br/>Time permitting  and the volume of plasticine is small, then just hold it in your hands. The body temperature is quite enough to warm the plasticine (of course, the hands must be warm). If at the same time you crumple the plasticine up, then the process would be speeded up.
<br/>✔️ <b>In the sun</b>
<br/>If the sun is shining brightly, then you can put plasticine on the windowsill.
<br/>✔️ <b>On the battery</b>
<br/>If there are hot batteries in the apartment, then you can put plasticine near the battery.
<br/>✔️ <b>In a water bath</b>
<br/>Place the plasticine on a plate and put it on a saucepan with hot water.
<br/>✔️ <b>In warm water</b>
<br/>Take a glass of warm water (in no case with boiling water). Put plasticine of the same color in a bag. Close it and place it in water for 30 seconds. Carefully remove it.
<br/>❌ <b>In the microwave</b>
<br/>Some craftsmen warm up plasticine in the microwave. I do not recommend it . Plasticine is very easy to be overheated, besides, after heat treatment, it becomes brittle.
<br/>
<br/>Of course, in the United States and Great Britain, there are almost no brands so hard that children can hardly stretch. But still, when faced with such a problem, you will know what is to be done.
<br/>
<br/>
      </div>
    }

{i18n.language === 'ru' &&
   
   <div className="faq-item-line"> 
<br/>Пластилину, чтобы размягчиться, необходимо тепло. Но тепло должно быть сухим. Положите его там, где тепло и уютно.
<br/>
<br/>✔️ <b>Разогреть руками</b>
<br/>Если позволяет время и объем пластилина не большой, то просто подержите его в руках. Температуры тела вполне достаточно, чтобы согреть пластилин (конечно, надо чтобы руки сами были теплые). Если при этом пластилин мять, то процесс можно ускорить.
<br/>✔️ <b>На солнышке</b>
<br/>Если на улице ярко светит солнышко, тогда можно положить пластилин на подоконник.
<br/>✔️ <b>На батарее</b>
<br/>Если в квартире горячие батареи, то можно пластилин положить около батареи.
<br/>✔️ <b>На водяной бане</b>
<br/>Поместите пластилин в тарелку и поставьте ее на кастрюлю, в которую налита горячая вода.
<br/>✔️ <b>В теплой воде</b>
<br/>Возьмите стакан с теплой водой (ни в коем случае не с кипятком). Положите пластилин одного цвета в пакет. Закройте и поместите на 30 секунд в воду. Аккуратно достаньте.
<br/>❌ <b>В микроволновке</b>
<br/>Некоторые умельцы разогревают пластилин в микроволновке. Я не советую этого делать. Пластилин очень легко перегреть, к тому же после термической обработки он становится хрупким.
<br/>
<br/>Конечно, в США и Великобритании почти нет настолько твердых марок, чтобы их с трудом могли размять дети. Но все же столкнувшись с такой проблемой, вы будете знать что делать.
<br/>
<br/>
    </div>
    }




                                                </div>
                                                </Col></Row>
                                            </div>
                    {/* 8th вопрос */}     

                            </Row>
                        </Container>
                    </section>

                    {/* Footer */}
                    <Footer/>

                </div>
            </Styles>
        )
    }
}

export default Faq
import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .top-bar2 {
        background : ${colors.copy_bg};
        height       : 40px;
        border-bottom: 1px solid ${colors.border2};

        .bar-left {
            ul {
                li {
                    font-size  : 14px;
                    color      : ${colors.text4};
                    border-left: 1px solid ${colors.border2};
                    padding    : 10px 15px;
                    margin     : 0;
                    display    : inline-block;
                    a {
                        font-size: 14px;
                        color    : ${colors.text4};
                        &:hover {
                            color: ${colors.green};
                        }
                    }
                    i {
                        font-size     : 20px;
                        color         : ${colors.green};
                        vertical-align: bottom;
                        margin-right  : 5px;
                    }
                    &:last-child {
                        border-right: 1px solid ${colors.border2};
                    }
                  
                }
            }
        }

        .bar-right {
            
            ul {
                padding: 8px 0;
                li {
                    font-size : 13px;
                    font-weight : 500;
                    text-transform : uppercase;
                    color : ${colors.text4};
                    a {
                        
                        color : ${colors.green};

                        &:hover {
                            color : ${colors.text4};
                        }
                    }
                    //delimiter
                    &:nth-child(2) { 
                        color : ${colors.text3};
                    }
                    i {
                        font-size     : 20px;
                        color         : ${colors.green};
                        vertical-align: bottom;
                        margin-right  : 5px;
                        &:hover {
                            color : ${colors.text4};
                        }
                    }

                }
            }

        }
        
        @media(max-width: 767px) {
            display: none;
        }
    }

    .logo-area2 {
        background : ${colors.white};



        .logo {
            a {
                img {
                    height: 50px;
                }
            }
        }

        div.menu-box {
            ul.nav.menu-nav {
                li.nav-item {
                    position: relative;

                    a.nav-link {
                        font-size     : 14px;
                        color         : ${colors.text3};
                        text-transform: uppercase;
                        font-weight   : 500;
                        padding       : 10px 10px 10px;

                        i {
                            font-size: 12px;
                        }
                        
                        &:after {
                            content: none;
                        }

                        &:hover {
                            color: ${colors.green};
                        }
                        
                        @media(max-width: 991px) {
                            padding-right : 5px;
                        }
                    }

                    ul.dropdown {
                        position  : absolute;
                        left      : 0;
                        top       : 100%;
                        min-width : 190px;
                        background: #fff;
                        border    : 1px solid ${colors.border1};
                        text-align: left;
                        padding   : 0;
                        border-radius : 5px;
                        transition : 0.2s ease;
                        opacity         : 0;
                        transform       : scaleY(0);
                        visibility      : hidden;
                        z-index         : 999;
                        transform-origin: center top 0;

                        li.nav-item {
                            position: relative;

                            a.nav-link {
                                font-size     : 13px;
                                color         : ${colors.text1};
                                padding       : 10px 10px;
                                text-transform: capitalize;
                                font-weight   : 400;
                                margin-right  : 0;
                                border-bottom : 1px solid ${colors.border1};

                                &:hover {
                                    color: ${colors.green};

                                    i {
                                        color: #fff;
                                    }
                                }

                                i {
                                    float     : right;
                                    margin-top: 4px;
                                }
                            }

                            &:last-child {
                                margin-left: 0;

                                a.nav-link {
                                    border-bottom: none;
                                }
                            }

                            ul.dropdown2 {
                                position  : absolute;
                                top       : 0;
                                left      : 100%;
                                min-width : 180px;
                                background: #fff;
                                border    : 1px solid ${colors.border1};
                                transition : 0.2s ease;
                                opacity         : 0;
                                transform       : scaleY(0);
                                visibility      : hidden;
                                z-index         : 999;
                                transform-origin: center top 0;
                            }

                            &:hover {
                                ul.dropdown2 {
                                    opacity   : 1;
                                    transform : scaleY(1);
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    &:hover {
                        ul.dropdown {
                            opacity   : 1;
                            transform : scaleY(1);
                            visibility: visible;
                        }
                    }
                }
            }


        }

        @media(max-width: 767px) {
            display: none;
        }
    }
`;
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { Styles } from "./styles/mobileMenu.js";
import i18n from '../../i18n';
import { useAuth0 } from '@auth0/auth0-react'
import { useStore } from 'react-redux';

function MobileMenu() {

    useEffect(() => {
        // Mobile Menu
        const hmBtn = document.getElementById("mb-sidebar-btn");

        if (hmBtn) {
            const mdSidebarOverlay = document.getElementById("mb-sidebar-overlay");
            const mdSidebarBody = document.getElementById("mb-sidebar-body");
            const mdSidebarExit = document.getElementById("close-mb-sidebar");

            if (!hmBtn.hasAttribute("data-event-onclick")) {
                hmBtn.addEventListener("click", function (e) {
                    e.preventDefault();
                    mdSidebarOverlay.classList.toggle("visible");
                    mdSidebarBody.classList.toggle("opened");
                });
                hmBtn.setAttribute("data-event-onclick", "true");
            }

            if (!mdSidebarOverlay.hasAttribute("data-event-onclick")) {
                mdSidebarOverlay.addEventListener("click", function (e) {
                    e.preventDefault();
                    mdSidebarOverlay.classList.remove("visible");
                    mdSidebarBody.classList.remove("opened");
                });
                mdSidebarOverlay.setAttribute("data-event-onclick", "true");
            }

            if (!mdSidebarExit.hasAttribute("data-event-onclick")) {
                mdSidebarExit.addEventListener("click", function (e) {
                    e.preventDefault();
                    mdSidebarOverlay.classList.remove("visible");
                    mdSidebarBody.classList.remove("opened");
                });
                mdSidebarExit.setAttribute("data-event-onclick", "true");
            }
        }

        // Menu Accordion -----------------
        const menuButton = document.querySelectorAll(".mb-menu-button");
        menuButton.forEach(button => {
            button.addEventListener("click", () => {
                button.classList.toggle("active");
                const content = button.nextElementSibling;

                if (button.classList.contains("active")) {
                    content.className = "mb-menu-content show";
                    content.style.maxHeight = content.scrollHeight + "px";
                } else {
                    content.className = "mb-menu-content";
                    content.style.maxHeight = "0";
                }
            });
        });
    });
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const store = useStore()

    return (
        <Styles>
            {/* Mobile Menu */}
            <section className="mobile-menu-area">
                <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Row>
                        <Col md="0" sm="12" className="align-items-center">
                            <div className="mb-topbar d-flex justify-content-between">
                                <div className="topbar-item">

                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><a href="https://www.facebook.com/crazyplasticine"><i className="fab fa-facebook"></i></a></li>
                                        <li className="list-inline-item"><a href="https://www.instagram.com/crazy_plasticine/"><i className="fab fa-instagram"></i></a></li>
                                        <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCZVWFzGD7hDsYsvzc4nxPkw"><i className="fab fa-youtube"></i></a></li>
                                    </ul>

                                </div>
                                <div className="topbar-item">
                                    <ul className="list-unstyled list-inline">

                                        {i18n.language === 'en'
                                            ? <li className="list-inline-item">{i18n.t('language.en', "English")}</li>
                                            : <li className="list-inline-item"><a href="#en" onClick={() => changeLanguage('en')} >{i18n.t('language.en', "English")}</a></li>
                                        }

                                        <li className="list-inline-item">|</li>
                                        {i18n.language === 'ru'
                                            ? <li className="list-inline-item">{i18n.t('language.ru', "Russian")}</li>
                                            : <li className="list-inline-item"><a href="#ru" onClick={() => changeLanguage('ru')} >{i18n.t('language.ru', "Russian")}</a></li>
                                        }
                                        <li className="list-inline-item">|</li>
                                        {isAuthenticated
                                            ? <li className="list-inline-item">
                                                <li className="list-inline-item">
                                                <Dropdown>
                                                    <Dropdown.Toggle style={{cursor:"pointer"}} as="a">{i18n.t('account.text',"Account")}</Dropdown.Toggle>
                                                    <Dropdown.Menu as="ul">
                                                        <Dropdown.Item as="li"><Link to={process.env.PUBLIC_URL + "/account"}>{i18n.t('account.courses',"My courses")}</Link></Dropdown.Item>
                                                        <Dropdown.Item as="li" ><a href="#" onClick={() => logout({ returnTo: window.location.origin})}>{i18n.t('logout.text',"Logout")}</a></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            </li>
                                            : <li className="list-inline-item"><a href="#" onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname }, ui_locales: i18n.language })} >{i18n.t('login.text', "Login/Signup")}</a></li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="mb-logo-area d-flex justify-content-between">

                                <div className="mb-logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo_text-brown.png"} alt="" /></Link>
                                </div>

                                <div className="hm-button">
                                    <a href={process.env.PUBLIC_URL + "/"} id="mb-sidebar-btn">
                                        <i className="las la-bars"></i>
                                    </a>
                                </div>
                                {/* <div className="mb-search-box">
                                    <form action="#">
                                        <input type="text" name="search" placeholder="Search Here" />
                                        <button type="submit"><i className="las la-search"></i></button>
                                    </form>
                                    </div>
                                        */}
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Mobile Menu Sidebar */}
            <section className="mb-sidebar" id="mb-sidebar-body">
                <div className="mb-sidebar-heading d-flex justify-content-between">
                    <div><h5>{i18n.t('menu.text')}</h5></div>
                    <div><a href={process.env.PUBLIC_URL + "/"} id="close-mb-sidebar"><i className="las la-times"></i></a></div>
                </div>
                <div className="mb-sidebar-menu">
                    <div className="mb-menu-item">
                        <div className="mb-menu-link">
                            <Link to={process.env.PUBLIC_URL + "/"}>{i18n.t('menu.home.text', 'Home')}</Link>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>{i18n.t('menu.lessons.text', 'Lessons')} <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                {store.getState().refs.categories
                                    && store.getState().refs.categories.length
                                    && store.getState().refs.categories
                                        .filter(item => item.inmenu)
                                        .map((category) => (

                                            <li key={'menu-lesson-' + category.id} ><Link className="nav-link" to={process.env.PUBLIC_URL + category.starturl}>{category.title[i18n.language]}</Link></li>

                                        ))

                                }
                            </ul>
                        </div>
                    </div>
                    {/* <div className="mb-menu-item">
                        <div className="mb-menu-link">
                            <Link to={process.env.PUBLIC_URL + "/courses"}>{i18n.t('menu.courses.text', 'Courses')}</Link>
                        </div>
                    </div> */}
                    <div className="mb-menu-item">
                        <div className="mb-menu-link">
                            <Link to={process.env.PUBLIC_URL + "/faq"}>{i18n.t('menu.faq.text', 'Q&A')}</Link>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <div className="mb-menu-link">
                            <a href="https://crazyplasticine.art/pictures">{i18n.t("menu.gallery.text", "Gallery")}</a>
                        </div>
                    </div>
                    {/* <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>{i18n.t('menu.home.text')} <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/"}>Home Style 1</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Pages <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/about"}>About Us</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/login"}>Log In</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/registration"}>Registration</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/faq"}>Faq</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/404"}>404</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/coming-soon"}>Coming Soon</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button active">
                            <p>Courses <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content show">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Instructor <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/instructor"}>Instructors</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/instructor-details"}>Instructor Details</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Event <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/event-details"}>Event Details</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Blog <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/blog-classic"}>Blog Classic</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/blog-grid"}>Blog Grid</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/blog-details"}>Blog Details</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="mb-menu-item">
                        <button className="mb-menu-button">
                            <p>Shop <i className="las la-plus"></i></p>
                        </button>
                        <div className="mb-menu-content">
                            <ul className="list-unstyled">
                                <li><Link to={process.env.PUBLIC_URL + "/products"}>Products</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/product-details"}>Product Details</Link></li>
                                <li><Link to={process.env.PUBLIC_URL + "/cart"}>Cart</Link></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </section>
            <div className="mb-sidebar-overlay" id="mb-sidebar-overlay"></div>
        </Styles>
    )
}

export default MobileMenu
import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: false,
        token: "",
    },
    reducers: {
        loginSuccess: (state, action) => {
            // state.auth.isUserLoggedIn = true;
            const payload = action.payload;
            state.isAuthenticated = true;
            state.token = payload.token;
        },
        loginFailed: (state) => {
            state.isAuthenticated = false;
        },
        logoutStart: (state) => {
            state.isAuthenticated = false;
            state.token = "";
        },
    },
});

export const { loginSuccess, loginFailed, logoutStart } = authSlice.actions;

export default authSlice.reducer;
import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .lesson-page {
        .lesson-area {
            padding-top: 20px;
            background: ${colors.socials};
            .lesson-details-content {
                .heading {
                    h4 {
                        color : ${colors.black1};
                        font-weight: 600;
                        line-height: 35px;
                        margin-bottom: 10px;

                        @media(max-width: 767px) {
                            font-size : 20px;
                        }

                        a {
                            color        : ${colors.green};
                            font-weight  : 600;
                            font-size: 22px;
                            display      : inline-block;

                            &:hover {
                                color: ${colors.green};
                            }
                        }
                    }
                }
                .lesson-icon {
                    margin-bottom: 20px;
                    ul {
                        li {
                            font-size : 14px;
                            color : ${colors.black2};
                            margin-right: 20px;
                            i {
                                font-size : 20px;
                                color : ${colors.green};
                                vertical-align: top;
                            }
                            &:last-child {
                                margin-right : 0;
                            }

                            @media(max-width: 991px) {
                                margin-right: 5px;
                            }
                        }
                    }
                }


                .lesson-buy-btn{
                    margin-bottom: 40px;
                    text-align: center;
                    button.buy-btn {
                        font-size: 16px;
                        color: #fff;
                        background: ${colors.gr_yl};
                        display: inline-block;
                        width: 120px;
                        height: 40px;
                        font-weight : 500;
                        border : none;
                        padding: 9px;
                        border-radius: 5px;
                        &:hover {
                            background: ${colors.gr_yl2};
                        }
                    }
                }
                .lesson-details-banner {
                    img {
                        border-radius: 5px;
                        margin-bottom: 30px;
                    }


                }
                .lesson-youtube {
                    max-width: 800px;
                    max-height: 480px;
                    width:  100%;
                    height: 40vw;
                    margin-bottom: 30px;
                }
                .lesson-details-overview {
                    margin-bottom: 35px;
                    h5 {
                        color: ${colors.black2};
                        font-weight: 600;
                        padding-bottom: 10px;
                        margin-bottom: 20px;
                        position: relative;
                        &:before {
                            position: absolute;
                            content: "";
                            background: ${colors.green};
                            width: 50px;
                            height: 2px;
                            bottom: 0;
                            left: 0;
                        }

                        @media(max-width: 767px) {
                            font-size : 17px;
                        }
                    }
                    p {
                        font-size: 15px;
                        color: ${colors.text2};
                        line-height: 25px;
                    
                    }
                    .lesson-item-line {
                        font-size   : 15px;
                        color       : ${colors.text2};
                        line-height : 28px;

                    }
                    ul {
                        margin-top : 30px;
                        li {
                            font-size: 14px;
                            color: ${colors.text3};
                            line-height: 25px;
                            margin-bottom: 15px;
                            i {
                                float: left;
                                color: ${colors.green};
                                border: 1px solid ${colors.border3};
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                text-align: center;
                                padding-top: 9px;
                                margin-right: 15px;
                            }
                            a {
                                margin-top: 5px;
                                font-size: 14px;
                                color    : ${colors.text4};
                                &:hover {
                                    color: ${colors.green};
                                }
                                
                                img {
                                    margin-right:10px;
                                }
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                
                
                }

                button.accordion-button {
                    border-radius : 5px;
                    border  : 1px solid ${colors.border1};
                    padding : 10px 0 10px;
                    background  : ${colors.gr_grey_rev};
                    width        : 100%;
                    text-align   : left;

                    
                    div.accordion-icon {
                        background  : ${colors.grey_dark};
                        height      : 18px;
                        text-align  : center;
                        float       : left;
                        margin-right: 12px;

                        i {
                            font-size   : 20px;
                            color       : #ffffff;
                            width       : 32px;
                            line-height : 18px;
                            padding-left: 2px
                        }

                    }

                    p {
                        font-size  : 16px;
                        color      : ${colors.white};
                        font-weight: 500;
                        line-height: 18px;
                    }
                }
                
                .vimeo-placeholder{
                    max-width:800px;
                    height: auto;
                    width: 100%;
                    padding-right: 10px;
                }

                .accordion-content {
                    max-height: 0;
                    overflow  : hidden;
                    transition: max-height 0.2s ease-in-out;
                    padding-left : 15px;
                    background : ${colors.white};
                    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
                    margin-bottom: 15px;

                    .section-video {
                        margin-top:10px;
                        margin-bottom:10px;

                    .vimeo-placeholder{
                        max-width:800px;
                        height: auto;
                        width: 100%;
                        padding-right: 10px;
                    }

                    .lesson-youtube-title {
                        margin-top:5px;
                        margin-bottom:5px;
                        h6 {
                            color: ${colors.black2};
                            font-weight: 600;
                        }
                    }
                }
                    
                }

                .accordion-content.show {
                    max-height: 100%;
                }

                .pre-nxt-lesson {
                    border : 1px solid ${colors.border1};
                    border-left: 0;
                    border-right: 0;
                    padding: 20px 0;
                    .next-prev-item {
                        img {
                            max-width : 110px;
                            border-radius : 5px;

                            @media(max-width: 991px) {
                                max-width: 55px;
                            }
                        }
                        p {
                            margin-bottom: 8px;
                            a {
                                font-size : 14px;
                                color : ${colors.black2};
                                font-weight : 500;
                                &:hover {
                                    color : ${colors.green};
                                    text-decoration : underline;
                                }

                                @media(max-width: 991px) {
                                    font-size : 13px;
                                }
                            }
                        }
                        span {
                            color : ${colors.text3};
                        }

                        .prev-img {
                            img {
                                margin-right : 15px;

                                @media(max-width: 991px) {
                                    margin-right: 6px;
                                }
                            }
                        }

                        .next-img {
                            img {
                                margin-left : 15px;

                                @media(max-width: 991px) {
                                    margin-left: 6px;
                                }
                            }
                        }
                    }

                    @media(max-width: 767px) {
                        margin-bottom: 40px;
                    }
                }
            }

            .event-details-sidebar {
                .event-sidebar-info {
                    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                    border-radius: 5px;
                    padding: 20px 20px 20px;
                    .event-sidebar-timer {
                        margin-bottom: 25px;
                        p {
                            display: inline-block;
                            background: ${colors.gr_bg};
                            margin-right: 5px;
                            width: 68px;
                            height: 65px;
                            font-size: 20px;
                            color : #ffffff;
                            font-weight : 500;
                            border-radius: 5px;
                            padding-top: 6px;
                            span {
                                display : block;
                                font-size: 13px;
                                font-weight: normal;
                                text-transform: uppercase;
                            }
                            &:last-child {
                                margin-right: 0;
                            }

                            @media(max-width: 1199px) {
                                margin-right: 3px;
                                width: 58px;
                                height: 60px;
                                font-size: 18px;
                            }
                        }
                    }

                    ul.event-info-list {
                        margin-bottom: 20px;
                        li {
                            border-top: 1px dashed ${colors.border3};
                            padding: 12px 0;
                            font-size : 14px;
                            color : ${colors.black2};
                            font-weight: 500;
                            span {
                                float : right;
                                font-size: 13px;
                                color: ${colors.text3};
                                font-weight: 400;
                                line-height: 20px;

                                @media(max-width: 1199px) {
                                    font-size: 12px;
                                }
                            }
                            &:first-child {
                                border-top : none;
                                padding-top : 0;
                            }
                            &:last-child {
                                padding-bottom : 0;
                            }
                        }
                    }

                    

                    @media(max-width: 1199px) {
                        padding: 15px;
                    }
                }
            }

            @media(max-width: 767px) {
                padding: 20px 0 30px;
            }
           
        }
    }
`;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/homeCategories.js";
import { connect } from 'react-redux';
import i18n from '../i18n.js';
import { withTranslation } from 'react-i18next';


const mapStateToProps = (state) => {
    return {
        categories: state.refs.categories
    }
}



class HomeCategoriesExtra extends Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
            <Styles>
                <section className="home-categories-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{i18n.language === 'ru' ? 'Великие художники' : 'Old masters'}</h4>
                                    {i18n.language === 'ru' ? 'или наработка портфолио для GCSE' : 'building GSCE portfolio'}
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            {
                                this.props.categories
                                    .filter(item => item.id !== 'online')
                                    .filter(item => item.kind === 'masters')
                                    .map((data, i) => (
                                        <Col sm="4" xs="8" key={i}>
                                            <div className="category-div">
                                                <Row>
                                                    <Col lg="12" md="12">
                                                        <div className="category-img">
                                                            <Link to={process.env.PUBLIC_URL + data.starturl}><img src={data.image ? data.image.thumbnails.large.url : 'https://via.placeholder.com/650x433'} alt="" className="img-fluid" /></Link>
                                                        </div>
                                                    </Col>
                                                    <Col lg="12" md="12">
                                                        <div className="category-content">
                                                            <div className="content-box">
                                                                <div className="top-content">
                                                                    <div className="category-title text-center">
                                                                        <h6><Link to={process.env.PUBLIC_URL + data.starturl}>{data.title ? data.title[i18n.language] : ""}</Link></h6>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="category-desc  text-center">
                                                                    {data.desc && i18n.language && data.desc[i18n.language] && data.desc[i18n.language].map((descline, i) => (
                                                                        <p key={'desc-line-' + data.id + '-' + i}>{descline}</p>

                                                                    ))

                                                                    }
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    ))
                            }
                        </Row>
                    </Container>
                </section>


            </Styles>
        )
    }
}

export default withTranslation()(connect(mapStateToProps)(HomeCategoriesExtra))

import styled from "styled-components";
import { colors, fonts } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .course-area {
    .header-section {
      position: relative;
      width: 100%;

      .top-part {
        background: ${colors.gr_grey};
        padding: 20px;
        text-align: left;
      }

      .top-part .pre-title {
        font-family: ${fonts.roboto};
        font-size: 12px;
        color: ${colors.white};
      }

      .top-part .title {
        color: ${colors.white};
        font-family: ${fonts.playfair};
        font-size: 18px;
        font-weight: 400;
        text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.17);
        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      p.margintop {
        margin-top: 15px;
        @media (max-width: 767px) {
          margin-top: 10px;
        }
      }
      p.marginbottom {
        margin-bottom: 15px;
        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }

      .top-part .description {
        font-family: ${fonts.roboto};
        font-size: 14px;
        color: ${colors.white};
      }

      .bottom-part {
        background: ${colors.socials};
        padding: 20px;
        text-align: left;
      }

      .bottom-part .detail-header {
        font-size: 1.2rem;
      }

      .bottom-part .detail-text {
        font-size: 1rem;

        ul,
        ol {
          padding-left: 30px; /* Adjust as necessary */
        }
      }

      .overlap-img {
        position: absolute;
        right: 50px;
        top: 10%;
        width: 25%;
      }

      .overlap-img img {
        width: 100%;
        max-width: 250px;
        height: auto;
        @media (max-width: 575px) {
          display: none;
        }
      }
    }

    .waveSec {
      height: 60px;
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      transform: translate3D(0, 0, 0);
      -moz-transform: unset;

      display: flex;
      justify-content: space-between;
      align-items: center; /* Aligns vertically in the center */
      position: relative;  /* Allows positioning child elements */
    }

    .waveSec .img-left,
    .waveSec .img-right {
      position: absolute;  /* Positions relative to the parent .waveSec */
      top: 50%;            /* Positions 50% from the top, centering vertically */
      width: 180px; 
      height: 180px;
      object-fit: contain; /* or 'cover' */
      transform: translateY(-50%);  /* Corrects alignment to true center */
    }

    .waveSec .img-left {
        left: 20%;           /* Positions 40% from the left */
    }
    .waveSec .img-right {
        right: 20%;          /* Positions 40% from the right */
    }

    .summary {
        margin-top: 20px;
        margin-bottom: 50px;

        h2 {
            font-family: ${fonts.playfair};
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        }
    }

    .summarypic {
        width: 100%;
        max-width : 440px;
        height: auto;
        text-align: center;
        @media (max-width: 575px) {
          display: none;
        }
    }
    .summaryitem {
        margin-top: 10px;
        color: ${colors.black1}; 
        cursor: pointer;
    }

    .summaryitem:hover {
        text-decoration: underline;
    }

    .summaryright {
        margin-left: 10px;
        margin-top: 0;
    }

    .secBgDark {
        background: ${colors.socials};
    }

    .secBgWhite {
      background: ${colors.white}!important;
  }


    .partSection{
        padding : 20px 10px;
        text-align: center;
        align-items: center;
        justify-content: center;
        h2 {
            font-size:18px;
            color: ${colors.orange};
            margin-bottom:10px;
        }
    }

    .vimeoVideo {
        width: 480px;
        height: 300px;
        border: none;
    }
  
    .vimeoVideo iframe {
        border: none;
        width=100%;
    }


      .titleText {
        font-size: 12px;
        text-transform: uppercase;
      }

      .video-container {
        display: flex;
        justify-content: center;
        max-width: 480px; 
        margin: auto;
      }

      .video-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%; /* for 16:9 aspect ratio change to 75% for 4:3 */
    }
    
    .video-wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

      button.accordion-button {
        border-radius : 5px;
        border  : 1px solid ${colors.border1};
        padding : 10px 0 10px;
        background  : ${colors.gr_grey_rev};
        width        : 100%;
        text-align   : left;

        
        div.accordion-icon {
            background  : ${colors.grey_dark};
            height      : 18px;
            text-align  : center;
            float       : left;
            margin-right: 12px;

            i {
                font-size   : 20px;
                color       : #ffffff;
                width       : 32px;
                line-height : 18px;
                padding-left: 2px
            }

        }

        p {
            font-size  : 16px;
            color      : ${colors.white};
            font-weight: 500;
            line-height: 18px;
        }
    }
    .accordion-content {
        width : 100%;
        max-height: 0;
        overflow  : hidden;
        transition: max-height 0.2s ease-in-out;
        background : ${colors.white};
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.07);

    }

    .accordion-content.show {
        max-height: 100%;
    }

    .courseAcc {
        margin-bottom: 20px;

    }

    .coursediv {
        background: ${colors.socials};
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 20px;

        h3 {
            font-size: 14px;
        }
        .desc{
            padding-top: 10px;
        }

        ol {
            padding-left : 20px;
            padding-top: 10px;
            li {
                ul {
                    padding-left:20px;
                }
            }
            
        }

        .recommend{
            padding-top: 10px;

            ul{
                padding-right: 20px;
            }
        }

        .attachment {


        }

        .attachmenturl {
            a {
                color: inherit;
                text-decoration: none; 
            }
        }

        .attachmentfile {
            a {
                color: inherit;
                text-decoration: none; 
            }
        }

        .attachmentbutton {
            a {
                color: inherit;
                background-color: #4CAF50;
                color: white;
                padding: 2px 5px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 14px;
                margin: 4px 2px;
                cursor: pointer;
                border-radius: 5px;
            }
        }
      }  
    .paddingBottom {
      padding-bottom: 40px;
    }


    .progBlock {
      max-width:260px;
      margin-bottom: 20px;
      .progImg {
        img {
          border-radius: 20px 20px 0 0;
        }
      }
      .progTextArea {
        box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
        padding: 10px;
        background: #ffffff;
        border-radius: 0 0 20px 20px;
        
        text-align : center;

        h6 {
          font-weight: bold;
        }
      }
    }
    
    
      textarea.feedback-input {
          font-family: ${fonts.roboto};
          font-size: 14px;
          width: 100%;
          max-width: 600px;
          resize: none;
          height: calc(1.5em * 7);;

          border-radius: 5px;
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.08);

          padding: 10px;

          @media (max-width: 767px) {
            font-size: 12px;
            height: calc(1.5em * 5);;
        }
      }
  
      .feedback-button {
        background: ${colors.green};
        border: none;
        border-radius: 5px;
        color: white;
        padding: 10px 100px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);
    
        text-transform: uppercase;
        transition: all 0.3s ease 0s;
        transform: scale(1);
  
          &:hover {
            transform: scale(0.95);
          }
  
          @media (max-width: 767px) {
              font-size: 14px;
              padding: 8px 16px;
          }
      }
      
      .feedback-button:disabled {
        background: ${colors.grey_light};
        cursor: not-allowed;
        opacity: 0.6;
      }

  }
`;

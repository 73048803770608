import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/FooterTwo';

import LessonsTabs from '../../components/LessonsTabs';
import GroupsBox from '../../components/GroupsBox';


export default class OnlineLessons extends Component {
    render() {
        return (
            <div className="main-wrapper" >

                {/* Header */}
                < Header />

                {/* Lessons tabs */}
                < LessonsTabs />

                {/* Icon Box */}
                < GroupsBox />

                {/* Footer */}
                < Footer />

            </div >
        )
    }
}

import { Auth0Context } from "@auth0/auth0-react";
import React, { Component } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import Footer from "../../components/Footer.js";
import Header from "../../components/Header.js";
import { Styles } from "./styles/teacherLesson.js";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import i18n from "../../i18n.js";

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lessonPublic: state.lesson.public,
    lessonPrivate: state.lesson.private,
  };
};




class TeacherLesson extends Component {
  static contextType = Auth0Context;
  constructor(props) {
    super(props);
    this.state = {
      feedback: "",
      alert: null,
      sending: false,
    };
  }

  scrollToRef = (ref) => {
    if (ref && ref.current) {
      let offsetTop = 0;
      let el = ref.current;

      // Traverse up through the parent nodes and sum the offsetTop
      while (el) {
        offsetTop += el.offsetTop;
        el = el.offsetParent;
      }

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  handleFeedbackChange = (event) => {
    this.setState({ feedback: event.target.value });
  };

  sendFeedback = () => {
    this.setState({ sending: true }); // Start sending.

    const { feedback } = this.state;
    const lessonId = 'teachers_01';
    // Make sure to handle the actual API request based on how your API is set up.
    // I'm just providing a simple fetch example below.
    fetch('/.netlify/functions/feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token,
      },
      body: JSON.stringify({ lessonId, feedback }),
    })
      .then((response => {
        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
        return response.json();
      }))
      .then(data => {
        this.setState({
          feedback: '',
          alert: {
            variant: 'success',
            message: 'Спасибо, ваше мнение очень важно для меня!'
          },
          sending: false
        });
      })
      .catch((error) => {
        // On error, show an error message and keep the text in the textarea
        console.error('Error:', error);
        this.setState({
          alert: {
            variant: 'danger',
            message: 'Что то пошло не так, попробуйте позже или свяжитесь со мной напрямую.'
          },
          sending: false // Stop sending
        });
      });
  };

  render() {

    const { alert } = this.state;

    const courseData = {
      1: {
        text: "Введение",
        videos: [{ title: "Введение", id: 840927252 }],
      },
      2: {
        text: "Материалы и инструменты",
        videos: [
          { title: "Инструменты", id: 838421681 },
          { title: "Какой пластилин использовать", id: 838425155 },
          { title: "Альтернативы пластилину", id: 838430182 },
        ],
      },
      3: {
        text: "Базовые элементы",
        videos: [{ title: "Введение в раздел", id: 839121547 }],
        othervideos: [
          { title: "Шарик", id: 838402594 },
          { title: "Морковка", id: 838402734 },
          { title: "Сосиска", id: 838402808 },
          { title: "Жгутик", id: 842889745 },
          { title: "Техники смешивания пластилина", id: 842890380 },
          { title: "Блинчик", id: 838403004 },
          { title: "Секрет одинаковых шариков", id: 838406250 },
        ],
      },
      4: {
        text: "Барельеф. Создание картин",
        videos: [
          { title: "Введение", id: 838670646 },
          { title: "Заключение", id: 839126188 },
        ],
      },
      4.1: {
        text: 'Картина "Подсолнух"',
        videos: [{ title: 'Картина "Подсолнух"', id: 842876507 }],
        attachments: [
          {
            title: "Конспект урока",
            type: "file",
            link: "4.1_summary_sunflower.pdf",
          },
          {
            title: "Для вдохновения",
            type: "file",
            link: "4.1_flowers-inspiration.pdf",
          },
          {
            title: "Отправить работу / задать вопрос ",
            type: "button",
            link: "https://wa.me/447507690706",
          },
        ],
      },
      4.2: {
        text: 'Картина "Рыбка"',
        videos: [{ title: 'Картина "Рыбка"', id: 844888431 }],
        attachments: [
          {
            title: "Конспект урока",
            type: "file",
            link: "4.2_summary_fish.pdf",
          },
          { title: "Контур рыбы", type: "file", link: "4.2_fish-contour.pdf" },
          {
            title: "Для вдохновения",
            type: "file",
            link: "4.2_fish-inspiration.pdf",
          },
          {
            title: "Другие формы контуров ",
            type: "file",
            link: "4.2_different-shapes.pdf",
          },
          {
            title: "Отправить работу / задать вопрос",
            type: "button",
            link: "https://wa.me/447507690706",
          },
        ],
      },
      4.3: {
        text: 'Картина "Ворона и Лисица"',
        videos: [{ title: 'Картина "Ворона и Лисица"', id: 842850070 }],
        attachments: [
          {
            title: "Конспект урока",
            type: "file",
            link: "4.3_summary_crow.pdf",
          },
          {
            title: "Для вдохновения",
            type: "file",
            link: "4.3_bird-inspiration.pdf",
          },
          {
            title: "Отправить работу / задать вопрос",
            type: "button",
            link: "https://wa.me/447507690706",
          },
        ],
      },
      5: {
        text: "Бонусы и подарки",
        videos: [],
        attachments: [],
      },
      5.1: {
        text: 'Флюид-арт "Космос"',
        videos: [{ title: 'Флюид-арт "Космос"', id: 842819357 }],
        attachments: [
          {
            title: "Конспект урока",
            type: "file",
            link: "5.1_summary_space.pdf",
          },
          {
            title: "Для вдохновения",
            type: "file",
            link: "5.1_space-inspiration.pdf",
          },
          {
            title: "Отправить работу / задать вопрос",
            type: "button",
            link: "https://wa.me/447507690706",
          },
        ],
      },
      5.2: {
        text: "Бонусные материалы",
        videos: [{ title: "Чем покрывать пластилин", id: 842233867 }],
        attachments: [
          {
            title: "Лайфхаки с пластилином",
            type: "file",
            link: "5.2_plasticine-lifehacks.pdf",
            img: "bonus-01.jpg",
          },
          {
            title: "Где ловить вдохновение",
            type: "file",
            link: "5.2_inspiration-sources.pdf",
            img: "bonus-02.jpg",
          },
        ],
      },
      final:{
        videos: [{ title: "Что можно рисовать", id: 844888028 }]
      }
    };

    let myRefs = {};
    // Create a ref for each id
    Object.keys(courseData).forEach((id) => {
      myRefs[id] = React.createRef();
    });

    const onSectionItemClick = function (event) {
      let button = event.target;
      while (!button.classList.contains("accordion-button")) {
        button = button.parentNode;
      }

      button.classList.toggle("active");
      const content =
        button.parentNode.parentNode.parentNode.children[1].firstChild
          .firstChild;
      if (button.classList.contains("active")) {
        content.className = "accordion-content show";
        button.firstChild.firstChild.className = "las la-minus-circle";
      } else {
        content.className = "accordion-content";
        button.firstChild.firstChild.className = "las la-plus-circle";
      }
    };

    let lessonPub = this.props.lessonPublic.find(
      (lesson) => lesson.id === "teachers_01"
    );
    let lessonPri = null;

    if (lessonPub && this.props.lessonPrivate.length > 0) {
      lessonPri = this.props.lessonPrivate.find(
        (lesson) => lesson.id === lessonPub.id
      );
    }

    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper">
          {/* Header 2 */}
          <Header />

          {/* Lesson Area */}
          <div className="course-area">
            <section class="secBgDark header-section">
            <Container>

              <div class="top-part">
                <h1 class="pre-title">Онлайн-курс Надежды Махнёвой</h1>
                <p class="title margintop">УНИКАЛЬНЫЕ ТЕХНИКИ ПЛАСТИЛИНА.</p>
                <p class="title">КУРС ДЛЯ ПРЕПОДАВАТЕЛЕЙ.</p>
                <p class="title marginbottom">БАРЕЛЬЕФ.</p>
                <p class="description marginbottom">
                  Приветствую вас на моем онлайн-курсе по пластилинографии!
                </p>
              </div>
              <div class="bottom-part">
                <h2 class="detail-header">Важное о курсе</h2>
                <p class="detail-text">
                  <ul>
                    <li>
                      Вы можете пересматривать уроки неоднократно, доступ к
                      курсу остается у вас навсегда.
                    </li>
                    <li>
                      Обратная связь доступна в течение 1 месяца с момента
                      оплаты.
                    </li>
                    <li>
                      По итогам прохождения курса вы можете получить сертификат
                      на русском и английском языках. Для этого нужно сдать 4
                      домашних задания (4 барельефа) и получить обратную связь.
                    </li>
                  </ul>
                  2 типа сертификатов:
                  <ol>
                    <li>
                      «Серебряный» О том, что курс прослушан и домашние задания
                      выполнены.
                    </li>
                    <li>
                      «Золотой». О том, что курс проработан на
                      высокопрофессиональном уровне, рекомендовано включить
                      полученные знания в систему обучения.
                    </li>
                  </ol>
                </p>
              </div>
              <div class="overlap-img">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    `/assets/images/teachers/pic-01.png`
                  }
                  className="coverpic"
                  alt=""
                />
              </div>
              </Container>

            </section>
            <div
              className="waveSec"
              style={{
                backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/teachers/line.jpg")`,
              }}
            ></div>

            {/* check if have access to the cource  */}
            {lessonPri || false ? (
              <section className="summary">
                <Container>
                  <Row>
                    <Col
                      md="7"
                      sm="7"
                      xs="0"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/teachers/pic-02.jpg`
                        }
                        className="summarypic"
                        alt=""
                      />
                    </Col>
                    <Col md="5" sm="5" xs="12">
                      <h2>СОДЕРЖАНИЕ КУРСА</h2>
                      {Object.keys(courseData)
                        .sort((a, b) => {
                          if (a.includes(".") || b.includes(".")) {
                            // We have float numbers, let's parse them and compare
                            return parseFloat(a) - parseFloat(b);
                          } else {
                            // Otherwise we have integers, let's compare them directly
                            return parseInt(a) - parseInt(b);
                          }
                        })
                        .map((item) => (
                          <p
                            key={item}
                            data-id={item}
                            className={`summaryitem ${item.includes(".") ? "summaryright" : ""
                              }`}
                            onClick={() => this.scrollToRef(myRefs[item])}
                          >
                            {`${item}. ${courseData[item].text}`}
                          </p>
                        ))}
                    </Col>
                  </Row>
                </Container>
              </section>
            ) : (
              <section className="summary">
                <Container>
                  <Row>
                    <Col
                      md="7"
                      sm="7"
                      xs="0"
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          `/assets/images/teachers/pic-02-blur.jpg`
                        }
                        className="summarypic"
                        alt=""
                      />
                    </Col>
                    <Col md="5" sm="5" xs="12">
                      <h2>СОДЕРЖАНИЕ КУРСА</h2>
                      Страница загружается... Если вы видите это сообщение
                      больше одной минуты - перегрузите страницу и проверьте что
                      вы вошли в свой аккаунт и имеете доступ к курсу.
                    </Col>
                  </Row>
                </Container>
              </section>
            )}

            {(lessonPri || false) && (
              // anything else is only visible for users with access
              <div>
                <section className="secBgDark partSection pb-3" ref={myRefs[1]}>
                  <h2>1. {courseData[1].text}</h2>
                  <div className="video-container">
                    <div className="video-wrapper">
                      <iframe
                        src={
                          "https://player.vimeo.com/video/" +
                          courseData[1].videos[0].id
                        }
                        allow="autoplay; fullscreen;"
                        allowFullScreen
                        title={courseData[1].videos[0].title}
                        className="vimeoVideo"
                      />
                    </div>
                  </div>
                </section>

                <div
                  className="waveSec"
                  style={{
                    backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/teachers/line-inverted.jpg")`,
                  }}
                ></div>

                <section className="partSection" ref={myRefs[2]}>
                  <h2>2. {courseData[2].text}</h2>
                  <Container>
                    <Row className="justify-content-center">
                      {courseData[2].videos.map((video, i) => (
                        <Col xs="12" md="6" key={i} className="pb-3">
                          <div className="video-container">
                            <div className="video-wrapper">
                              <iframe
                                src={
                                  "https://player.vimeo.com/video/" + video.id
                                }
                                allow="autoplay; fullscreen;"
                                allowFullScreen
                                title={video.title}
                                className="vimeoVideo"
                              />
                            </div>
                          </div>
                          <p className="titleText">{video.title}</p>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                </section>

                <section className="partSection secBgDark" ref={myRefs[3]}>
                  <h2>3. {courseData[3].text}</h2>
                  <div className="video-container pb-3">
                    <div className="video-wrapper">
                      <iframe
                        src={
                          "https://player.vimeo.com/video/" +
                          courseData[3].videos[0].id
                        }
                        allow="autoplay; fullscreen;"
                        allowFullScreen
                        title={courseData[3].videos[0].title}
                        className="vimeoVideo"
                      />
                    </div>
                  </div>

                  <div class="othervideos">
                    <Container>
                      <Row>
                        <Col xs="12">
                          <button
                            className="accordion-button"
                            onClick={onSectionItemClick}
                          >
                            <div className="accordion-icon">
                              <i className="las la-plus-circle"></i>
                            </div>
                            <p>Открыть список</p>
                          </button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <div className="accordion-content">
                            <Container>
                              <Row className="justify-content-center">
                                {courseData[3].othervideos.map((video, i) => (
                                  <Col
                                    md="12"
                                    lg="6"
                                    key={i}
                                  >
                                    <div className="video-container">
                                      <div className="video-wrapper">
                                        <iframe
                                          src={
                                            "https://player.vimeo.com/video/" +
                                            video.id
                                          }
                                          allow="autoplay; fullscreen;"
                                          allowFullScreen
                                          title={video.title}
                                          className="vimeoVideo"
                                        />
                                      </div>
                                    </div>
                                    <p className="titleText">{video.title}</p>
                                  </Col>
                                ))}
                              </Row>
                            </Container>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </section>
                <div
                  className="waveSec"
                  style={{
                    backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/teachers/line.jpg")`,
                  }}
                ></div>

                <section className="partSection" ref={myRefs[4]}>
                  <h2>4. {courseData[4].text}</h2>
                  <div className="video-container pb-3">
                    <div className="video-wrapper">
                      <iframe
                        src={
                          "https://player.vimeo.com/video/" +
                          courseData[4].videos[0].id
                        }
                        allow="autoplay; fullscreen;"
                        allowFullScreen
                        title={courseData[4].videos[0].title}
                        className="vimeoVideo"
                      />
                    </div>
                  </div>
                  <Container>
                    <Row>
                      <Col xs="12">
                        {[4.1, 4.2, 4.3].map((id) => (
                          <div className="courseAcc" ref={myRefs[id]} key={id}>
                            <Row>
                              <Col xs="12">
                                <button
                                  className="accordion-button"
                                  onClick={onSectionItemClick}
                                >
                                  <div className="accordion-icon">
                                    <i className="las la-plus-circle"></i>
                                  </div>
                                  <p>{id + ". " + courseData[id].text}</p>
                                </button>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12">
                                <div className="accordion-content">
                                  <div className="coursediv">
                                    <h3>Описание урока</h3>
                                    {id === 4.1 && (
                                      <div class="desc">
                                        <p>
                                          В этом уроке вы создадите небольшой
                                          барельеф «Подсолнух», с которым смогут
                                          справиться самые маленькие.
                                        </p>
                                        <p>
                                          Начнем с азов. Но даже если вы давно
                                          работаете с пластилином, наверняка
                                          почерпнете для себя что-то новое.
                                        </p>
                                        <ol>
                                          <li>Для малышей 4-5 лет.</li>
                                          <li>Время урока: 56 мин.</li>
                                          <li>
                                            Материалы:
                                            <ul>
                                              <li>
                                                Пластилин: желтый, зеленый,
                                                черный, оранжевый.
                                              </li>
                                              <li>
                                                Картон 15x15 см (толщина 3мм).
                                              </li>
                                              <li>Карандаш.</li>
                                              <li>Стек.</li>
                                              <li>Линейка.</li>
                                              <li>Пластиковый файл.</li>
                                            </ul>
                                          </li>
                                        </ol>
                                      </div>
                                    )}
                                    {id === 4.2 && (
                                      <div class="desc">
                                        <p>
                                          В этом уроке вы создадите картину в
                                          форме рыбы.
                                        </p>
                                        <ol>
                                          <li>Для детей 5-7 лет.</li>
                                          <li>Время урока: 1 час 36 мин.</li>
                                          <li>
                                            Материалы:
                                            <ul>
                                              <li>
                                                Пластилин: синий, голубой,
                                                фиолетовый, черный, белый,
                                                оранжевый, зеленый, розовый и
                                                любой разноцветный.
                                              </li>
                                              <li>
                                                Картон А4 (не толще 1 мм),
                                                вырезанный в форме рыбы.
                                              </li>
                                              <li>Карандаш.</li>
                                              <li>Стек.</li>
                                              <li>Линейка.</li>
                                              <li>Пластиковый файл.</li>
                                              <li>Влажные салфетки.</li>
                                            </ul>
                                          </li>
                                        </ol>
                                      </div>
                                    )}

                                    {id === 4.3 && (
                                      <div class="desc">
                                        <p>
                                          В этом уроке вы выполните сюжетный
                                          барельеф для детей постарше.
                                        </p>
                                        <ol>
                                          <li>Для детей 7-9 лет.</li>
                                          <li>Время урока: 1 час 41 мин.</li>
                                          <li>
                                            Материалы:
                                            <ul>
                                              <li>
                                                Пластилин: желтый, розовый,
                                                фиолетовый, синий, голубой,
                                                зеленый, черный, белый,
                                                оранжевый, красный.
                                              </li>
                                              <li>
                                                Картон 12x30 см. (толщина 3мм)
                                              </li>
                                              <li>Карандаш.</li>
                                              <li>Стек.</li>
                                              <li>Линейка.</li>
                                              <li>Пластиковый файл.</li>
                                              <li>
                                                Кисточка с круглым концом.
                                              </li>
                                              <li>Влажные салфетки.</li>
                                            </ul>
                                          </li>
                                        </ol>
                                      </div>
                                    )}

                                    <h3>
                                      Рекомендации для работы с учениками:
                                    </h3>

                                    {id === 4.1 && (
                                      <div class="recommend">
                                        <ul>
                                          <li>
                                            Для таких малышей важен быстрый
                                            результат, поэтому работа небольшая
                                            по размеру.
                                          </li>
                                          <li>
                                            Фон заранее подготавливает учитель.
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                    {id === 4.2 && (
                                      <div class="recommend">
                                        <ul>
                                          <li>
                                            Чем младше ребенок-ученик, тем
                                            мельче размер барельефа и меньше
                                            элементов включаем. Стараемся
                                            уложить в одно – максимум два
                                            занятия.
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                    {id === 4.3 && (
                                      <div class="recommend">
                                        <ul>
                                          <li>
                                            Весь фон от начала до конца
                                            выполняют ребята.
                                          </li>
                                          <li>
                                            Все фигуры делаются из простых
                                            элементов, которые вы освоили в
                                            прошлых уроках.{" "}
                                          </li>
                                          <li>
                                            Сложность увеличивается за счет
                                            количества элементов и детализации
                                            силуэтов.
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                    <Container>
                                      <Row>
                                        <Col md="12">
                                          <div className="video-container">
                                            <div className="video-wrapper">
                                              <iframe
                                                src={
                                                  "https://player.vimeo.com/video/" +
                                                  courseData[id].videos[0].id
                                                }
                                                allow="autoplay; fullscreen;"
                                                allowFullScreen
                                                title={
                                                  courseData[id].videos[0].title
                                                }
                                                className="vimeoVideo"
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      {courseData[id].attachments.map(
                                        (attachment) => (
                                          <Row key={attachment.link}>
                                            <Col
                                              md="12"
                                              className="d-flex justify-content-center align-items-center"
                                            >
                                              <div
                                                className={
                                                  "attachment attachment" +
                                                  attachment.type
                                                }
                                              >
                                                {attachment.type === "url" && (
                                                  <a
                                                    href={attachment.link}
                                                    target="_blank"
                                                  >
                                                    » {attachment.title} «
                                                  </a>
                                                )}

                                                {attachment.type === "file" && (
                                                  <a
                                                    href={
                                                      process.env.PUBLIC_URL +
                                                      `/assets/downloads/teachers/${attachment.link}`
                                                    }
                                                    target="_blank"
                                                  >
                                                    » {attachment.title} «
                                                  </a>
                                                )}

                                                {attachment.type ===
                                                  "button" && (
                                                    <a
                                                      href={attachment.link}
                                                      target="_blank"
                                                    >
                                                      » {attachment.title} «
                                                    </a>
                                                  )}
                                              </div>
                                            </Col>
                                          </Row>
                                        )
                                      )}
                                    </Container>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </Container>

                  <h2 style={{ marginTop: "30px" }}>Заключение</h2>

                  <div className="video-container">
                    <div className="video-wrapper">
                      <iframe
                        src={
                          "https://player.vimeo.com/video/" +
                          courseData[4].videos[1].id
                        }
                        allow="autoplay; fullscreen;"
                        allowFullScreen
                        title={courseData[4].videos[1].title}
                        className="vimeoVideo"
                      />
                    </div>
                  </div>
                </section>

                <div
                  className="waveSec"
                  style={{
                    backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/teachers/line-flip.jpg")`,
                    backgroundPosition: "center center",
                    height: "200px",
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/teachers/gift1.png`
                    }
                    className="img-left"
                    alt="Left"
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/teachers/gift2.png`
                    }
                    className="img-right"
                    alt="Right"
                  />
                </div>

                <section className="partSection secBgDark pb-3" ref={myRefs[5]}>
                  <h2>5. {courseData[5].text}</h2>
                </section>

                <section className="partSection secBgDark">
                  <Container>
                    <Row>
                      <Col xs="12">
                        {[5.1, 5.2].map((id) => (
                          <div className="courseAcc" ref={myRefs[id]} key={id}>
                            <Row>
                              <Col xs="12">
                                <button
                                  className="accordion-button"
                                  onClick={onSectionItemClick}
                                >
                                  <div className="accordion-icon">
                                    <i className="las la-plus-circle"></i>
                                  </div>
                                  <p>{id + ". " + courseData[id].text}</p>
                                </button>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12">
                                <div className="accordion-content">
                                  {id === 5.1 && (
                                    <div
                                      className="coursediv secBgWhite"
                                      ref={myRefs[id]}
                                    >
                                      <h3>Описание урока</h3>
                                      <div class="desc">
                                        <p>
                                          В бонусном уроке вы будете творить в
                                          уникальной технике «флюид-арт».
                                        </p>

                                        <ol>
                                          <li>
                                            Для детей старше 8 лет/подростков.
                                          </li>
                                          <li>Время урока: 1 час 32 мин.</li>
                                          <li>
                                            Материалы:
                                            <ul>
                                              <li>
                                                Пластилин: черный, белый, синий,
                                                желтый, оранжевый, голубой,
                                                зеленый и любой разноцветный.
                                              </li>
                                              <li>
                                                Картон 21x29,7 см. (формат А4)
                                              </li>
                                              <li>Карандаш.</li>
                                              <li>Стек.</li>
                                              <li>Линейка.</li>
                                              <li>Пластиковый файл.</li>
                                              <li>
                                                Кисточка с круглым концом.
                                              </li>
                                              <li>Влажные салфетки.</li>
                                              <li>Бумага для запекания.</li>
                                              <li>Утюг.</li>
                                            </ul>
                                          </li>
                                        </ol>
                                      </div>

                                      <h3>
                                        Рекомендации для работы с учениками:
                                      </h3>
                                      <div class="recommend">
                                        <ul>
                                          <li>
                                            Пластилин для фона должен быть одной
                                            фирмы/одной твёрдости – тогда он
                                            будет плавиться равномерно.
                                          </li>
                                          <li>
                                            Новым в уроке будет создание фона.
                                          </li>
                                          <li>
                                            Элементы картины выполнены из
                                            базовых деталей.
                                          </li>
                                        </ul>
                                      </div>

                                      <Container>
                                        <Row>
                                          <Col
                                            md="12"
                                          >
                                            <div className="video-container">
                                              <div className="video-wrapper">
                                                <iframe
                                                  src={
                                                    "https://player.vimeo.com/video/" +
                                                    courseData[id].videos[0].id
                                                  }
                                                  allow="autoplay; fullscreen;"
                                                  allowFullScreen
                                                  title={
                                                    courseData[id].videos[0]
                                                      .title
                                                  }
                                                  className="vimeoVideo"
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                        {courseData[id].attachments.map(
                                          (attachment) => (
                                            <Row key={attachment.link}>
                                              <Col
                                                md="12"
                                                className="d-flex justify-content-center align-items-center"
                                              >
                                                <div
                                                  className={
                                                    "attachment attachment" +
                                                    attachment.type
                                                  }
                                                >
                                                  {attachment.type ===
                                                    "url" && (
                                                      <a
                                                        href={attachment.link}
                                                        target="_blank"
                                                      >
                                                        » {attachment.title} «
                                                      </a>
                                                    )}

                                                  {attachment.type ===
                                                    "file" && (
                                                      <a
                                                        href={
                                                          process.env.PUBLIC_URL +
                                                          `/assets/downloads/teachers/${attachment.link}`
                                                        }
                                                        target="_blank"
                                                      >
                                                        » {attachment.title} «
                                                      </a>
                                                    )}

                                                  {attachment.type ===
                                                    "button" && (
                                                      <a
                                                        href={attachment.link}
                                                        target="_blank"
                                                      >
                                                        » {attachment.title} «
                                                      </a>
                                                    )}
                                                </div>
                                              </Col>
                                            </Row>
                                          )
                                        )}
                                      </Container>
                                    </div>
                                  )}

                                  {id === 5.2 && (
                                    <div
                                      className="coursediv secBgWhite"
                                      ref={myRefs[id]}
                                    >
                                      <Container style={{ paddingTop: "30px" }}>
                                        <Row className="justify-content-center">
                                          {courseData[id].attachments.map(
                                            (attachment) => (
                                              <Col
                                                sm="6"
                                                xs="12"
                                                className="d-flex justify-content-center align-items-center"
                                              >
                                                <div className="progBlock">
                                                  <a
                                                    href={
                                                      process.env.PUBLIC_URL +
                                                      `/assets/downloads/teachers/${attachment.link}`
                                                    }
                                                    target="_blank"
                                                  >
                                                    <div className="progImg">
                                                      <img
                                                        src={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          `/assets/images/teachers/` +
                                                          attachment.img
                                                        }
                                                        alt=""
                                                        className="img-fluid"
                                                      />
                                                    </div>
                                                    <div className="progTextArea">
                                                      <h6>
                                                        {attachment.title}
                                                      </h6>
                                                    </div>
                                                  </a>
                                                </div>
                                              </Col>
                                            )
                                          )}
                                        </Row>
                                        <Row className="justify-content-center mt-4">
                                          <Col className="d-flex justify-content-center align-items-center">
                                            <h2>
                                              {courseData[id].videos[0].title}
                                            </h2>
                                          </Col>
                                        </Row>
                                        <Row className="justify-content-center">
                                          <Col>
                                            <div className="video-container">
                                              <div className="video-wrapper">
                                                <iframe
                                                  src={
                                                    "https://player.vimeo.com/video/" +
                                                    courseData[id].videos[0].id
                                                  }
                                                  allow="autoplay; fullscreen;"
                                                  allowFullScreen
                                                  title={
                                                    courseData[id].videos[0]
                                                      .title
                                                  }
                                                  className="vimeoVideo"
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Container>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </Container>

                  <h2 style={{ marginTop: "30px" }}>Не останавливайся на достигнутом</h2>

<div className="video-container">
  <div className="video-wrapper">
    <iframe
      src={
        "https://player.vimeo.com/video/" +
        courseData.final.videos[0].id
      }
      allow="autoplay; fullscreen;"
      allowFullScreen
      title={courseData.final.videos[0].title}
      className="vimeoVideo"
    />
  </div>
</div>
<p style={{ marginTop: "10px" }}>Вот какие ещё картины можно создать с помощью этой техники</p>


                </section>

                <div
                  className="waveSec"
                  style={{
                    backgroundImage: `url("${process.env.PUBLIC_URL}/assets/images/teachers/line.jpg")`,
                  }}
                ></div>

                <section className="partSection pb-3">
                  <h2>Спасибо за отличную работу!</h2>
                  <Container>
                    <Row>
                      <p>
                        Поделитесь своим мнением о курсе и мы опубликуем его на этом
                        сайте. Не забывайте делиться в социальных сетях своими
                        работами, успехами своих учеников и конечно же ссылкой на
                        этот курс!
                      </p>
                    </Row>
                    {alert && (
                      <Row className='justify-content-center p-1'>
                        <Alert variant={alert.variant} onClose={() => this.setState({ alert: null })} dismissible>
                          {alert.message}
                        </Alert>
                      </Row>
                    )}
                    <Row className='justify-content-center p-3'>
                      <textarea
                        className="feedback-input"
                        value={this.state.feedback}
                        onChange={this.handleFeedbackChange}
                      />
                    </Row>
                    <Row className='justify-content-center  pb-2'>
                      <button class="feedback-button" onClick={this.sendFeedback} disabled={this.state.sending}>Отправить</button>
                    </Row>
                  </Container>

                </section>

              </div>
            )}
          </div>

          {/* Footer*/}
          <Footer />
        </div>
      </Styles>
    );
  }
}

export default connect(mapStateToProps)(TeacherLesson);

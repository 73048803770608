import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import store from '../../store'
import { connect } from 'react-redux';
import { Auth0Context } from '@auth0/auth0-react';
import { Styles } from './styles/lessons.js'

import i18n from '../../i18n';

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        lessonPublic: state.lesson.public,
        lessonPrivate: state.lesson.private,
        groups: state.refs.groups,
        categories: state.refs.categories,
        complexity: state.refs.complexity,
    }
}


class Lessons extends Component {
    static contextType = Auth0Context;
    constructor(props) {
        super(props);
        const initFilter = {
        }
        const filter = localStorage.getItem("lessons-filter") ? JSON.parse(localStorage.getItem("lessons-filter")) : initFilter;
        this.state = {
            filter: filter
        }
    }

    componentDidMount() {
    }

    componentDidUpdate() {
        //save filter to local storage
        window.localStorage.setItem("lessons-filter", JSON.stringify(this.state.filter))
    }



    onGroupChange = function (e) {
        let groupId = e.target.getAttribute("group-id")

        let filter = this.state.filter;
        if (filter.online && filter.online.groups && filter.online.groups.find(item => item === groupId)) {
            filter.online.groups = filter.online.groups.filter(item => item !== groupId)
        } else {
            if (!filter.online) {
                filter.online = {}
            }

            if (!filter.online.groups) {
                filter.online.groups = []
            }
            filter.online.groups.push(groupId)
        }
        this.setState(filter)

    }

    onComplexityChange = function (e) {
        let complexityId = e.target.getAttribute("complexity-id")

        let filter = this.state.filter;

        if (!filter[this.props.category]) {
            filter[this.props.category] = {}
        }


        if (filter[this.props.category].complexity && filter[this.props.category].complexity.find(item => item === complexityId)) {
            filter[this.props.category].complexity = filter[this.props.category].complexity.filter(item => item !== complexityId)
        } else {
            if (!filter[this.props.category].complexity) {
                filter[this.props.category].complexity = []
            }
            filter[this.props.category].complexity.push(complexityId)
        }
        this.setState(filter)

    }

    onFreeChange = function (e) {
        let filter = this.state.filter;
        if (!filter[this.props.category]) {
            filter[this.props.category] = {}
        }
        filter[this.props.category].free = !filter[this.props.category].free
        this.setState(filter)
    }

    onBuyClick = function (e) {
        sessionStorage.setItem('checkout-lesson-id', e.target.getAttribute("lesson-id"))
        this.props.history.push('/checkout')
    }

    //function to use in all lessons list - filters items if any groups are in filter 
    filterGroups = function (e) {
        if (this.props.category === 'online' && this.state.filter.online && this.state.filter.online.groups && this.state.filter.online.groups.length > 0) {
            if (e.group) {
                return this.state.filter.online.groups.find(item => item === e.group.id)
            } else {
                return false
            }
        }
        return true
    }

    filterComplexity = function (e) {
        if (this.state.filter[this.props.category] && this.state.filter[this.props.category].complexity && this.state.filter[this.props.category].complexity.length > 0) {
            return this.state.filter[this.props.category].complexity.find(item => item === e.complexity)
        }
        return true
    }

    filterFree = function (e) {
        if (this.state.filter[this.props.category] && this.state.filter[this.props.category].free) {
            return e.free
        }
        return true;
    }

    filterCategory = function (e) {
        if (this.props.category && this.props.category !== 'online') {
            return e.categories.find(item => item === this.props.category)
        }
        return true;
    }

    render() {

        let drafts = localStorage.getItem("drafts")

        let category
        if (this.props.categories && this.props.categories.length && this.props.category) {
            category = this.props.categories.find(cat => cat.id === this.props.category)
        }

        return (
            <Styles>
                <div className="main-wrapper lessons-page">

                    {/* Header */}
                    <Header />

                    {/* Lessons */}
                    <section className="lessons-area">
                        <Container>

                            <Row className='justify-content-center'>
                                {this.props.showFilter === 'true' &&

                                    <Col lg="3" md="4" sm="4">
                                        <div className="lesson-sidebar">
                                            {(this.props.category !== 'online' && this.props.showFilter === 'true') &&
                                                <Row>
                                                    <Col md="12">
                                                        <div className="lesson-filter">
                                                            <h5>{i18n.language === 'ru' ? 'Фильтр' : 'Filter'}</h5>
                                                            <ul className="filter-item list-unstyled">
                                                                {this.props.complexity &&
                                                                    this.props.complexity.map((data) => (
                                                                        <li className="check-btn" key={data.id}>
                                                                            <label htmlFor="cat1"><input type="checkbox" id={"complexity-checkbox-" + data.id} className="check-box" onChange={this.onComplexityChange.bind(this)} complexity-id={data.id} checked={!!(this.state.filter[this.props.category] && this.state.filter[this.props.category].complexity && this.state.filter[this.props.category].complexity.find(e => e === data.id))} />{data.title[i18n.language]}</label>
                                                                        </li>
                                                                    ))
                                                                }
                                                                {/* free lessons */}
                                                                <li className="check-btn" key='free'>
                                                                    <label htmlFor="cat1"><input type="checkbox" id="free-checkbox" className="check-box" onChange={this.onFreeChange.bind(this)} checked={!!(this.state.filter[this.props.category] && this.state.filter[this.props.category].free)} />{i18n.language === 'ru' ? 'Бесплатно' : 'Free'}</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            {this.props.category === 'online' &&
                                                <Row>
                                                    <Col md="12">
                                                        <div className="lesson-filter">
                                                            <h5>{i18n.language === 'ru' ? 'Группа' : 'Group'}</h5>
                                                            <ul className="filter-item list-unstyled">
                                                                {this.props && this.props.groups &&
                                                                    this.props.groups.map((data) => (
                                                                        <li className="check-btn" key={data.id}>
                                                                            <label htmlFor="cat1"><input type="checkbox" id={"group-checkbox-" + data.id} className="check-box" onChange={this.onGroupChange.bind(this)} group-id={data.id} checked={!!(this.state.filter.online && this.state.filter.online.groups && this.state.filter.online.groups.find(e => e === data.id))} />{data.name[i18n.language]}</label>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            }
                                        </div>
                                    </Col>
                                }
                                <Col lg="9" md="8" sm="8">

                                    <Row className="lesson-items">
                                        <Col md="12">
                                            {
                                                this.props.lessonPublic
                                                    .filter(this.filterCategory.bind(this))
                                                    .filter(this.filterComplexity.bind(this))
                                                    .filter(this.filterFree.bind(this))
                                                    .filter(this.filterGroups.bind(this))
                                                    .map((data, i) => (
                                                        <div className="lesson-box" key={i}>
                                                            <Row>
                                                                <Col xl="4" lg="4" >
                                                                    {false &&
                                                                        <div className="lesson-img">
                                                                            {data.external_url ?
                                                                                <a href={data.external_url} target="_blank"><img src={data.image ? data.image.url : 'https://via.placeholder.com/150x100'} alt="" className="img-fluid" /></a>
                                                                                :
                                                                                <Link to={process.env.PUBLIC_URL + '/lessons/' + data.url}><img src={data.image ? data.image.url : 'https://via.placeholder.com/150x100'} alt="" className="img-fluid" /></Link>
                                                                            }

                                                                        </div>

                                                                    }

                                                                    {data.external_url ?
                                                                        <a href={data.external_url} target="_blank">
                                                                            <div className="lesson-image" style={{ backgroundImage: `url(${data.image ? data.image.url : 'https://via.placeholder.com/150x100'})` }}>
                                                                                {!(this.props.lessonPrivate &&
                                                                                    this.props.lessonPrivate.find(item => item.id === data.id)) &&
                                                                                    (data.price || (data.group &&
                                                                                        this.props.groups.find(group => group.id === data.group.id))) &&
                                                                                    !data.free &&
                                                                                    <div className="lesson-price">
                                                                                        <p>£{data.price && data.price.amount}</p>
                                                                                    </div>
                                                                                }
                                                                                {!!data.free &&
                                                                                    <div className="lesson-price">
                                                                                        <p>{i18n.language === 'ru' ? 'Бесплатно' : 'Free'}</p>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </a>
                                                                        :

                                                                        <Link to={process.env.PUBLIC_URL + '/lessons/' + data.url}>
                                                                            <div className="lesson-image" style={{ backgroundImage: `url(${data.image ? data.image.url : 'https://via.placeholder.com/150x100'})` }}>
                                                                                {!(this.props.lessonPrivate &&
                                                                                    this.props.lessonPrivate.find(item => item.id === data.id)) &&
                                                                                    (data.price || (data.group &&
                                                                                        this.props.groups.find(group => group.id === data.group.id))) &&
                                                                                    !data.free &&
                                                                                    <div className="lesson-price">
                                                                                        <p>£{data.price && data.price.amount}</p>
                                                                                    </div>
                                                                                }
                                                                                {!!data.free &&
                                                                                    <div className="lesson-price">
                                                                                        <p>{i18n.language === 'ru' ? 'Бесплатно' : 'Free'}</p>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </Link>
                                                                    }
                                                                </Col>
                                                                <Col xl="8" lg="8" >
                                                                    <div className="lesson-content">
                                                                        <div className="lesson-box">
                                                                            <Row>
                                                                                <Col xl="9" lg="9" >
                                                                                    <div className="lesson-title">
                                                                                        {data.external_url ?
                                                                                            <h6><a href={data.external_url} target="_blank"> {data.title}</a></h6>

                                                                                            :
                                                                                            <h6><Link to={process.env.PUBLIC_URL + '/lessons/' + data.url}> {data.title}</Link></h6>

                                                                                        }

                                                                                    </div>
                                                                                    <div className="lesson-time-location">
                                                                                        <ul className="list-unstyled list-inline">
                                                                                            {data.group &&
                                                                                                <li className="list-inline-item"><i className="las la-user-friends"></i> {data.group.name} </li>
                                                                                            }
                                                                                            {data.date &&
                                                                                                <li className="list-inline-item"><i className="las la-calendar"></i>
                                                                                                    {data.date ?
                                                                                                        (new Date(data.date)).toLocaleString(['ru-RU'], { year: 'numeric', month: 'long', day: 'numeric' })
                                                                                                        : 'to be confirmed'
                                                                                                    }
                                                                                                </li>
                                                                                            }
                                                                                            {data.duration &&
                                                                                                <li className="list-inline-item"><i className="las la-clock"></i>{new Date(data.duration * 1000).toISOString().substr(11, 5)}</li>
                                                                                                // <li className="list-inline-item"><i className="las la-clock"></i>{Math.floor(data.duration / 3600)}:{Math.floor((data.duration%3600) / 60)}</li>

                                                                                            }
                                                                                            {data.complexity && this.props.complexity && this.props.complexity.find(elem => elem.id === data.complexity) &&
                                                                                                <li className="list-inline-item"><i className="las la-user-cog"></i>{this.props.complexity.find(elem => elem.id === data.complexity).title[i18n.language]}</li>
                                                                                                // <li className="list-inline-item"><i className="las la-clock"></i>{Math.floor(data.duration / 3600)}:{Math.floor((data.duration%3600) / 60)}</li>

                                                                                            }
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="lesson-desc">
                                                                                        <p>{data.shortdesc}</p>
                                                                                    </div>
                                                                                </Col>
                                                                                <Col xl="3" lg="3" className="text-left">
                                                                                    {!data.external_url &&

                                                                                        ((this.props.lessonPrivate && this.props.lessonPrivate.find(item => item.id === data.id))
                                                                                            || data.free
                                                                                            ?
                                                                                            <div className="join-btn">
                                                                                                <Link to={process.env.PUBLIC_URL + '/lessons/' + data.url}>{data.free ? (i18n.language === 'ru' ? 'Доступно' : 'Free') : (i18n.language === 'ru' ? 'Оплачено' : 'Available')}</Link>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="buy-btn" >
                                                                                                <a onClick={this.onBuyClick.bind(this)} lesson-id={data.id}>{i18n.language === 'ru' ? 'Купить' : 'Buy now'}</a>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {data.external_url &&

                                                                                        (data.free
                                                                                            ?
                                                                                            <div className="join-btn">
                                                                                                <a href={data.external_url} target="_blank">{i18n.language === 'ru' ? 'Доступно' : 'Free'}</a>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="buy-btn" >
                                                                                                <a href={data.external_url} target="_blank">{i18n.language === 'ru' ? 'Купить' : 'Buy now'}</a>
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    ))
                                            }
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Container>
                    </section>



                    {/* Footer */}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default connect(mapStateToProps)(Lessons)
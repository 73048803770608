import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Styles } from "./styles/copyright.js";

export class Copyright extends Component {

    render() {
        return (
            <Styles>
                <section className="copyright-area">
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className="text-center copyright-text">
                                    <p>Copyright &copy; 2021 | Crazy Plasticine</p>
                                    <ul className="social list-unstyled list-inline">
                                        <li className="list-inline-item"><a href="https://www.instagram.com/crazy_plasticine/"><i className="fab fa-instagram"></i></a></li>
                                        <li className="list-inline-item"><a href="https://www.facebook.com/crazyplasticine"><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCZVWFzGD7hDsYsvzc4nxPkw"><i className="fab fa-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Styles>
        )
    }
}
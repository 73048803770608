import { Auth0Context } from "@auth0/auth0-react";
import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../../components/Footer.js";
import Header from "../../components/Header.js";
import { Styles } from "./styles/lesson.js";
import store from "../../store";
import { connect } from "react-redux";
import { loadPublic, loadPrivate } from "../../store/lessonSlice.js";
import { Link } from "react-router-dom";
import i18n from "../../i18n.js";
import { loadTags } from "../../store/refSlice.js";

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lessonPublic: state.lesson.public,
    lessonPrivate: state.lesson.private,
    groups: state.refs.groups,
    categories: state.refs.categories,
  };
};

class Lesson extends Component {
  static contextType = Auth0Context;
  constructor(props) {
    super(props);
  }

  onBuyClick = function (e) {
    sessionStorage.setItem(
      "checkout-lesson-id",
      e.target.getAttribute("lesson-id")
    );
    this.props.history.push("/checkout");
  };

  render() {
    const onSectionItemClick = function (event) {
      let button = event.target;
      while (!button.classList.contains("accordion-button")) {
        button = button.parentNode;
      }
      //            console.log(button);

      button.classList.toggle("active");
      const content =
        button.parentNode.parentNode.parentNode.children[1].firstChild
          .firstChild;

      if (button.classList.contains("active")) {
        content.className = "accordion-content show";
        button.firstChild.firstChild.className = "las la-minus-circle";
      } else {
        content.className = "accordion-content";
        button.firstChild.firstChild.className = "las la-plus-circle";
      }
    };

    let lessonPub = this.props.lessonPublic.find(
      (lesson) => lesson.url === this.props.match.params.url
    );
    let lessonPri = null;

    if (lessonPub && this.props.lessonPrivate.length > 0) {
      lessonPri = this.props.lessonPrivate.find(
        (lesson) => lesson.id === lessonPub.id
      );
    }

    let lessonNext = null,
      lessonPrev = null;
    if (lessonPub && lessonPub.group) {
      let groupLessons = this.props.lessonPublic
        .filter(
          (lesson) => lesson.group && lesson.group.id === lessonPub.group.id
        )
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      let i = groupLessons.findIndex((lesson) => lesson.id === lessonPub.id);
      if (i > 0) {
        lessonPrev = groupLessons[i - 1];
      }
      if (i < groupLessons.length) {
        lessonNext = groupLessons[i + 1];
      }
    }

    let showVideo =
      (lessonPri ||
        (lessonPub && lessonPub.free) ||
        (lessonPub && lessonPub.sections)) &&
      ((lessonPri && lessonPri.youtubeLink) ||
        (lessonPub && (lessonPub.sections || lessonPub.youtubeLink)));

    return (
      <Styles>
        {/* Main Wrapper */}
        <div className="main-wrapper lesson-page">
          {/* Header 2 */}
          <Header />

          {/* Lesson Area */}
          <div className="lesson-area">
            {lessonPub && (
              <Container>
                <div className="lesson-details-content">
                  <Row>
                    <Col>
                      <div className="heading">
                        <h4>
                          {lessonPub.categories &&
                            lessonPub.categories.length &&
                            this.props.categories &&
                            this.props.categories.find(
                              (cat) => cat.id === lessonPub.categories[0]
                            ) && (
                              <Link
                                to={
                                  this.props.categories.find(
                                    (cat) => cat.id === lessonPub.categories[0]
                                  ).url
                                }
                              >
                                {
                                  this.props.categories.find(
                                    (cat) => cat.id === lessonPub.categories[0]
                                  ).title[i18n.language]
                                }{" "}
                              </Link>
                            )}{" "}
                          &gt;&nbsp;
                          {lessonPub.title}
                        </h4>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="lesson-icon">
                        <ul className="list-unstyled list-inline">
                          {/* <li className="list-inline-item" key="2"><i className="las la-clock"></i> {new Date(lessonPub.date).toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' })}</li> */}
                          {lessonPub.group && (
                            <li className="list-inline-item" key="3">
                              <i className="las la-user-friends"></i>{" "}
                              {lessonPub.group.name}
                            </li>
                          )}
                          {lessonPub.date && (
                            <li className="list-inline-item" key="1">
                              <i className="las la-calendar"></i>{" "}
                              {new Date(lessonPub.date).toLocaleDateString(
                                "ru-RU",
                                {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                }
                              )}
                            </li>
                          )}
                          {lessonPub.duration && (
                            <li className="list-inline-item" key="4">
                              <i className="las la-clock"></i>{" "}
                              {new Date(lessonPub.duration * 1000)
                                .toISOString()
                                .substr(11, 5)}
                            </li>
                          )}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="5" xs="12">
                      <div className="lesson-details-banner">
                        <img
                          src={
                            lessonPub.image
                              ? lessonPub.image.url
                              : "https://via.placeholder.com/1280x720"
                          }
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </Col>
                    <Col md="7" xs="12">
                      <div className="lesson-details-overview">
                        <h5>Описание</h5>
                        <div>
                          {lessonPub.desc.map((data, i) => (
                            <div key={"desc-" + i} className="lesson-item-line">
                              {data}
                            </div>
                          ))}
                        </div>
                      </div>


                      {!lessonPri && !lessonPub.free && (
                        <div className="lesson-details-overview">
                          <div>

                            <div>
                              {" "}
                              * Урок можно приобрести за £
                              {lessonPub.price && lessonPub.price.amount}
                            </div>

                            {!lessonPri && !lessonPub.free && (
                              <div className="lesson-buy-btn">
                                <button
                                  type="button"
                                  className="buy-btn"
                                  lesson-id={lessonPub.id}
                                  onClick={this.onBuyClick.bind(this)}
                                >
                                  {i18n.language === 'ru' ? 'Купить' : 'Buy now'}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>

                      {lessonPub.materials && lessonPub.materials.length > 0 && (
                        <div className="lesson-details-overview">
                          <h5>Материалы</h5>
                          <div>
                            {lessonPub.materials.map((data, i) => (
                              <div
                                key={"attachment-" + i}
                                className="lesson-item-line"
                              >
                                {data}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}


                      {(lessonPri || lessonPub.free) &&
                        (lessonPub.attachmentsdesc ||
                          lessonPub.attachments.length > 0) && (
                          <div className="lesson-details-overview">
                            <h5>Файлы для скачивания</h5>
                            <p>{lessonPub.attachmentsdesc}</p>
                            <ul className="list-unstyled">
                              {lessonPub.attachments.map((data, i) => (
                                <li key={data.id}>


                                  <a href={data.url} target="_blank">
                                    {data.thumbnails && data.thumbnails.small &&
                                      <img src={data.thumbnails.small.url} />
                                    }
                                    {data.filename}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}

<div className="lesson-details-overview">
<h5>Видео </h5>
                      


                          {lessonPub.sections ? ( // multiple video lesson
                            <div>
                              {lessonPub.sections.map((data, i) => (
                                <div key={"section-" + data.id}>
                                  <Row>
                                    <Col md="12">
                                      <button
                                        className="accordion-button"
                                        onClick={onSectionItemClick}
                                      >
                                        <div className="accordion-icon">
                                          <i className="las la-plus-circle"></i>
                                        </div>
                                        <p>{data.title}</p>
                                      </button>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md="12">
                                      <div className="accordion-content">
                                        {data.videos.map((video) => (
                                          <Row key={"lesson" + video.id}>
                                            <Col md="12">
                                              <div className="section-video">
                                                <div className="lesson-youtube-title">
                                                  <h6>{video.title}</h6>
                                                </div>
                                                {(video.public && video.vimeo) ||
                                                  (!video.public &&
                                                    lessonPri &&
                                                    lessonPri.videosMap[
                                                    video.id
                                                    ]) ? (
                                                  <iframe
                                                    className="lesson-youtube"
                                                    src={
                                                      "https://player.vimeo.com/video/" +
                                                      (video.public
                                                        ? video.vimeo
                                                        : lessonPri.videosMap[
                                                        video.id
                                                        ]) +
                                                      ""
                                                    }
                                                    allowFullScreen
                                                    frameBorder="0"
                                                  ></iframe>
                                                ) : (
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/images/video-lock.jpg"
                                                    }
                                                    alt=""
                                                    className="img-fluid vimeo-placeholder"
                                                  />
                                                )}
                                              </div>
                                            </Col>
                                          </Row>
                                        ))}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </div>
                          ) : ( // single video lesson
                            ((lessonPub && lessonPub.youtubeLink) || 
                            (lessonPri && lessonPri.youtubeLink)) ? (
                              <iframe
                                className="lesson-youtube"
                                src={
                                  "https://player.vimeo.com/video/" +
                                  (lessonPub && lessonPub.free
                                    ? lessonPub.youtubeLink
                                    : lessonPri.youtubeLink) +
                                  ""
                                }
                                allowFullScreen
                                frameBorder="0"
                              ></iframe>
                            ) : ( 
                              <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/video-lock.jpg"
                              }
                              alt=""
                              className="img-fluid vimeo-placeholder"
                            />


                            )

                          )}
                     
                      </div>


                      {!lessonPri && !lessonPub.free && (
                        <div className="lesson-buy-btn">
                          <button
                            type="button"
                            className="buy-btn"
                            lesson-id={lessonPub.id}
                            onClick={this.onBuyClick.bind(this)}
                          >
                            {i18n.language === 'ru' ? 'Купить' : 'Buy now'}
                          </button>
                        </div>
                      )}
                    </Col>
                  </Row>
                  {(lessonPrev || lessonNext) && (
                    <div className="pre-nxt-lesson">
                      <Row>
                        <Col md="6">
                          {lessonPrev && (
                            <div className="next-prev-item prev-event d-flex">
                              <div className="prev-img">
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/lessons/" +
                                    lessonPrev.url
                                  }
                                >
                                  <img
                                    src={
                                      lessonPrev.image
                                        ? lessonPrev.image.thumbnails.large.url
                                        : "https://via.placeholder.com/150x100"
                                    }
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="prev-text">
                                <p>
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/lessons/" +
                                      lessonPrev.url
                                    }
                                  >
                                    Предыдущий урок: {lessonPrev.title}
                                  </Link>
                                </p>
                                <span>
                                  {" "}
                                  {new Date(lessonPrev.date).toLocaleString(
                                    ["ru-RU"],
                                    {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </Col>
                        <Col md="6">
                          {lessonNext && (
                            <div className="next-prev-item next-event d-flex flex-row-reverse text-right">
                              <div className="next-img">
                                <Link
                                  to={
                                    process.env.PUBLIC_URL +
                                    "/lessons/" +
                                    lessonNext.url
                                  }
                                >
                                  <img
                                    src={
                                      lessonNext.image
                                        ? lessonNext.image.thumbnails.large.url
                                        : "https://via.placeholder.com/150x100"
                                    }
                                    alt=""
                                  />
                                </Link>
                              </div>
                              <div className="next-text">
                                <p>
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/lessons/" +
                                      lessonNext.url
                                    }
                                  >
                                    Следующий урок: {lessonNext.title}
                                  </Link>
                                </p>
                                <span>
                                  {new Date(lessonNext.date).toLocaleString(
                                    ["ru-RU"],
                                    {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    }
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </Container>
            )}
          </div>

          {/* Footer*/}
          <Footer />
        </div>
      </Styles>
    );
  }
}

export default connect(mapStateToProps)(Lesson);

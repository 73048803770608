import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .checkout-page {
        .checkout-success-page-area {
            margin-bottom:30px;

            h3 {
                color    : ${colors.black2};
                font-weight : 600;
                margin-bottom: 20px;

                @media(max-width: 575px) {
                    font-size : 22px;
                }
            }
            p {
                font-size : 17px;
                color : ${colors.text1};
                font-weight: 500;
                margin-bottom: 40px;
            }
            a {
                font-size: 18px;
                background : ${colors.gr_bg};
                color : #ffffff;
                padding: 12px 30px;
                font-weight: 500;
                border-radius: 5px;
                display : inline-block;
                i {
                    margin-right: 6px;
                }
                &:hover {
                    background : ${colors.gr_bg2};
                }

                @media(max-width: 575px) {
                    font-size: 15px;
                    padding: 10px 20px;
                }
            }

            a.inlineLink {
                font-family: inherit;
                font-size: inherit;
                color: inherit;
                padding-left:5px;
                padding-right: 5px;

                background-color: #4CAF50;
                color: white;
                padding: 2px 5px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                margin: 4px 2px;
                cursor: pointer;
                border-radius: 5px;

                &:hover {
                    transform: scale(0.95); 
                    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3); 
                }
            }
        
        }

        
    }
`;
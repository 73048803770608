import React from 'react';
import BackToTop from './common/BackToTop';
import { Styles } from "./styles/footer.js";
import { Copyright } from './common/Copyright';

function Footer() {

    return (
        <Styles>

            {/* Back To Top  */}
            <BackToTop />

            {/* Copyright  */}
            <Copyright />

        </Styles>
    );
}

export default Footer

import React, { Component } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { Styles } from "./styles/lessonsTabs.js";
import Datas from '../data/lessons-tabs.json';
import i18n from '../i18n'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';


const mapStateToProps = (state) => {
    return {
      lessonsFaq: state.refs.lessonsFaq,
    };
  };

class LessonsTabs extends Component {

    constructor(props) {
        super(props);
      }


    render() {
        return (
            <Styles>
                {/* Tab Box Area */}
                <section className="tab-section">
                    <Container>
                        <Tab.Container defaultActiveKey={Datas.defaultTabId}>
                            <Row>
                            <Col md="12">
                                <div className="sec-title text-center">
                                    <h4>{Datas.secTitle[i18n.language]}</h4>
                                </div>
                            </Col>
                                <Col lg="3" md="4">
                                    <Nav className="flex-column">
                                        {
                                            this.props.lessonsFaq.map((tabData,i)=>(
                                            <Nav.Item key={tabData.id}>
                                                <Nav.Link eventKey={tabData.id}><i className="las la-arrow-right"></i> {tabData.tabTitle[i18n.language]}</Nav.Link>
                                            </Nav.Item>
                                            ))

                                        }
                                    </Nav>
                                </Col>
                                <Col lg="9" md="8">
                                    <Tab.Content>
                                    {
                                            this.props.lessonsFaq.map((tabData,i)=>(
                                                <Tab.Pane eventKey={tabData.id} key={tabData.id}>
                                                <h4 className="tab-title">{tabData.tabTitle[i18n.language]}</h4>
                                                <div className="tab-desc">
                                                {tabData.tabDesc[i18n.language] && tabData.tabDesc[i18n.language].map((data,i) => 
                                                     <div key={i} className="tab-item-line">{data}</div>
                                                    )}
                                                    
                                                    </div>

                                            </Tab.Pane>
                                            ))

                                        }
                                    </Tab.Content>
                                </Col>
                                <Col md="12" className="text-center">
                            <div className="contact-btn">
                                <a href="https://wa.me/447507690706">{Datas.contactText[i18n.language]}</a>
                            </div>
                        </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </section>
            </Styles>
        )
    }
}

export default withTranslation()(connect(mapStateToProps)(LessonsTabs))

import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Styles } from './styles/course.js';
import tagsData from '../../data/course/tags.json';
import languagesData from '../../data/course/languages.json';
import categoriesData from '../../data/course/categories.json';
import audienceData from '../../data/course/audience.json';
import i18n from '../../i18n';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        courses: state.courses.courses,
        tags: state.refs.tags
    }
}

class Courses extends Component {

    constructor(props) {
        super(props);
        const initFilter = {
            categories: [],
            audiences: [],
            languages: [],
            tags: [],
        }

        const filter = localStorage.getItem("courses-filter") ? JSON.parse(localStorage.getItem("courses-filter")) : initFilter;
        this.state = {
            filter: filter
        }
    }
    componentDidUpdate() {
        //save filter to local storage
        window.localStorage.setItem("courses-filter", JSON.stringify(this.state.filter))
    }

    // function to call on click to category filter
    onCategoryChange = function (e) {
        let category = e.target.getAttribute("category")

        let filter = this.state.filter;
        if (filter.categories && filter.categories.find(item => item === category)) {
            filter.categories = filter.categories.filter(item => item !== category)
        } else {
            if (!filter.categories) {
                filter.categories = []
            }
            filter.categories.push(category)
        }
        this.setState(filter)
    }

    //function to use in all courses list - filters item if category is in filter 
    filterCategory = function (e) {
        if (this.state.filter.categories && this.state.filter.categories.length > 0) {
            if (e.category) {
                return this.state.filter.categories.find(item => item === e.category)
            } else {
                return false
            }
        }
        return true
    }


    onAudienceChange = function (e) {
        let audience = e.target.getAttribute("audience")

        let filter = this.state.filter;
        if (filter.audiences && filter.audiences.find(item => item === audience)) {
            filter.audiences = filter.audiences.filter(item => item !== audience)
        } else {
            if (!filter.audiences) {
                filter.audiences = []
            }
            filter.audiences.push(audience)
        }
        this.setState(filter)
    }

    //function to use in all courses list - filters item if category is in filter 
    filterAudience = function (e) {
        if (this.state.filter.audiences && this.state.filter.audiences.length > 0) {
            if (e.audience) {
                return this.state.filter.audiences.find(item => item === e.audience)
            } else {
                return false
            }
        }
        return true
    }


    onLanguageChange = function (e) {
        let languageId = e.target.getAttribute("language-id")

        let filter = this.state.filter;
        if (filter.languages && filter.languages.find(item => item === languageId)) {
            filter.languages = filter.languages.filter(item => item !== languageId)
        } else {
            if (!filter.languages) {
                filter.languages = []
            }
            filter.languages.push(languageId)
        }
        this.setState(filter)
    }

    //function to use in all courses list - filters item if category is in filter 
    filterLanguage = function (e) {
        if (this.state.filter.languages && this.state.filter.languages.length > 0) {
            if (e.language) {
                return this.state.filter.languages.find(item => item === e.language.id)
            } else {
                return false
            }
        }
        return true
    }

    onTagsChange = function (e) {
        let tagId = e.target.getAttribute("tag-id")

        let filter = this.state.filter;
        if (filter.tags && filter.tags.find(item => item === tagId)) {
            filter.tags = filter.tags.filter(item => item !== tagId)
        } else {
            if (!filter.tags) {
                filter.tags = []
            }
            filter.tags.push(tagId)
        }

        this.setState(filter)
    }

    //function to use in all courses list - filters item if category is in filter 
    filterTags = function (e) {
        if (this.state.filter.tags && this.state.filter.tags.length > 0) {
            if (e.tags) {
                return e.tags.filter(tag => this.state.filter.tags.includes(tag.id)).length > 0
            } else {
                return false
            }
        }
        return true
    }

    render() {

        return (
            <div className="main-wrapper course-page">

                {/* Header 2 */}
                <Header />

                <Styles>
                    {/* Course Grid */}
                    <section className="course-grid-area">
                        <Container>
                            <Row>
                                <Col lg="3" md="4" sm="5">
                                    <div className="course-sidebar">
                                        <Row>
                                            <Col md="12">
                                                <div className="course-category">
                                                    <h5>{categoriesData.secTitle[i18n.language]}</h5>
                                                    <ul className="category-item list-unstyled">
                                                        {
                                                            categoriesData.categories.map((data) => (
                                                                <li className="check-btn" key={data.id}>
                                                                    <label htmlFor="cat1">
                                                                        <input type="checkbox"
                                                                            id={"category-checkbox-" + data.id}
                                                                            className="check-box "
                                                                            onChange={this.onCategoryChange.bind(this)}
                                                                            category={data.id}
                                                                            checked={!!(this.state.filter.categories && this.state.filter.categories.find(e => e === data.id))} />
                                                                        {data.name[i18n.language]}
                                                                    </label>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="course-category">
                                                    <h5>{audienceData.secTitle[i18n.language]}</h5>
                                                    <ul className="category-item list-unstyled">
                                                        {
                                                            audienceData.audiences.map((data) => (
                                                                <li className="check-btn" key={data.id}>
                                                                    <label htmlFor="cat1">
                                                                        <input type="checkbox"
                                                                            id={"audience-checkbox-" + data.id}
                                                                            className="check-box"
                                                                            onChange={this.onAudienceChange.bind(this)}
                                                                            audience={data.id}
                                                                            checked={!!(this.state.filter.audiences && this.state.filter.audiences.find(e => e === data.id))} />
                                                                        {data.name[i18n.language]}</label>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="course-category">
                                                    <h5>{languagesData.secTitle[i18n.language]}</h5>
                                                    <ul className="category-item list-unstyled">
                                                        {
                                                            languagesData.languages.map((data) => (
                                                                <li className="check-btn" key={data.id}>
                                                                    <label htmlFor="cat1">
                                                                        <input type="checkbox"
                                                                            id={"language-checkbox-" + data.id}
                                                                            className="check-box"
                                                                            onChange={this.onLanguageChange.bind(this)}
                                                                            language-id={data.id}
                                                                            checked={!!(this.state.filter.languages && this.state.filter.languages.find(e => e === data.id))} />
                                                                        {data.name[i18n.language]}</label>
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                {/* Course Tags */}
                                                <div className="course-tag">
                                                    <h5>{tagsData.secTitle[i18n.language]}</h5>
                                                    <div className="tag-box">
                                                        <ul className="tags-btn-list list-unstyled list inline">
                                                            { this.props.tags &&
                                                                this.props.tags.map((data, i) => (
                                                                    <li 
                                                                        key={data.id} 
                                                                        tag-id={data.id} 
                                                                        className={!!(this.state.filter.tags && this.state.filter.tags.find(e => e === data.id))?'list-inline-item active':'list-inline-item'}
                                                                        onClick={this.onTagsChange.bind(this)}
                                                                        >{data.name[i18n.language]}</li>
                                                                ))

                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg="9" md="8" sm="7">

                                    <Row className="course-items">
                                        {/* Course Item */}
                                        { this.props.courses &&
                                            this.props.courses
                                            .filter(this.filterCategory.bind(this))
                                            .filter(this.filterAudience.bind(this))
                                            .filter(this.filterLanguage.bind(this))
                                            .filter(this.filterTags.bind(this))
                                            .map((data, i) => (
                                                // coursesData.sort((a,b)=>(parseFloat(b.order) - parseFloat(a.order))).map((data, i) => (
                                                <Col lg="4" md="12" key={i} id={"course-" + data.id}>
                                                    <div className="course-item" key={i}>
                                                        <a href={data.promoPrice && data.promoPrice.link ? data.promoPrice.link : data.link} target="_blank">
                                                            <div className="course-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.imgUrl})` }}>
                                                                <div className="course-price">
                                                                    <p>{data.promoPrice ? data.promoPrice[i18n.language] : data.price[i18n.language]}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <div className="course-content">
                                                            <h6 className="heading"><a href={data.promoPrice && data.promoPrice.link ? data.promoPrice.link : data.link}  target="_blank">{data.courseTitle[i18n.language]}</a></h6>
                                                            {/* <p className="desc">{data.desc[i18n.language]} </p> */}
                                                            {(data.promoPrice && data.promoPrice.desc) &&
                                                                <p className="promo">{data.promoPrice.desc[i18n.language]}</p>
                                                            }
                                                            <div className="course-face d-flex justify-content-between">
                                                                <div className="duration">
                                                                    <p><i className="las la-globe-africa"></i>{data.language.name[i18n.language]}</p>
                                                                </div>
                                                                <div className="rating">
                                                                    <ul className="list-unstyled list-inline">
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        <li className="list-inline-item"><i className="las la-star"></i></li>

                                                                        {data.rating === "4.5" &&
                                                                            <li className="list-inline-item"><i className="las la-star-half-alt"></i></li>
                                                                        }
                                                                        {data.rating === "5" &&
                                                                            <li className="list-inline-item"><i className="las la-star"></i></li>
                                                                        }
                                                                        <li className="list-inline-item">({data.rating})</li>
                                                                    </ul>
                                                                </div>

                                                            </div>
                                                            <div className="tags">
                                                                Tags: {
                                                                    data.tags.map(tag => tag.name[i18n.language]).join(', ')
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }

                                    </Row>

                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Styles>

                {/* Footer 2 */}
                <Footer />

            </div>
        )
    }
}


export default connect(mapStateToProps)(Courses)
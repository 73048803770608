import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .faq-page {
        background : ${colors.socials};
        .faq-area {
            padding : 30px 0 30px;

            .sec-title {
                padding-bottom: 20px; 
                @media(max-width: 575px) {
                    padding-bottom: 10px;
                }
            }

            .faq-item {
                border-radius : 5px;
                border  : 1px solid ${colors.border1};
                margin:10px;
                width        : 100%;
                
                    button.accordion-button {
                        border-radius : 5px;
                        border  : 1px solid ${colors.border1};
                        padding : 10px 0 10px;
                        background  : ${colors.gr_grey_rev};
                        width        : 100%;
                        text-align   : left;

                        
                        div.accordion-icon {
                            background  : ${colors.grey_dark};
                            height      : 18px;
                            text-align  : center;
                            float       : left;
                            margin-right: 12px;

                            i {
                                font-size   : 20px;
                                color       : #ffffff;
                                width       : 32px;
                                line-height : 18px;
                                padding-left: 2px
                            }

                        }

                        p {
                            font-size  : 16px;
                            color      : ${colors.white};
                            font-weight: 500;
                            line-height: 18px;
                        }
                    }

                    .accordion-content {
                        max-height: 0;
                        overflow  : hidden;
                        transition: max-height 0.2s ease-in-out;
                        padding-left : 15px;
                        background : ${colors.white};
                        box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);

                        .faq-item-line {
                            font-size   : 14px;
                            color       : ${colors.text3};
                            line-height : 28px;
                            min-height  : 28px;

                            a {
                                font-size   : 14px;
                                font-weight  : 500;
                                color: ${colors.green};
                                

                            }

                            .youtube-img {
                                min-width: 200px;
                                max-width: 34%;
                                border-radius : 5px;
                                box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);
                
                                @media(max-width: 991px) {
                                }                                
                            }

                            .inline-img {
                                float: left;
                                padding: 5px;
                                width: 16%;
                                height: 16%;
                                @media(max-width: 575px) {
                                    width: 30%;
                                    height: 30%;
                                }
                            }
                        }

                    }

                    .accordion-content.show {
                        max-height: 100%;
                    }


                }
            }
        }
    }
`;
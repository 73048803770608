import React, { Component } from 'react';
import Header from './components/Header';
import Footer from './components/FooterTwo';
import AboutUs from './components/AboutUs'
import CourseSlider from './components/CourseSlider';
import LessonsTabs from './components/LessonsTabs';
import HomeCategories from './components/HomeCategories';
import HomeCategoriesExtra from './components/HomeCategoriesExtra';
import HomeSelectedLesson from './components/HomeSelectedLesson';

export default class HomeOne extends Component {
    render() {
        return (
            <div className="main-wrapper" >

                {/* Header */}
                < Header />

                {/* About us */}
                < AboutUs />

                {/* Categories selection */}
                < HomeCategoriesExtra />

                {/* Teacher cources selection */}
                < HomeSelectedLesson />

                {/* Categories selection */}
                < HomeCategories />


                {/* Course Slider
                < CourseSlider/> */}

                {/* Lessons tabs */}
                {/* < LessonsTabs /> */}

                {/* Footer */}
                < Footer />

            </div >
        )
    }
}

import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './helper/ScrollToTop';
import { Helmet } from 'react-helmet'
import { GlobalStyle } from "./components/common/styles/global.js";
import Courses from './pages/courses/Courses';

import HomeOne from './HomeOne';
import HomeTwo from './HomeTwo';
import Home from './Home';
import About from './pages/about/About';
import CourseGrid from './pages/courses/CourseGrid';
import CourseList from './pages/courses/CourseList';
import CourseDetails from './pages/courses/CourseDetails';
import Instructor from './pages/instructor/Instructors';
import InstructorDetails from './pages/instructor/InstructorDetails';
import Gallery from './pages/gallery/Gallery';
import Events from './pages/events/Events';
import EventDetails from './pages/events/EventsDetails';
import Login from './pages/account/Login';
import Register from './pages/account/Register';
import Contact from './pages/contact/Contact';
import Faq from './pages/faq/Faq';
import PageNotFound from './pages/404/PageNotFound';
import ComingSoon from './pages/comingsoon/ComingSoon';
import BlogClassic from './pages/blog/BlogClassic';
import BlogGrid from './pages/blog/BlogGrid';
import BlogDetails from './pages/blog/BlogDetails';
import Product from './pages/shop/Products';
import ProductDetails from './pages/shop/ProductDetails';
import Cart from './pages/shop/Cart';
import { useTranslation } from 'react-i18next';
import FastClick from 'react-fastclick-alt'
import Lessons from './pages/lessons/Lessons';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Provider } from 'react-redux'
import store from './store'
import Lesson from './pages/lessons/Lesson';
import LessonCheckout from './pages/user/Checkout.js'
import LessonCheckoutSuccess from './pages/user/CheckoutSuccess';
import LessonCheckoutFail from './pages/user/CheckoutFail';
import OnlineLessons from './pages/lessons/OnlineLessons';
import MenuCategory from './pages/lessons/MenuCategory';
import Auth0Callback from './helper/auth0';
import { RedirectContext } from './helper/redirect';
import history from './helper/history';
import TeacherLesson from './pages/lessons/TeacherLesson';
import TeacherLanding from './pages/lessons/TeacherLanding';
import Account from './pages/account/Account.js';
import FarmLanding from './pages/lessons/FarmLanding';
import FarmLesson from './pages/lessons/FarmLesson';


function App() {
  const { t, i18n } = useTranslation();
  //setting default language  
  if (i18n.language !== 'ru') {
    i18n.language = 'en';
  }

  const [redirectUri, setRedirectUri] = React.useState(null);


  const onRedirectCallback = (appState) => {
    setRedirectUri((appState && appState.returnTo) ? appState.returnTo : window.location.origin);
  };

  const providerConfig = {

    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID,
    redirectUri: `${window.location.origin}/auth0callback`,
    onRedirectCallback: onRedirectCallback,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
  };

  React.useEffect(() => {
    if (redirectUri) {
      console.log('going to redirect '+ redirectUri);
      history.push(redirectUri);
      console.log('pushed to '+ redirectUri);
    }
  }, [redirectUri]);

  return (
    <Auth0Provider {...providerConfig}>
       <RedirectContext.Provider value={{ redirectUri, setRedirectUri }}>
      <Provider store={store}>

        <FastClick>
          <Router history={history}>
            {/* Title */}
            <Helmet title={i18n.t("title", "Crazy Plasticine studio")} />
            <GlobalStyle />
            <ScrollToTop />
            <Switch>
              <Route exact path={`${process.env.PUBLIC_URL + "/"}`} component={Home} />
              <Route path={`${process.env.PUBLIC_URL + "/auth0callback"}`} component={Auth0Callback} />
              <Route path={`${process.env.PUBLIC_URL + "/account"}`} component={Account} />
              <Route path={`${process.env.PUBLIC_URL + "/courses"}`} component={Courses} />
              <Route path={`${process.env.PUBLIC_URL + "/lessons/teachers"}`} component={TeacherLesson} />
              <Route path={`${process.env.PUBLIC_URL + "/lessons/farm"}`} component={FarmLesson} />
              <Route path={`${process.env.PUBLIC_URL + "/lessons/:url"}`} component={Lesson} />

              {/* <Route path={`${process.env.PUBLIC_URL + "/onlinelessons"}`} component={OnlineLessons} /> */}
              <Route path={`${process.env.PUBLIC_URL + "/online"}`} render={(props) => <MenuCategory {...props} category='online' showFilter='true' />} />
              <Route path={`${process.env.PUBLIC_URL + "/adults"}`} render={(props) => <MenuCategory {...props} category='adults' showFilter='true' />} />
              <Route path={`${process.env.PUBLIC_URL + "/pictures"}`} render={(props) => <MenuCategory {...props} category='pictures' showFilter='true' />} />
              <Route path={`${process.env.PUBLIC_URL + "/figurines"}`} render={(props) => <MenuCategory {...props} category='figurines' showFilter='true' />} />
              <Route path={`${process.env.PUBLIC_URL + "/masterpieces"}`} render={(props) => <MenuCategory {...props} category='masterpieces' showFilter='true' />} />
              {/* <Route path={`${process.env.PUBLIC_URL + "/teachers"}`} render={(props) => <MenuCategory {...props} category='teachers' showFilter='false' />} /> */}
              <Route path={`${process.env.PUBLIC_URL + "/teachers"}`} component={TeacherLanding}/>
              <Route path={`${process.env.PUBLIC_URL + "/farm"}`} component={FarmLanding}/>


              <Route path={`${process.env.PUBLIC_URL + "/faq"}`} component={Faq} />
              <Route path={`${process.env.PUBLIC_URL + "/gallery"}`} component={Gallery} />
              <Route path={`${process.env.PUBLIC_URL + "/checkout"}`} component={LessonCheckout} />
              <Route path={`${process.env.PUBLIC_URL + "/checkoutsuccess"}`} component={LessonCheckoutSuccess} />
              <Route path={`${process.env.PUBLIC_URL + "/checkoutfail"}`} component={LessonCheckoutFail} />

              <Route path={`${process.env.PUBLIC_URL + "/coming-soon"}`} component={ComingSoon} />
              {/* <Route path={`${process.env.PUBLIC_URL + "/home-one"}`} component={HomeOne} />
              <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo} />

              <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={About} />
                <Route path={`${process.env.PUBLIC_URL + "/course-grid"}`} component={CourseGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/course-list"}`} component={CourseList} />
                <Route path={`${process.env.PUBLIC_URL + "/course-details"}`} component={CourseDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/instructor"}`} component={Instructor} />
                <Route path={`${process.env.PUBLIC_URL + "/instructor-details"}`} component={InstructorDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/events"}`} component={Events} />
                <Route path={`${process.env.PUBLIC_URL + "/event-details"}`} component={EventDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/login"}`} component={Login} />
                <Route path={`${process.env.PUBLIC_URL + "/registration"}`} component={Register} />
                <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact} />
                <Route path={`${process.env.PUBLIC_URL + "/404"}`} component={PageNotFound} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} component={BlogClassic} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-grid"}`} component={BlogGrid} />
                <Route path={`${process.env.PUBLIC_URL + "/blog-details"}`} component={BlogDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/products"}`} component={Product} />
                <Route path={`${process.env.PUBLIC_URL + "/product-details"}`} component={ProductDetails} />
                <Route path={`${process.env.PUBLIC_URL + "/cart"}`} component={Cart} />  */}

              <Route path="*" component={PageNotFound} />


            </Switch>
          </Router>
        </FastClick>

      </Provider>
      </RedirectContext.Provider>
    </Auth0Provider>
  )
}

export default App;
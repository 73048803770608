import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import MobileMenu from './common/MobileMenu';
import { Styles } from "./styles/header.js";
import { Auth0Context } from "@auth0/auth0-react";
import i18n from '../i18n';
import store from '../store/'
import { loginSuccess, logoutStart } from '../store/authSlice';
import { connect } from 'react-redux';
import { loadPublic, loadPrivate } from '../store/lessonSlice.js';
import { loadCategories, loadComplexity, loadGroups, loadTags, loadLessonsFaq } from '../store/refSlice.js';
import { loadCourses } from '../store/coursesSlice.js';
import { colors } from './common/element/elements.js';


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        categories: state.refs.categories,
    }
}

class Header extends Component {
    // Assign the context type to a static property
    static contextType = Auth0Context;


    componentDidMount() {
        let drafts = localStorage.getItem("drafts")

        const dispatch = this.props.dispatch
        if (!store.getState().lesson.statusPublic)
            dispatch(loadPublic(drafts));
        if (!store.getState().refs.statusGroups)
            dispatch(loadGroups());
        if (!store.getState().refs.statusTags)
            dispatch(loadTags());
        if (!store.getState().refs.statusCategories)
            dispatch(loadCategories());
        if (!store.getState().refs.statusComplexity)
            dispatch(loadComplexity());
        if (!store.getState().refs.statusLessonsFaq)
            dispatch(loadLessonsFaq());
        if (!store.getState().courses.status)
            dispatch(loadCourses());

    }

    componentDidUpdate() {
        if (this.props.isAuthenticated && store.getState().lesson.public && !store.getState().lesson.statusPrivate) {
            this.props.dispatch(loadPrivate({ token: store.getState().auth.token }))
        }
    }

    render() {

        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
        }
        const { getAccessTokenSilently, isAuthenticated, loginWithRedirect, logout } = this.context;


        const loginFlow = function () {
            //store.dispatch(loginStart());
            loginWithRedirect({ appState: { returnTo: window.location.pathname }, ui_locales: i18n.language });
        }

        const logoutFlow = function () {
            //store.dispatch(logoutStart());
            logout({ returnTo: window.location.origin});
        }

        // process logout for blocked users
        const params = new URLSearchParams(window.location.search);
        if (params.get('error') === 'access_denied' && params.get('error_description') === 'User blocked') {
            logoutFlow();
        }

        //save token
        if (isAuthenticated && !store.getState().auth.isAuthenticated && !sessionStorage.getItem('auth-started')) {
            sessionStorage.setItem('auth-started', true)
            Promise.resolve(
                getAccessTokenSilently()).then(
                    result => {
                        if (sessionStorage.getItem('auth-started')) {
                            sessionStorage.removeItem('auth-started');
                        }
                        store.dispatch(loginSuccess({ token: result }))
                    }
                );
        }


        return (
            <Styles>
                {/* Topbar 2 */}
                <section className="top-bar2">
                    <Container>
                        <Row>
                            <Col lg="7" md="9">
                                <div className="bar-left">
                                    <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item"><a href="https://www.facebook.com/crazyplasticine"><i className="fab fa-facebook"></i>crazyplasticine</a></li>
                                        <li className="list-inline-item"><a href="https://www.instagram.com/crazy_plasticine/"><i className="fab fa-instagram"></i>@crazy_plasticine</a></li>
                                        <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCZVWFzGD7hDsYsvzc4nxPkw"><i className="fab fa-youtube"></i>crazy_plasticine</a></li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg="5" md="3">
                                <div className="bar-right d-flex justify-content-end">
                                    <ul className="list-unstyled list-inline text-nowrap">

                                        {i18n.language === 'en'
                                            ? <li className="list-inline-item">{i18n.t('language.en', "English")}</li>
                                            : <li className="list-inline-item"><a href="#en" onClick={() => changeLanguage('en')} >{i18n.t('language.en', "English")}</a></li>
                                        }

                                        <li className="list-inline-item">|</li>
                                        {i18n.language === 'ru'
                                            ? <li className="list-inline-item">{i18n.t('language.ru', "Russian")}</li>
                                            : <li className="list-inline-item"><a href="#ru" onClick={() => changeLanguage('ru')} >{i18n.t('language.ru', "Russian")}</a></li>
                                        }
                                        <li className="list-inline-item">|</li>
                                        {isAuthenticated
                                            ? <li className="list-inline-item">
                                                <Dropdown>
                                                    <Dropdown.Toggle style={{cursor:"pointer"}} as="a">{i18n.t('account.text',"Account")}</Dropdown.Toggle>
                                                    <Dropdown.Menu as="ul">
                                                        <Dropdown.Item as="li"><Link to={process.env.PUBLIC_URL + "/account"}>{i18n.t('account.courses',"My courses")}</Link></Dropdown.Item>
                                                        <Dropdown.Item as="li" ><a href="#" onClick={logoutFlow}>{i18n.t('logout.text',"Logout")}</a></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            : <li className="list-inline-item"><a href="#" onClick={() => loginFlow()} >{i18n.t('login.text', "Login/Signup")}</a></li>
                                        }

                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Logo Area 2 */}
                <section className="logo-area2">
                    <Container>
                        <Row className="align-items-center">
                            <Col md="3">
                                <div className="logo">
                                    <Link to={process.env.PUBLIC_URL + "/"}><img src={process.env.PUBLIC_URL + "/assets/images/logo_text-brown.png"} alt="" /></Link>
                                </div>
                            </Col>
                            <Col md="9">
                                <div className="menu-box d-flex justify-content-end">
                                    <ul className="nav menu-nav">
                                        <li className="nav-item">
                                            <Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>{i18n.t("menu.home.text", "Home")} </Link>
                                        </li>

                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle" data-toggle="dropdown">{i18n.t("menu.lessons.text", "Lessons")} <i className="las la-angle-down"></i></a>
                                            <ul className="dropdown list-unstyled">
                                                {store.getState().refs.categories
                                                    && store.getState().refs.categories.length
                                                    && store.getState().refs.categories
                                                        .filter(item => item.inmenu)
                                                        .map((category) => (

                                                            <li key={'menu-lesson-' + category.id} className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + category.starturl}>{category.title[i18n.language]}</Link></li>

                                                        ))

                                                }
                                            </ul>
                                        </li>

                                        {/* <li className="nav-item">
                                            <Link className="nav-link" to={process.env.PUBLIC_URL + "/courses"}>{i18n.t("menu.courses.text", "Courses")}</Link>
                                        </li> */}
                                        <li className="nav-item">
                                            <Link className="nav-link" to={process.env.PUBLIC_URL + "/faq"}>{i18n.t("menu.faq.text", "Q&A")}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="https://crazyplasticine.art/pictures">{i18n.t("menu.gallery.text", "Gallery")}</a>
                                        </li>
                                        {/* <li className="nav-item dropdown active">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">{i18n.t("menu.home.text","Home")} <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/"}>Home Style 1</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/home-two"}>Home Style 2</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Pages <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/about"}>About Us</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/gallery"}>Gallery</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/login"}>Log In</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/registration"}>Registration</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/faq"}>Faq</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/404"}>404</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/coming-soon"}>Coming Soon</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Courses <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-grid"}>Course Grid</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-list"}>Course List</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/course-details"}>Course Details</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Instructor <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/instructor"}>Instructors</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/instructor-details"}>Instructor Details</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Event <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/events"}>Events</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/event-details"}>Event Details</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Blog <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-classic"}>Blog Classic</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-grid"}>Blog Grid</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/blog-details"}>Blog Details</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Link className="nav-link dropdown-toggle" to={process.env.PUBLIC_URL + "/"} data-toggle="dropdown">Shop <i className="las la-angle-down"></i></Link>
                                                <ul className="dropdown list-unstyled">
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/products"}>Products</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/product-details"}>Product Details</Link></li>
                                                    <li className="nav-item"><Link className="nav-link" to={process.env.PUBLIC_URL + "/cart"}>Cart</Link></li>
                                                </ul>
                                            </li> */}
                                    </ul>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>


                {/* Mobile Menu */}
                <MobileMenu />
            </Styles>
        )
    }
}

export default connect(mapStateToProps)(Header)

import React from 'react';

export const Wave = ({ left, reversed, width }) => {
  const transformStyle = reversed
    ? {
        transform: "scale(1, -1)",
        WebkitTransform: "scale(1, -1)",
        MozTransform: "scale(1, -1)",
        OTransform: "scale(1, -1)",
      }
    : {};

  const containerStyle = {
    ...{
      width: '100%', // w-full
      overflow: 'hidden', // overflow-hidden
      position: 'absolute', // absolute
      zIndex: 10, // z-10
    },
    ...(reversed ? { top: '-2px' } : { bottom: '-2px' }),
  };

  const waveStyle = {
    background: 'transparent', // bg-transparent
    position: 'relative', // relative
    left: left,
    width: width,
  };

  return (
    <div style={containerStyle}>
      <div style={waveStyle}>
        <div style={transformStyle}>
          <svg version="1.1" viewBox="0 0 5223 186" preserveAspectRatio="none">
            <path
              d="M2057.1 37.42c206.9 83.752 297.8 147.42 660.2 92.25 493-75.037 624.1 29.02 898.2 32.826 226.7 3.145 357-81.27 521.4-81.27 164.3 0 274 98.65 436.4 101.63C4743.3 186 5259 186 5259 186L0 184.4h715.9c11.4 0 16.6-1.655 19.3-4.303 22.9-22.4 142.7-122.705 415.2-87.89 151.2 19.31 310.3-23.284 459-67.2 115.1-34.098 327-36.415 447.7 12.413z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

import React, { Component } from 'react';
import Datas from '../../data/gallery/gallery-page.json';
import { Container, Row, Col } from 'react-bootstrap';
import ModalImage from "react-modal-image";
import Header from '../../components/Header';
import Pagination from './../../components/Pagination';
import Footer from '../../components/Footer';
import { Styles } from './styles/gallery.js';

class Gallery extends Component {
    render() {

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper gallery-page">

                    {/* Header 2 */}
                    <Header />

                    {/* Gallery Area */}
                    <section className="gallery-page-area">
                        <Container>
                            <Row>
                                {
                                    Datas.sort((a,b)=>(b.galleryImage.localeCompare(a.galleryImage)))
                                          .map((data, i) => (
                                        <Col lg="4" sm="6" key={i}>
                                            <div className="gallery-box">
                                                <ModalImage 
                                                    small={process.env.PUBLIC_URL + `/assets/images/gallery/${data.galleryImage}-thumb.jpg`} 
                                                    large={process.env.PUBLIC_URL + `/assets/images/gallery/${data.galleryImage}.jpg`} 
                                                    hideDownload="true" 
                                                    hideZoom="true"/>
                                            </div>
                                        </Col>
                                    ))
                                }

                                {/* <Col md="12" className="text-center">
                                    <Pagination />
                                </Col> */}
                            </Row>
                        </Container>
                    </section>

                    {/* Footer*/}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default Gallery
import styled from "styled-components";
import { colors, fonts } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .landing1 {
    background-image: url("${process.env.PUBLIC_URL}/assets/images/teachers/bg1.jpg");
    background-size: cover;
    background-position: center 80%;
    background-repeat: no-repeat;

    position:relative;
    text-align: left;

    .headersec {
      text-align: left;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p.line1 {
        font-family: ${fonts.playfair};
        font-size: 12px;
        color: ${colors.white};
      }

      p.line2 {
        font-family: ${fonts.roboto};
        font-size: 14px;
        color: ${colors.white};
        margin-bottom: 5px;
      }

      p.header {
        color: ${colors.white};
        font-family: ${fonts.playfair};
        font-size: 24px;
        font-weight: 400;
        text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.17);
        @media (max-width: 767px) {
          font-size: 22px;
        }
      }

      p.margintop {
        margin-top: 15px;
        @media (max-width: 767px) {
          margin-top: 10px;
        }
      }
      p.marginbottom {
        margin-bottom: 15px;
        @media (max-width: 767px) {
          margin-bottom: 10px;
        }
      }

    }

    p.line3 {
      font-family: ${fonts.playfair};
      font-size: 14px;
      color: ${colors.black2};
      margin-bottom: 20px;
      margin-top: 40px;
      bottom: 0;
      width: 80%;
    }

    .coverpicarea {
      text-align: right;
      margin: 10px 0 10px;

      .coverpic {
        height: auto;
        width: 300px;
        max-width:100%;
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5); 
        @media (max-width: 575px) {
          display: none;
        }
      }
    }

    .coverpicareabottom {
      text-align: center;
     
      margin-bottom: 15px;

      .coverpicbottom {
        height: auto;
        width: 300px;
        max-width:100%;
        box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5); 
        
      }

      @media (min-width: 575px) {
        display: none;
      }
    }

  }

  .landing1Button {
    width: 100%;
    align-items: center;
    text-align: center;
    margin-bottom: 15px;
  }

  .aboutsec {
    background: ${colors.socials};
    position: relative;
    padding-top: 10px;
    padding-bottom: 40px;

    align-items: center;
    text-align: center;

    .aboutpicarea {
      .aboutpic {
        width: 100%;

        @media (max-width: 575px) {
          display: none;
        }
      }
    }

    .abouttextarea {

      padding-top:15px; 

      p.abouttext {
        font-family: ${fonts.roboto};
        font-size: 14px;
        color: ${colors.black1};
        padding: 20px 0 20px;
      }

      .aboutTable{

      }
      
      .aboutItem {
        display: flex; 
        align-items: center; 
        padding: 10px; 
      }
      
      .aboutItemImage {
        width: 32px; 
        height: 32px; 
        margin-right: 10px; 
      }
      
      .aboutItemText {
        text-align: left; 
        flex-grow: 1; 
      }
      
    }
  }



  .landing2 {
    background-color: ${colors.white};
    margin: 10px 0 10px;

  }

  

  .featureSection {
    background: ${colors.socials};
    padding: 20px 0; /* some padding for the section */
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .sectionTitle {
      text-align: center;
      padding: 20px 0;
      font-family: ${fonts.playfair};
      text-transform: uppercase;
      font-size: 26px;
    }
  }

  .featureBlock {
    background: ${colors.socials};
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 20px; /* spacing between icon and text */
    margin-bottom: 20px; /* spacing between blocks */
    height: 120px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2); /* Add this line */
  }

  .featureIcon {
    flex-shrink: 0; /* prevent icon from shrinking */
    width: 40px;
    height: 40px;
  }

  .featureText {
    /* your text styles here */
  }

  .discoverButton {
    background: ${colors.custom1};
    border: none;
    border-radius: 10px;
    color: white;
    padding: 10px 60px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.2);

    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    transform: scale(1);

    @media (max-width: 450px) {
      padding: 5px 50px;
      font-size: 20px;
    }

  }

  .discoverButton:hover {
    /* Slightly reduce the size of the button on hover */
    transform: scale(0.95);
  }

  .paidButton {
    background: ${colors.green};
  }
  


  .progBlock {
    margin-bottom: 20px;
    max-width: 450px;
    .progImg {
      img {
        border-radius: 20px 20px 0 0;
      }
    }
    .progTextArea {
      box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
      padding: 10px;
      background: #ffffff;
      border-radius: 0 0 20px 20px;
      h6 {
        font-weight: bold;
      }
    }
  }

  .toolsSection {
    background: ${colors.socials};
    padding: 20px 0; /* some padding for the section */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
      li {
        font-size: 14px;
      }

    .sectionTitle {
      text-align: center;
      padding: 20px 0;
      font-family: ${fonts.playfair};
      text-transform: uppercase;
      font-size: 26px;
    }
  
    img {
      max-width: 200px;
      
      @media (max-width: 575px) {
        display: none;
      }
    }

  }


  .stepsSection {
    font-family: 'Arial', sans-serif; /* Update this to your font */
    text-align: center;
    margin: 20px 0;

    .stepItem {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 20px; /* Spacing between steps */
    }
    
    .stepImage {
      max-width: 50px;
      max-height: 50px;
      width: auto;
      height: auto;
      object-fit: contain; /* Ensures image isn't stretched */
      margin-right: 20px; /* Gap between image and text */
    }
    
    .stepText {
      text-align: left;
      font-size: 14px;
    }

    .underscored {
      text-decoration: underline;
    }
  }
  


  .paymentSection {
    background: ${colors.socials};
    padding: 20px 0; /* some padding for the section */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .payment {
      background: ${colors.white};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-radius: 10px; /* adjust as needed */
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25); /* adjust as needed */
      margin: 20px 0; /* space around the payment div */
    
      p.price {
        font-size : 20px;
        margin-bottom: 20px;
      }
  
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: left;
      
        li {
            display: block;
            width: 100%; /* li elements span the entire width of the ul */
            white-space: nowrap; /* prevents text wrapping */
            overflow: hidden; /* hides text that overflows the li */
            text-overflow: ellipsis; /* replaces overflow with ... */
            padding-left: 10px; /* space for the - before the li */
          }
      
          li:before {
            content: "- "; /* adds a - before each li */
          }

    }
  
      
    
    
    }
   
  }

  .testimonialsSection {
    background: ${colors.white};

    .sectionTitle {
      text-align: center;
      padding: 20px 0;
      font-family: ${fonts.playfair};
      text-transform: uppercase;
      font-size: 26px;
    }
  

.testimonialItem {
    display: flex;
    border: 1px solid grey;
    padding: 10px;
    background-color: ${colors.white};
    min-height: 250px;
}

.testimonialImage {
    max-height: 250px;
    width: auto; /* maintain the image's aspect ratio */
    margin-right: 15px;
}

.testimonialContent {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
  padding: 10px; /* Add some space around content */
  flex-grow: 1; /* Allow this container to grow and occupy available space */
}

blockquote {
    font-size: larger; 
    position: relative;
    padding: 20px;
    quotes: "“" "”";
}

blockquote:before {
    content: open-quote;
    font-size: 5em; /* adjust as necessary */
    position: absolute;
    top: -10px; /* adjust as necessary */
    left: -10px; /* adjust as necessary */
}

blockquote:after {
    content: close-quote;
    font-size: 5em; /* adjust as necessary */
    position: absolute;
    bottom: -50px; /* adjust as necessary */
    right: -10px; /* adjust as necessary */
}

.slide {
  background-color: ${colors.white} !important;
}

.testimonialName {
    font-weight: bold;
    position: absolute;
    bottom: 10px;
    right: 10px;
}
}



  .landing5 {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 300px;
    backgrouund-attachment: fixed;
    transform: translate3D(0, 0, 0);
    -moz-transform: unset;
    opacity: 0.4;

    @media (max-width: 767px) {
      padding: 30px 0 20px;
    }
  }

  .padding-bottom-20 {
    padding-bottom: 20px;
  }

  .checkIcon {
    flex-shrink: 0; /* prevent icon from shrinking */
    width: 20px;
    height: 20px;
  }

  .waveContainer {
    
    position: relative;
    padding-top: 186px;
    overflow: hidden;

    @media (max-width: 992px) {
      padding-top: 125px;
    }

    @media (max-width: 768px) {
      padding-top: 100px;
    }

    @media (max-width: 576px) {
      padding-top: 70px;
    }

  }

  h2 {
    font-size: 18px;
  }


  .faq-item {
    border-radius : 5px;

    border  : 2px solid ${colors.custom1};
    margin:10px;
    width        : 100%;
    
        button.accordion-button {
            border-radius : 5px;
            border  : 1px solid ${colors.border1};
            padding : 10px 0 10px;
            background  : ${colors.white};
            width        : 100%;
            text-align   : left;

            
            div.accordion-icon {
                background  : ${colors.white};
                height      : 18px;
                text-align  : center;
                float       : left;
                margin-right: 12px;

                i {
                    font-size   : 20px;
                    color       : ${colors.custom1};
                    width       : 32px;
                    line-height : 18px;
                    padding-left: 2px
                }

            }

            p {
                font-size  : 16px;
                color      : ${colors.black};
                font-weight: 500;
                line-height: 18px;
            }
        }

        .accordion-content {
            max-height: 0;
            overflow  : hidden;
            transition: max-height 0.2s ease-in-out;
            padding-left : 15px;
            background : ${colors.white};
            box-shadow: 15px -5px 20px rgba(0, 0, 0, 0.07);
            border-radius: 5px;

            .faq-item-line {

              
          
            }

        }

        .accordion-content.show {
            max-height: 100%;
        }


    }
    .element {

      li, p {
        font-size   : 14px;
        color       : ${colors.text3};
        line-height : 28px;
        min-height  : 28px;
      }

    }

    .elementbutton {
        a {
          color: inherit;
          background-color: #4CAF50;
          color: white;
          padding: 2px 5px;
          text-align: center;
          text-decoration: none;
          display: inline-block;
          font-size: 14px;
          margin: 4px 2px;
          cursor: pointer;
          border-radius: 5px;
      }
    }

    .elementunordered_list {
      margin-left:15px;
    }

  }
`;

import styled from "styled-components";
import { colors, fonts } from "../common/element/elements.js";

export const Styles = styled.div`
  .background-blue {
    background: ${colors.bg2};
  }
  .background-dark {
    background: ${colors.socials};
  }

  .home-categories-area {
    padding: 30px 0 42px;

    .sec-title {
      margin-bottom: 24px;
      h4 {
        color: ${colors.black1};
        line-height: 35px;
        font-weight: 400;
        max-width: 550px;
        margin: auto;

        @media (max-width: 575px) {
          margin-bottom: 15px;
          font-size: 20px;
        }
      }
    }

    .sec-title-large {
        margin-bottom: 24px;
        h4 {
          color: ${colors.black1};
          line-height: 35px;
          font-weight: 400;
          max-width: 550px;
          margin: auto;
  
          @media (max-width: 575px) {
            margin-bottom: 15px;
            font-size: 20px;
          }
        }
      }

    .category-div {
      margin-bottom: 30px;

      .category-img {
        a {
          img {
            border-radius: 20px 20px 0 0;
          }
        }
      }

      .category-content {
        position: relative;

        .content-box {
          box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
          padding: 10px;
          background: #ffffff;
          border-radius: 0 0 20px 20px;

          .top-content {
            margin-bottom: 10px;

            .category-title {
              h6 {
                a {
                  font-family: ${fonts.roboto};
                  color: ${colors.black1};
                  display: inline-block;
                  line-height: 23px;
                  padding-top: 5px;
                  &:hover {
                    color: ${colors.green};
                  }
                }
              }
            }
          }

          .category-desc {
            p {
              font-size: 14px;
              color: ${colors.text3};
              //border-bottom : 1px solid ${colors.border1};
              padding-bottom: 10px;
              margin-bottom: 10px;
            }

            ul {
              li {
                margin-right: 15px;

                a {
                  font-size: 12px;
                  color: ${colors.text3};

                  i {
                    font-size: 16px;
                    color: ${colors.green};
                    vertical-align: text-bottom;
                  }

                  &:hover {
                    color: ${colors.green};
                  }
                }
              }
            }
          }

          @media (max-width: 1199px) {
            top: 9px;
          }

          @media (max-width: 991px) {
            position: unset;
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 30px 0 10px;
    }
  }
`;

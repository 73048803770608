import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/FooterTwo";
import { Styles } from "./styles/teacherLanding.js";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { Wave } from "../../components/Wave.js";
import { colors } from "../../components/common/element/elements.js";
import i18n from '../../i18n';
import { loadStripe } from '@stripe/stripe-js';

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lessonPublic: state.lesson.public,
    lessonPrivate: state.lesson.private,
  };
};

class TeachersLanding extends Component {
  constructor(props) {
    super(props);
  }

  onBuyClick = (e) => {
    sessionStorage.setItem("checkout-lesson-id", "teachers_01");
    let lesson = this.props.lessonPublic.find(
      (lesson) => lesson.id === "teachers_01"
    );
    const cartDetails = {}
    if (lesson){
      cartDetails.lessonId = lesson.id
      cartDetails.description = (i18n.language==='ru'?'Курс: ':'Course: ') + lesson.title
      if (lesson.price){
        cartDetails.priceId = process.env.REACT_APP_STRIPE_ENV_TEST?lesson.price.testStripeId:lesson.price.prodStripeId
      }
    }
    
    let headers = {
      'Content-Type': 'application/json'
    };
    
    if (this.props.token) {
      headers['Authorization'] = 'Bearer ' + this.props.token;
    }
    
    cartDetails.cancelUrl = "/teachers"

    fetch(
      '/.netlify/functions/create-checkout-session-v2',
      {
          method: 'post',
          headers: headers,
          body: JSON.stringify(cartDetails),
      }
  )
      .then((res) => {
          return res.json();
      })
      .then(async session => {
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
          if (session.sessionId){
              sessionStorage.setItem('checkout-stripe-session-id',session.sessionId)
              stripe.redirectToCheckout(session)
          }
      })
      .catch((error) => {
          console.log(error);
      });

    // this.props.history.push("/checkout");
  };

  onFaqItemClick = (event) => {
    let button = event.target;
    while (!button.classList.contains("accordion-button")) {
      button = button.parentNode;
    }
    // console.log(button);

    button.classList.toggle("active");
    const content = button.parentNode.children[1];
    // console.log(content);

    if (button.classList.contains("active")) {
      content.className = "accordion-content show";
      button.firstChild.firstChild.className = "las la-minus";
    } else {
      content.className = "accordion-content";
      button.firstChild.firstChild.className = "las la-plus";
    }
  };

  render() {
    const aboutData = [
      {
        img: "mortarboard.png",
        title: "Образование",
        text: "Архитектурное и педагогическое образование (русский как иностранный)",
      },
      {
        img: "shopping-bag.png",
        title: "Опыт работы",
        text: "Работаю с пластилином с 2015 года",
      },
      {
        img: "graduate.png",
        title: "Достижение",
        text: "Обучила более 500 детей и взрослых",
      },
      {
        img: "like.png",
        title: "Креативность",
        text: "Разработала свою технику работы с пластилином и получила международное признание",
      },
    ];

    const coursePoints = [
      "Научиться лепить картины из пластилина в технике барельеф",
      "Понять систему, которая позволяет создавать картины любой сложности в этой технике",
      "Узнать особенности обучения лепки для разных возрастных групп",
    ];

    const featureData = [
      {
        iconText: "15+",
        title: "ВОЗРАСТ ЦЕЛЕВОЙ АУДИТОРИИ",
        text: "Фокус на системный подход и умение учить других",
      },
      {
        iconText: "30",
        title: "ДНЕЙ ОБРАТНОЙ СВЯЗИ",
        text: "Рассмотрю каждую деталь и дам конструктивный отзыв",
      },
      {
        iconText: "6",
        title: "ЧАСОВ ПРОСМОТРА ВИДЕОМАТЕРИАЛОВ",
        text: "Без воды и понятным языком",
      },
    ];

    const featureBlocks = [
      {
        img: "family.png",
        title: "РОДИТЕЛИ И НЯНИ",
        text: "развивающие творческие стороны ребенка",
      },
      {
        img: "teacher.png",
        title: "УЧИТЕЛЯ ИЗО",
        text: "профессионально работающие с детьми",
      },
      {
        img: "painter.png",
        title: "ХУДОЖНИКИ",
        text: "работающие в стиле микс медиа, осваивающие новые техники",
      },
    ];

    const progData = [
      {
        img: "structure01.jpg",
        title: "1 - ВВЕДЕНИЕ",
        subtitle: "(11 видео по 3 минуты) *",
        text: "Покажу, что мы будем делать, расскажу какой пластилин лучше всего использовать и можно ли его заменить, дам обзор материалов для лепки. Объясню, что всё сложное состоит из простых деталей. Научу правильно создавать основные фигуры.",
      },
      {
        img: "structure02.jpg",
        title: "2 - ЛЕПКА КАРТИН",
        subtitle: "(3 урока по 1,5 часа) *",
        text: "Разные уровни сложности, изучаем детально техники лепки, обсуждаем вариации и элементы композиции, вдохновляясь на создание за рамками обычного.",
      },
      {
        img: "structure03.jpg",
        title: "3 - ФЛЮИД-АРТ",
        subtitle: "(бонусный урок, 1,5 часа) *",
        text: "Финальная, большая картина, выполненная с использованием пройденного материала и креативного способа создания фона.",
      },
      {
        img: "structure04.jpg",
        title: "4 - БОНУСЫ",
        text: "Расскажу где я ловлю вдохновение. Покажу, как и чем можно покрывать готовую картину. Узнаете полезные лайфхаки с пластилином.",
      },
    ];

    const faqData = [
      {
        title: "КАК СМОТРЕТЬ УРОКИ?",
        elements: [
          {
            type: "texts",
            items: [
              "Уроки выложены на нашей платформе в форме видео. К каждому уроку приложена аннотация с кратким описанием и тайм-кодами основных этапов. ",
              "Кроме того, к каждой картине, приложены файлы «Для вдохновения», где можно подсмотреть, как с данной темой справлялись другие. Вдохновляйтесь и фантазируйте.",
            ],
          },
        ],
      },
      {
        title: "КАКИЕ МАТЕРИАЛЫ ПОТРЕБУЮТСЯ?",
        elements: [
          {
            type: "texts",
            items: [
              "Использую самые простые и доступные материалы, которые можно найти в каждом доме: ",
            ],
          },
          {
            type: "unordered_list",
            items: [
              "4 листа картона (А4)",
              "Карандаш",
              "Линейка",
              "Стек (ножичек) / мастихин",
              "Кисточка с круглым концом",
              "Зубочистка",
              "Файл / плотный пакет",
              "Влажные салфетки",
            ],
          },
        ],
      },
      {
        title: "СКОЛЬКО ПЛАСТИЛИНА ПОКУПАТЬ?",
        elements: [
          {
            type: "texts",
            items: [
              "Начните с одной пачки. В процессе работы вы поймете расход материалов и какие цвета будут наиболее востребованы.",
              "",
              "ВАЖНО:",
            ],
          },
          {
            type: "unordered_list",
            items: [
              "Начинающим пластилинографам я рекомендую использовать восковой пластилин. От твердых сортов с непривычки могут разболеться пальцы и пропасть желание лепить.",
            ],
          },
        ],
      },
      {
        title: "КАК ДОЛГО СОХРАНИТСЯ ДОСТУП К КУРСУ?",
        elements: [
          {
            type: "texts",
            items: [
              "Доступ к курсу сохранится с вами навсегда. ",
              "",
              "ВАЖНО:",
            ],
          },
          {
            type: "unordered_list",
            items: [
              "Доступ к курсу возможна только зарегистрированным пользователям (техническая необходимость)",
              "Не забывайте заходить под своим аккаунтом. Если вы меняете устройство, убедитесь, что вы «вошли» на страницу - справа сверху должен быть значок войти/выйти",
            ],
          },
        ],
      },
      {
        title: "КАК ПРОИСХОДИТ ОБРАТНАЯ СВЯЗЬ?",
        elements: [
          {
            type: "texts",
            items: [
              "Я буду принимать ваши барельефы в мессенджере WhatsApp. Давать обратную связь и отвечать на возникающие вопросы. ",
              "",
              "* Обратная связь доступна в течение 1 месяца с момента оплаты.",
            ],
          },
        ],
      },
      {
        title: "БУДЕТ ЛИ СЕРТИФИКАТ ПО ОКОНЧАНИЮ?",
        elements: [
          {
            type: "texts",
            items: [
              "По итогам прохождения курса вы можете получить сертификат на русском и английском языках. Для этого нужно сдать 4 домашних задания (4 барельефа) и получить обратную связь.",
              "",
              "Два типа сертификатов:",
              "1.	«Серебряный» О том, что курс прослушан и домашние задания выполнены.",
              "2.	«Золотой». О том, что курс проработан на высокопрофессиональном уровне, рекомендовано включить полученные знания в систему обучения.",
            ],
          },
        ],
      },
      {
        title: "КАКИЕ МЕТОДЫ ОПЛАТЫ?",
        elements: [
          {
            type: "texts",
            items: [
              "Цена курса £60. Платеж единовременный.",
              "Оплата на сайте проходит через stripe (доступна в 46 странах). Система автоматически конвертирует любую валюту.",
              "",
              "* Если вы находитесь в РФ, свяжитесь со мной, я расскажу альтернативные способы оплаты.",
            ],
          },
          {
            type: "button",
            title: "» НАПИСАТЬ «",
            link: "https://wa.me/447507690706",
          },
        ],
      },
    ];

    let lessonPub = this.props.lessonPublic.find(
      (lesson) => lesson.id === "teachers_01"
    );
    let lessonPri = null;

    if (lessonPub && this.props.lessonPrivate.length > 0) {
      lessonPri = this.props.lessonPrivate.find(
        (lesson) => lesson.id === lessonPub.id
      );
    }

    return (
      <div className="main-wrapper">
        {/* Header */}
        <Header />
        <Styles>
          <section className="landing1">
            <Container>
              <Row>
                <Col md="8" sm="7" xs="12">
                  <div className="headersec">
                    <div>
                      <p className="line1 margintop">
                        Онлайн-курс международно признанного художника Надежды
                        Махнёвой
                      </p>
                      <p className="header margintop">
                        УНИКАЛЬНЫЕ ТЕХНИКИ ПЛАСТИЛИНА.
                      </p>
                      <p className="header">БАРЕЛЬЕФ.</p>
                      <p className="header marginbottom">от нуля до эксперта</p>
                    </div>
                    <p className="line3">
                      Практический курс по созданию картин из пластилина в
                      технике барельефной лепки.
                    </p>
                  </div>

                  <section className="landing1Button">
                    {lessonPri ? (
                      <Link to={process.env.PUBLIC_URL + "/lessons/teachers/"}>
                        <button className="discoverButton paidButton">
                          Оплачено
                        </button>
                      </Link>
                    ) : (
                      <button
                        onClick={this.onBuyClick}
                        className="discoverButton"
                      >
                        Купить
                      </button>
                    )}
                  </section>
                </Col>
                <Col
                  md="4"
                  sm="5"
                  xs="0"
                  className="coverpicarea align-self-center"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/teachers/20231225-firstpic01.png`
                    }
                    className="coverpic"
                    alt=""
                  />
                </Col>

              </Row>
              <Row>
                <Col className="coverpicareabottom">
                <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/teachers/20231225-firstpic01.png`
                    }
                    className="coverpicbottom"
                    alt=""
                  />
                </Col>

</Row>

            </Container>
          </section>
          <section className="aboutsec">
            <Container>
              <Row>
                <Col lg="4" sm="5" xs="0" className="aboutpicarea">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/teachers/20231225-me01.png`
                    }
                    className="aboutpic"
                    alt=""
                  />
                </Col>
                <Col lg="8" sm="7" xs="12" className="abouttextarea">
                  <h2>ОБ АВТОРЕ</h2>

                  <p className="abouttext">
                    Меня зовут Надежда Махнёва. Я – художник по пластилину,
                    педагог и мама. Годы работы с детьми и опыт художника по
                    пластилину, помогли мне накопить много полезных знаний и
                    приемов, которые я хочу передать вам в этом курсе.
                  </p>
                  <Container className="aboutTable">
                    <Row className="aboutRow">
                      {aboutData.map((data, index) => (
                        <Col md="6" sm="12" key={index} className="aboutItem">
                          <img
                            className="aboutItemImage"
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/teachers/icons/${data.img}`
                            }
                            alt={data.title}
                          />
                          <div className="aboutItemText">{data.text}</div>
                        </Col>
                      ))}
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
            <Wave left="-126.25%" width="300%" />
          </section>

          <section className="landing2" style={{ position: "relative" }}>
            <Container className="">
              <Row className="padding-bottom-20">
                <Col>
                  <h2 className="text-center">О ЧЕМ ЭТОТ КУРС</h2>
                </Col>
              </Row>
              <Row className="justify-content-center padding-bottom-20">
                <Col md="8" className="text-center">
                  <div style={{ display: "inline-block", textAlign: "left" }}>
                    <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                      {coursePoints.map((point, index) => (
                        <li
                          key={index}
                          style={{ marginBottom: "10px", fontSize: "16px" }}
                        >
                          <span style={{ marginRight: "10px" }}>
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/teachers/checked.png`}
                              width="18"
                              height="18"
                              style={{ flexShrink: 0 }}
                              alt=""
                            />
                          </span>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center padding-bottom-20">
                <Col md="8" className="text-center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/teachers/20231225-pic3.png`}
                    width="100%"
                    height="auto"
                    style={{ maxWidth: "600px" }}
                    alt="course works"
                  />
                </Col>
              </Row>

              <Row>
                {featureData.map((data, index) => (
                  <Col sm={4} key={index} className="mb-3">
                    <div className="d-flex flex-sm-column flex-row align-items-sm-center align-items-start text-sm-center text-left">
                      <div
                        style={{
                          width: "80px",
                          height: "80px",
                          borderRadius: "50%",
                          backgroundColor: colors.socials,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "10px",
                          flexShrink: 0,
                        }}
                      >
                        <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                          {data.iconText}
                        </span>
                      </div>
                      <div className="mt-2 ml-2 ml-md-0 w-100">
                        <h3 style={{ fontSize: "14px", marginBottom: "4px" }}>
                          {data.title}
                        </h3>
                        <p style={{ fontSize: "14px" }}>{data.text}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </section>

          <section
            style={{
              background: colors.socials,
              position: "relative",
              paddingTop: "80px",
            }}
          >
            <Wave left="-123%" width="237%" reversed />
            <Container>
              <h2 className="text-center">ДЛЯ КОГО ЭТОТ КУРС</h2>
              <Row
                style={{ paddingBottom: "20px", paddingTop: "20px" }}
                className="row-cols-1 row-cols-sm-3 g-3"
              >
                {featureBlocks.map((block, index) => (
                  <Col key={index} className="mb-3">
                    <div
                      className="d-flex flex-column align-items-center text-center p-3 h-100"
                      style={{
                        background: colors.white,
                        border: "1px solid #ddd",
                        borderRadius: "20px",
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/teachers/icons/${block.img}`}
                        alt={block.title}
                        width="64"
                        height="64"
                      />
                      <div className="mt-2 w-100 text-center">
                        <h3 style={{ fontSize: "14px", marginBottom: "4px" }}>
                          {block.title}
                        </h3>
                        <p style={{ fontSize: "14px" }}>{block.text}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <Row className="d-flex justify-content-center">
                <Col xs="auto">
                  {lessonPri ? (
                    <Link to={process.env.PUBLIC_URL + "/lessons/teachers/"}>
                      <button className="discoverButton paidButton">
                        Оплачено
                      </button>
                    </Link>
                  ) : (
                    <button
                      onClick={this.onBuyClick}
                      className="discoverButton"
                    >
                      Купить
                    </button>
                  )}
                </Col>
              </Row>
            </Container>
          </section>
          <section
            className="waveContainer"
            style={{
              background: colors.socials,
            }}
          >
            <Wave left="-144%" width="356%" />
          </section>

          <section style={{ position: "relative", paddingBottom: "40px" }}>
            <Container>
              <h2 className="sectionTitle text-center">СТРУКТУРА КУРСА</h2>
              <Row style={{ paddingTop: "20px" }}>
                {progData.map((block, index) => (
                  <Col
                    sm="6"
                    xs="12"
                    key={index}
                    className=" d-flex justify-content-center"
                  >
                    <div className="progBlock">
                      <div className="progImg">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/teachers/` +
                            block.img
                          }
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="progTextArea">
                        <h6>{block.title}</h6>
                        {block.subtitle && <p>{block.subtitle}</p>}
                        <p>{block.text}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <span style={{ fontSize: "12px" }}>
                * Время указано усредненно
              </span>
            </Container>
          </section>

          <section>
            <Container>
              <h2 className="text-center">ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ</h2>
              {faqData.map((block, index) => (
                <Row>
                  <div className="faq-item" key={index}>
                    <button
                      onClick={this.onFaqItemClick}
                      className={
                        block.active
                          ? "accordion-button active"
                          : "accordion-button"
                      }
                    >
                      <div className="accordion-icon">
                        <i
                          className={
                            block.active ? "las la-minus" : "las la-plus"
                          }
                        ></i>
                      </div>
                      <p>{block.title}</p>
                    </button>
                    <div
                      className={
                        block.active
                          ? "accordion-content show"
                          : "accordion-content"
                      }
                    >
                      {block.elements.map((element, i) => (
                        <div key={i} className="faq-item-line">
                          <div className={"element element" + element.type}>
                            {element.type === "texts" &&
                              element.items &&
                              element.items.map((item, i) => (
                                <p key={i}>{item}</p>
                              ))}

                            {element.type === "unordered_list" && (
                              <ul>
                                {element.items &&
                                  element.items.map((item, i) => (
                                    <li key={i}>{item}</li>
                                  ))}
                              </ul>
                            )}

                            {element.type === "button" && (
                              <Container className="d-flex justify-content-center align-items-center full-w">
                              <a href={element.link} target="_blank">
                                {element.title}
                              </a>

                              </Container>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Row>
              ))}
            </Container>
          </section>

          <section
            className="paymentSection"
            style={{ position: "relative", paddingTop: "140px" }}
          >
            <Wave left="-98%" width="222%" reversed />
            <h2>СТОИМОСТЬ КУРСА</h2>
            <div className="payment">
              <p className="price">£60</p>
              <ul>
                <li>19 онлайн-уроков</li>
                <li>6 часов записанного видео</li>
                <li>4 созданные картины</li>
                <li>Обратная связь на домашние задания</li>
                <li>Доступ к курсу навсегда</li>
                <li>Сертификат</li>
              </ul>
            </div>
            {lessonPri ? (
              <Link to={process.env.PUBLIC_URL + "/lessons/teachers/"}>
                <button className="discoverButton paidButton">Оплачено</button>
              </Link>
            ) : (
              <button onClick={this.onBuyClick} className="discoverButton">
                Купить
              </button>
            )}
          </section>

          <section
            className="waveContainer"
            style={{
              background: colors.socials,
            }}
          >
            <Wave left="-31%" width="153%" />
          </section>
          <section className="testimonialsSection">
            <h2 className="text-center">ОТЗЫВЫ</h2>
            <Container>
              <Row>
                <Col>
                  <Carousel
                    showArrows={true}
                    showStatus={false}
                    showIndicators={true}
                    showThumbs={false}
                  >
                    {[
                      {
                        image: "20230820LM.jpg",
                        text: "Темы и сюжеты, выбранные для уроков интересные, начиная с простого и заканчивая техникой пластилинового флюид-арта! Уроки могут выполнять даже новички, так как объяснение очень подробное и доступное. Очень приятно по окончании курса получить свою \"вкусную плюшку\" - золотой сертификат. Скажу, что для меня это было отличной мотивацией! Обязательно заберу в свою копилку интересные находки и приёмы, полученные на курсе!",
                        name: "Марина",
                      },
                      {
                        image: "20231130BP.jpg",
                        text: "Получила огромное удовольствие от предоставленных приёмов в лепке. С закатом, как будто порелаксировала, даже черновики выбрасывать не хочется. Работу выполнила на одном дыхании, ночью.",
                        name: "Полина",
                      },
                      {
                        image: "20240222AV.jpeg",
                        text: "Хочу поблагодарить Вас за удивительный курс в технике пластилиновый барельеф. Прекрасная подача уроков, масса положительных эмоций, и конечно же уникальные работы, которые, несомненно, понравятся деткам любых возрастов.",
                        name: "Виктория",
                      },
                      {
                        image: "20230802TM.jpg",
                        text: "Курс просто находка для начинающих педагогов. Всё понятно, доступно и интересно. Спасибо Надежде, что даёт возможность узнать и научиться чему то новому. Желаю творческих успехов в любых начинаниях!",
                        name: "Тамара",
                      },
                      {
                        image: "20230820AP.jpg",
                        text: "Курс невероятно познавательный. Качество, подача информации, отработка самых маленьких деталей на высшем уровне. Закончив курс и пройдя все этапы обучения, с нетерпением жду передать этот опыт деткам дальше. Спасибо!",
                        name: "Ада",
                      },
                      {
                        image: "20230609SB.jpg",
                        text: "Спасибо вам за интересный курс! Много техник взяла для своей работы с детьми. У меня маленькие 5-6 лет. Я заметила, что им больше нравится вылепливать фигурки, чем просто размазывать пластилин. А ваш барельеф - это те картины, работать с которыми малышам легко.",
                        name: "Сюзанна",
                      },
                    ].map((testimonial, index) => (
                      <div key={index} className="testimonialItem">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/teachers/${testimonial.image}`
                          }
                          alt={testimonial.name}
                          className="testimonialImage d-none d-sm-block"
                        />
                        <div className="testimonialContent">
                          <blockquote>{testimonial.text}</blockquote>
                          <cite className="testimonialName">
                            {testimonial.name}
                          </cite>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>

        {/* Footer */}
        <Footer />
          {/* <div dangerouslySetInnerHTML={{__html: `
          <!-- Meta Pixel Code -->
          <script>
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1507914883117753');
          fbq('track', 'PageView');
          </script>
          <noscript><img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=1507914883117753&ev=PageView&noscript=1"
          /></noscript>
          <!-- End Meta Pixel Code -->
           
          <!-- Yandex.Metrika counter -->
<script type="text/javascript" >
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(96135778, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });
</script>
<noscript><div><img src="https://mc.yandex.ru/watch/96135778" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
<!-- /Yandex.Metrika counter -->
          
          `   }}></div>       */}

      </div>
    );
  }
}

export default connect(mapStateToProps)(TeachersLanding);

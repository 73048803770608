import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Styles } from './styles/checkout_sf.js';
import i18n from '../../i18n';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        lessons: state.lesson.public,
    }
}

class LessonCheckoutSuccess extends Component {

    componentDidMount() {
        if (sessionStorage.getItem('checkout-stripe-session-id')){
            fetch('/.netlify/functions/validate-checkout-session',{
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({sessionId:sessionStorage.getItem('checkout-stripe-session-id')}),
            })
            .then((res) => res.json())
            .then(validation => {
                if (!validation.validated){
                    console.log('Payment session was not validated');
                }
                sessionStorage.removeItem('checkout-stripe-session-id')
            })
            .catch((error) => {
                console.log(error);
            })


        }

        setTimeout(() => {
            let lessonId = sessionStorage.getItem('checkout-lesson-id');
            let lessonPub = this.props.lessons.find(lesson => lesson.id === lessonId);
            if (lessonPub) {
                this.props.history.push(process.env.PUBLIC_URL + '/lessons/' + lessonPub.url);
            }
        }, 3000); 

    }


    render() {

        let lessonId = sessionStorage.getItem('checkout-lesson-id')
        let lessonPub = this.props.lessons.find(lesson => lesson.id === lessonId);

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper checkout-page">

                    {/* Header 2 */}
                    <Header />

                    {/* Gallery Area */}
                    <section className="checkout-success-page-area">
                        <Container>
                            <Row>
                                <Col md="12">

                                    <div className="checkout-message-box text-center">
                                        <h3>😊 {i18n.language === 'ru' ? 'Спасибо за покупку!' : 'Thanks for your purchase!'} 😊</h3>
                                        {i18n.language === 'ru'
                                            ? <p>Вы будете автоматически перенаправлены на страницу {(lessonId === 'teacher_01')?'курса':'урока'}. Если это не происходит - нажмите кнопку ниже.</p>
                                            : <p>You are going to be redirected on {(lessonId === 'teacher_01')?'course':'lesson'} page now. Press the button if it's not happening automatically.</p>
                                        }
                                        {lessonPub &&
                                            <Link to={process.env.PUBLIC_URL + '/lessons/' + lessonPub.url}> {i18n.language === 'ru' ? 'Перейти к урокам' : 'Go to lessons'}</Link>
                                        }

                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer*/}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default connect(mapStateToProps)(LessonCheckoutSuccess)
import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`

.sec-title {
    h4 {
        color        : ${colors.black1};
        line-height  : 35px;
        font-weight  : 600;
        max-width    : 550px;
        margin       : auto;
        margin-bottom: 42px;

        @media(max-width: 575px) {
            margin-bottom: 15px;
            font-size: 20px;
        }
    }
}


    .tab-section {
        background: ${colors.bg2};
        padding   : 70px 0 100px;

        .nav {
            background-color: #ffffff;
            border-radius : 5px;
            border  : 1px solid ${colors.border1};
            overflow: hidden;

            .nav-item {
                a.nav-link {
                    font-size    : 15px;
                    color        : ${colors.black1};
                    border-bottom: 1px solid ${colors.border1};
                    padding      : 18px 25px;

                    i {
                        color: ${colors.green};
                    }
                }

                a.nav-link.active {
                    background: ${colors.gr_bg};
                    color     : #ffffff;

                    i {
                        color: #ffffff;
                    }
                }

                &:last-child {
                    a.nav-link {
                        border-bottom: none;
                    }
                }
            }

            @media(max-width: 767px) {
                margin-bottom : 30px;
            }
        }

        .tab-content {
            box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
            background: ${colors.white};
            padding      : 10px 10px;
            .tab-pane {
                h4.tab-title {
                    color        : ${colors.black1};
                    font-weight  : 600;
                    margin-bottom: 25px;
                    padding-left: 15px;

                    @media(max-width: 575px) {
                        margin-bottom: 15px;
                        font-size: 20px;
                    }
                }

                .tab-desc {
                    font-size    : 15px;
                    color        : ${colors.text2};
                    line-height  : 30px;
                    margin-bottom: 25px;

                    @media(max-width: 575px) {
                        font-size: 14px;
                    }
                    
                    .tab-item-line {
                        font-size   : 14px;
                        color       : ${colors.text3};
                        line-height : 28px;
                        min-height  : 28px;
                        padding-left: 15px;
                    }
                }

                ul.check-list {
                    li {
                        font-size    : 15px;
                        color        : ${colors.text3};
                        margin-bottom: 20px;
                        line-height  : 25px;

                        i {
                            float : left;
                            color : ${colors.green};
                            border: 1px solid ${colors.border3};
                            width : 35px;
                            height: 35px;
                            border-radius : 50%;
                            text-align  : center;
                            padding-top : 9px;
                            margin-top  : 7px;
                            margin-right: 15px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }

                        @media(max-width: 575px) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .contact-btn {
            a {
                font-size     : 15px;
                color         : #fff;
                background    : ${colors.gr_bg};
                display       : inline-block;
                width         : 200px;
                height        : 48px;
                text-transform: uppercase;
                font-weight   : 500;
                text-align    : center;
                padding       : 14px;
                border-radius : 5px;
                margin-top: 20px;

                &:hover {
                    background: ${colors.gr_bg2};
                }

                @media(max-width: 575px) {
                    font-size: 13px;
                    width: 170px;
                    height: 42px;
                    padding: 12px;
                    margin-top: 10px;
                }
            }
        }

        @media(max-width: 767px) {
            padding: 35px 0 30px;
        }
    }
`;
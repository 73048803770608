import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const loadPublic = createAsyncThunk(
    'lesson/loadPublic',
    async (drafts) => {
        return fetch('/.netlify/functions/lessons' + (drafts?"?drafts=true":""))
        .then(res => res.json());

    }
);

export const loadPrivate = createAsyncThunk(
    'lesson/loadPrivate',
    async (params) => {
        
        return fetch('/.netlify/functions/lesson-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + params.token,
            }
        })
            .then(res => res.json())
    }
);

const lessonSlice = createSlice({
    name: 'lesson',
    initialState: {
        statusPublic: "",
        statusPrivate: "",
        public: [],
        private: [],
    },

    extraReducers: {
        [loadPublic.pending]: (state) => {
            state.statusPublic = 'loading';
        },
        [loadPublic.fulfilled]: (state, action) => {
            state.public = action.payload;
            state.statusPublic = 'success';
        },
        [loadPublic.rejected]: (state) => {
            state.statusPublic = 'failed';
        },
        [loadPrivate.pending]: (state, action) => {
            state.statusPrivate = 'loading';
        },
        [loadPrivate.fulfilled]: (state, action) => {
            state.private = action.payload;
            state.statusPrivate = 'success';
        },
        [loadPrivate.rejected]: (state, action) => {
            state.statusPrivate = 'failed';
        },
    }
})

export default lessonSlice.reducer
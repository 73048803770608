import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import authReducer from './authSlice'
import lessonReducer from './lessonSlice'
import refsReducer from './refSlice'
import coursesReducer from './coursesSlice'



const reducer = combineReducers({
  auth: authReducer,
  lesson: lessonReducer,
  refs: refsReducer,
  courses: coursesReducer,
})
const store = configureStore({
  reducer,
})
export default store;
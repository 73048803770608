import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const loadCourses = createAsyncThunk(
    'courses/loadCourses',
    async () => {
        return fetch('/.netlify/functions/courses')
        .then(res => res.json());

    }
);



const coursesSlice = createSlice({
    name: 'courses',
    initialState: {
        status: "",
        courses: [],
    },

    extraReducers: {
        [loadCourses.pending]: (state) => {
            state.status = 'loading';
        },
        [loadCourses.fulfilled]: (state, action) => {
            state.courses = action.payload;
            state.status = 'success';
        },
        [loadCourses.rejected]: (state) => {
            state.status = 'failed';
        },
    }
})

export default coursesSlice.reducer
import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .lessons-page {
        .lessons-area {
            padding : 20px 0;
            background: ${colors.socials};


            .lesson-sidebar{
            
                
                
            
            }


            .lesson-items {

                .lesson-box {
                    margin-bottom: 10px;
                    .lesson-img {
                        a {
                            img {
                                border-radius : 5px;
                            }
                        }
                    }

                    .lesson-image {
                        width              : 240px;
                        height             : 190px;
                        background-size    : cover;
                        background-position: center;
                        background-repeat  : no-repeat;
                        border-radius : 5px;
                        position: relative;



                        .lesson-price {
                            p {
                                font-size  : 16px;
                                color      : #ffffff;
                                background : ${colors.gr_blue};
                                position   : absolute;
                                right      : 20px;
                                bottom     : 20px;

                                padding    : 8px 10px;
                                font-weight: 500;
                                border-radius : 5px;

                            }
                        }

                    @media(max-width: 991px) {
                        width: 100%;
                    }
                }

                    .lesson-content {

                        .lesson-box {
                            box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                            padding   : 20px; 
                            margin-bottom: 10px;
                            background: #ffffff;
                            border-radius : 5px;

                            .lesson-title{
                                h6 {
                                    margin-bottom: 10px;
                                    a {
                                        color      : ${colors.black1};
                                        font-weight: 600;

                                        &:hover {
                                            color: ${colors.green};
                                        }
                                    }
                                }
                            }

                            .lesson-time-location {
                                margin-bottom : 10px;
                                ul {
                                    li {
                                        list-style: inside;
                                        font-size : 13px;
                                        color: ${colors.text3};
                                        i {
                                            font-size : 18px;
                                            color: ${colors.green};
                                            vertical-align: top;
                                        }
                                        &:first-child {
                                            margin-right : 20px;
                                        }
                                    }
                                }
                            }


                            .join-btn {
                                padding: 9px 12px 7px;
                                a {
                                    font-size : 12px;
                                    color         : #fff;
                                    background    : ${colors.gr_bg};
                                    border: 1px solid ${colors.border3};
                                    font-weight : 500;
                                    text-transform : uppercase;
                                    padding: 9px 12px 7px 9px; 
                                    border-radius: 5px;
                                    white-space: nowrap;
                                    cursor   : pointer;
                                    &:hover {
                                        background: ${colors.gr_bg2};
                                    }

                                    @media(max-width: 767px) {
                                        float: left;
                                        padding: 5px 10px 3px;
                                    }
                                }
                            }

                            .buy-btn {
                                padding: 9px 12px 7px;
                                a {
                                    font-size : 12px;
                                    color         : #fff;
                                    background    : ${colors.gr_yl};
                                    border: 1px solid ${colors.border3};
                                    font-weight : 500;
                                    text-transform : uppercase;
                                    padding: 9px 12px 7px 9px;
                                    border-radius: 5px;
                                    white-space: nowrap;
                                    cursor   : pointer;
                                    &:hover {
                                        background: ${colors.gr_yl2};
                                    }

                                    @media(max-width: 767px) {
                                        float: left;
                                        padding: 5px 10px 3px;
                                    }
                                }
                            }

                        }
                    }
                }

                ul.pagination-box {
                    margin-top: 50px;
                }


            }
        }
    }


    .lesson-filter {
        background: ${colors.white};
        border : 1px solid ${colors.border1};
        padding: 15px 20px 20px;
        border-radius : 5px;
        margin-bottom : 30px;
        h5 {
            color : ${colors.black1};
            font-weight : 600;
            padding-bottom: 10px;
            margin-bottom: 20px;
            position : relative;
            &:before {
                position : absolute;
                content : "";
                background : ${colors.green};
                width : 50px;
                height: 2px;
                bottom : 0;
                left : 0;
            }

            @media(max-width: 575px) {
                font-size  : 17px;
            }
        }
        ul.filter-item {
            li.check-btn {
                border-top : 1px dashed ${colors.border3};
                padding: 10px 0;

                &:last-child {
                    border-bottom : 1px dashed ${colors.border3};
                }

                label {
                    font-size: 14px;
                    color: ${colors.text3};
                    display: block;
                    margin-bottom : 4px;
                    cursor: pointer;

                    input[type=checkbox] {
                        border : 2px solid ${colors.border3};
                        appearance: none;
                        width: 18px;
                        height: 18px;
                        cursor: pointer;
                        margin-right: 6px;
                        position: relative;
                        top: 4px;

                        &:focus {
                            outline: none;
                        }

                        &:checked {
                            background-color: ${colors.green};
                            background: ${colors.green} url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 2px 2px no-repeat;
                            border-color : ${colors.green};
                        }
                    }

                    span {
                        float : right;
                        font-size: 12px;
                        color: ${colors.text2};
                        line-height: 27px;
                    }
                }
            }
        }

        @media(max-width: 1199px) {
            padding: 12px 15px 15px;
        }
    }
`;
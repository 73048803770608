import React, { useEffect } from 'react';
import { Styles } from '../styles/courseTag.js';
import Datas from '../../../data/course/tags.json';
import i18n from '../../../i18n'

// todo - tags are switched to getting from API, need to redo before going into this

function CourseTag() {
    useEffect(() => {
      
        const buttons = document.querySelector(".tags-btn-list").children;
        
        let onTagClick = function () {
            const tagId = this.getAttribute("tag-id");

            if (localStorage.getItem("filter-tag-"+tagId)){
                localStorage.removeItem("filter-tag-"+tagId);
                this.classList.remove("active");
            }else{
                localStorage.setItem("filter-tag-"+tagId,tagId);
                this.classList.add("active");
            }

        };

        for (let i = 0; i < buttons.length; i++) {
            
            if (localStorage.getItem("filter-tag-"+buttons[i].getAttribute("tag-id"))){
                buttons[i].classList.add("active");
            }

            if (!buttons[i].hasAttribute("data-event-onclick")){
                buttons[i].addEventListener("click", onTagClick);
                buttons[i].setAttribute("data-event-onclick","true");
            }
            
        }
    });

        return (
            <Styles>
                {/* Course Tag */}
                <div className="course-tag">
                    <h5>{Datas.secTitle[i18n.language]}</h5>
                    <div className="tag-box">
                    <ul className="tags-btn-list list-unstyled list inline">
                        {/* {
                            readTags.map((data,i) => (
                                <li key={data.id} tag-id={data.id} className = "list-inline-item">{data.name[i18n.language]}</li>
                            ))

                        } */}
                       </ul>
                    </div>
                </div>
            </Styles>
        )
    }


export default CourseTag

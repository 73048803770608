import React, { Component } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { connect } from "react-redux";
import { colors } from "../../components/common/element/elements";
import { Col, Container, Row } from "react-bootstrap";
import i18n from "../../i18n";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Styles } from "./styles/account";

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    lessonPublic: state.lesson.public,
    lessonPrivate: state.lesson.private,
  };
};

class Account extends Component {
  render() {
    return (
      <div className="main-wrapper">
        {/* Header */}
        <Header />

        <Styles>
          <section style={{ background: colors.socials }}>
            <Container
              style={{ minHeight: 200, padding: "30px 0 30px" }}
              className="lesson-items"
            >
              <div className="text-center" style={{ paddingBottom: 30 }}>
                <h4>{i18n.language === "ru" ? "Мои курсы" : "My courses"}</h4>
              </div>

              {(!this.props.token || !this.props.lessonPrivate || !this.props.lessonPublic) && (
                <div className="text-center" style={{ paddingBottom: 30 }}>
                  <p>
                    {i18n.language === "ru"
                      ? "Пожалуйста, подождите, пока курсы загружаются. Если это сообщение отображается более 30 секунд - возможно вы не авторизованы. Пожалуйста, используйте кнопку входа в правом верхнем углу, чтобы получить доступ к вашим курсам."
                      : "Please wait until courses are loaded. If you see this message longer than 30 seconds - you are not authenticated. Please use the login button in the top right corner to access your courses."}
                  </p>
                  {/* <p>
                    {i18n.language === "ru"
                      ? "Пожалуйста, подождите, пока курсы загружаются. Если это сообщение отображается более 30 секунд - выйдите из системы и войдите снова."
                      : "Please wait until courses are loaded. If you see this message longer than 30 seconds - log out and log in again."}
                  </p> */}
                </div>
              )}

              {this.props.lessonPrivate &&
                this.props.lessonPublic
                  .filter((publicLesson) =>
                    this.props.lessonPrivate.some(
                      (privateLesson) => privateLesson.id === publicLesson.id
                    )
                  )
                  .sort((a, b) =>
                    !a.date && a.date !== 0
                      ? -1
                      : !b.date && b.date !== 0
                      ? 1
                      : new Date(b.date) - new Date(a.date)
                  )
                  .map((data, i) => (
                    <div className="lesson-box" key={i}>
                      <Row>
                        <Col xl="4" lg="4">
                          <Link
                            to={process.env.PUBLIC_URL + "/lessons/" + data.url}
                          >
                            <div
                              className="lesson-image"
                              style={{
                                backgroundImage: `url(${
                                  data.image
                                    ? data.image.url
                                    : "https://via.placeholder.com/150x100"
                                })`,
                              }}
                            ></div>
                          </Link>
                        </Col>
                        <Col xl="8" lg="8">
                          <div className="lesson-content">
                            <div className="lesson-box">
                              <Row>
                                <Col xl="9" lg="9">
                                  <div className="lesson-title">
                                    <h6>
                                      <Link
                                        to={
                                          process.env.PUBLIC_URL +
                                          "/lessons/" +
                                          data.url
                                        }
                                      >
                                        {" "}
                                        {data.title}
                                      </Link>
                                    </h6>
                                  </div>
                                  <div className="lesson-time-location">
                                    <ul className="list-unstyled list-inline">
                                      {data.group && (
                                        <li className="list-inline-item">
                                          <i className="las la-user-friends"></i>{" "}
                                          {data.group.name}{" "}
                                        </li>
                                      )}
                                      {data.date && (
                                        <li className="list-inline-item">
                                          <i className="las la-calendar"></i>
                                          {data.date
                                            ? new Date(
                                                data.date
                                              ).toLocaleString(["ru-RU"], {
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                              })
                                            : "to be confirmed"}
                                        </li>
                                      )}
                                      {data.duration && (
                                        <li className="list-inline-item">
                                          <i className="las la-clock"></i>
                                          {new Date(data.duration * 1000)
                                            .toISOString()
                                            .substr(11, 5)}
                                        </li>
                                      )}
                                      {data.complexity &&
                                        this.props.complexity &&
                                        this.props.complexity.find(
                                          (elem) => elem.id === data.complexity
                                        ) && (
                                          <li className="list-inline-item">
                                            <i className="las la-user-cog"></i>
                                            {
                                              this.props.complexity.find(
                                                (elem) =>
                                                  elem.id === data.complexity
                                              ).title[i18n.language]
                                            }
                                          </li>
                                        )}
                                    </ul>
                                  </div>
                                  <div className="lesson-desc">
                                    <p>{data.shortdesc}</p>
                                  </div>
                                </Col>
                                <Col xl="3" lg="3" className="text-left">
                                  <div className="join-btn">
                                    <Link
                                      to={
                                        process.env.PUBLIC_URL +
                                        "/lessons/" +
                                        data.url
                                      }
                                    >
                                      {data.free
                                        ? i18n.language === "ru"
                                          ? "Доступно"
                                          : "Free"
                                        : i18n.language === "ru"
                                        ? "Оплачено"
                                        : "Available"}
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ))}
            </Container>
          </section>
        </Styles>
        {/* Footer */}
        <Footer />
      </div>
    );
  }
}

export default connect(mapStateToProps)(Account);

import styled from "styled-components";
import { colors } from "../element/elements.js";

export const Styles = styled.div`
  
    .copyright-area {
        background : ${colors.copy_bg};
        border-top : 1px solid ${colors.black2};
        padding-bottom : 30px;

        .copyright-text {
        
            p {
                font-size   : 14px;
                color       : ${colors.text3};
                margin      : 25px;

                i {
                    color : ${colors.green};
                    margin: 0 2px;
                }

                a {
                    color: ${colors.green};

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }


            ul.social {
                li {
                    a {
                        text-align: center;
                        position  : relative;
                        height    : 17px;
                        display   : inline-block;

                        &:before {
                            content           : "";
                            position          : absolute;
                            border-width      : 9px 17px;
                            border-style      : solid;
                            border-top-color  : transparent;
                            border-right-color: transparent;
                            border-left-color : transparent;
                            top               : -18px;
                            left              : 0;
                            z-index           : 1;
                            transition : all 0.2s ease;
                        }

                        &:after {
                            content            : "";
                            position           : absolute;
                            border-width       : 9px 17px;
                            border-style       : solid;
                            border-right-color : transparent;
                            border-bottom-color: transparent;
                            border-left-color  : transparent;
                            bottom             : -18px;
                            left               : 0;
                            z-index            : 1;
                            transition : all 0.2s ease;
                        }

                        i {
                            font-size: 14px;
                            color    : #ffffff;
                            width    : 34px;
                        }

                        &:hover {
                            background-color: ${colors.green} !important;

                            &:before {
                                border-bottom-color: ${colors.green} !important;
                            }

                            &:after {
                                border-top-color: ${colors.green} !important;
                            }
                        }
                    }

                    &:nth-child(1) {
                        a {
                            background-color: #F58529;

                            &:before {
                                border-bottom-color: #F58529;
                            }

                            &:after {
                                border-top-color: #F58529;
                            }
                        }
                    }

                    &:nth-child(2) {
                        a {
                            background-color: #4267B2;

                            &:before {
                                border-bottom-color: #4267B2;
                            }

                            &:after {
                                border-top-color: #4267B2;
                            }
                        }
                    }

                    &:nth-child(3) {
                        a {
                            background-color: #DD1343;

                            &:before {
                                border-bottom-color: #DD1343;
                            }

                            &:after {
                                border-top-color: #DD1343;
                            }
                        }
                    }

                }
            }

            
            @media(max-width: 767px) {
                text-align : center;
                margin-bottom: 20px;
            }
        }


    }
`;
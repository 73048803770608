import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
    .about-us2 {
        background: ${colors.gr_grey};
        padding   : 50px 0 30px;

        .about-content {
            h4.about-title {
                color        : ${colors.white};
                line-height  : 30px;
                font-weight  : 400;
                margin-bottom: 25px;
                text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.17);
                @media(max-width: 575px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }

            p.about-para {
                font-size    : 16px;
                color        : ${colors.white};
                line-height  : 28px;
                margin-bottom: 30px;

                @media(max-width: 991px) {
                    font-size    : 14px;
                }
            }

            
        }

        .about-image {
            position: relative;


            img.main-img1 {
                width:100%;
                min-width:200px;
                
                @media(max-width: 991px) {
                    bottom: 42%;
                }
            }

           
            @media(max-width: 767px) {
                display : none;
            }
        }

        @media(max-width: 767px) {
            padding : 30px 0 20px;
        }
        
        .viewfaq-btn {
            a {
                font-size     : 15px;
                color         : #fff;
                background    : ${colors.gr_bg};
                display       : inline-block;
                width         : 280px;
                height        : 48px;
                text-transform: uppercase;
                font-weight   : 300;
                text-align    : center;
                padding       : 14px;
                border-radius : 5px;
                margin-top: 20px;

                &:hover {
                    background: ${colors.gr_bg2};
                }

                @media(max-width: 575px) {
                    font-size: 13px;
                    width: 230px;
                    height: 42px;
                    padding: 12px;
                    margin-top: 10px;
                }
            }
        }
    }
`;
import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Styles } from './styles/checkout_sf.js';
import i18n from '../../i18n';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    return {
        lessons: state.lesson.public,
    }
}

class LessonCheckoutFail extends Component {

    componentDidMount() {
        if (sessionStorage.getItem('checkout-stripe-session-id')){
            sessionStorage.removeItem('checkout-stripe-session-id')
        }
    }


    render() {

        let lessonId = sessionStorage.getItem('checkout-lesson-id')
        let lessonPub = this.props.lessons.find(lesson => lesson.id === lessonId);

        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper checkout-page">

                    {/* Header 2 */}
                    <Header />

                    {/* Gallery Area */}
                    <section className="checkout-success-page-area">
                        <Container>
                            <Row>
                                <Col md="12">

                                    <div className="text-center">
                                        <h3>😔 {i18n.language === 'ru' ? 'Что-то пошло не так...' : 'Something goes wrong...'} 😔</h3>
                                        {i18n.language === 'ru'
                                            ? <p>Попробуйте оплатить позднее или использовать другой метод оплаты </p>
                                            : <p>Please try again later or use different payment card</p>
                                        }
                                                                                {i18n.language === 'ru'
                                            ? <p>☝Если вы не можете провести оплату -
                                            <a
                                              href="https://wa.me/447507690706"
                                              target="_blank"
                                              className="inlineLink"
                                            >
                                              свяжитесь со мной
                                            </a>
                                            для альтернативного способа оплаты.</p>
                                            : <p>☝If you are unable to complete a payment -
                                            <a
                                              href="https://wa.me/447507690706"
                                              target="_blank"
                                              className="inlineLink"
                                            >contact me</a>
                                            for an alternative payment method.</p>
                                        }
                                        {lessonPub &&
                                            <Link to={process.env.PUBLIC_URL + '/checkout/'}> {i18n.language === 'ru' ? 'Оплатить' : 'Checkout'}</Link>
                                        }

                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </section>

                    {/* Footer*/}
                    <Footer />

                </div>
            </Styles>
        )
    }
}

export default connect(mapStateToProps)(LessonCheckoutFail)
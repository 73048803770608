import React, { Component, useState } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Styles } from './styles/checkout.js';
import i18n from '../../i18n';
import { connect } from 'react-redux';
import { Auth0Context } from '@auth0/auth0-react';
import { loadStripe } from '@stripe/stripe-js';


const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth.token,
        lessons: state.lesson.public,
        groups: state.refs.groups
    }
}

class LessonCheckout extends Component {

    constructor(props) {
        super(props);
        
    }

    static contextType = Auth0Context;

    state = {
        showLoginModal: false,
        loading: false,
    }



    openLoginModal = () => this.setState({ showLoginModal: true, loading: this.state.loading })
    closeLoginModal = () => this.setState({ showLoginModal: false, loading: this.state.loading })


    onBuyButtonClick = function (e) {
        

        if (!this.props.isAuthenticated) {
            this.openLoginModal()
            return;
        }

        let action = e.target.getAttribute("action")
 
        const cartDetails = {}
        if (action === 'buy-lesson'){
            let lesson = this.props.lessons.find(lesson => lesson.id === e.target.getAttribute("lessonid"));
            if (lesson){
                cartDetails.lessonId = lesson.id
                cartDetails.description = (i18n.language==='ru'?'Урок: ':'Lesson: ') + lesson.title
                if (lesson.id === "teachers_01"){
                    cartDetails.description = (i18n.language==='ru'?'Курс для учителей: ':'Course for teachers: ') + lesson.title
                }
                if (lesson.price){
                    cartDetails.priceId = process.env.REACT_APP_STRIPE_ENV_TEST?lesson.price.testStripeId:lesson.price.prodStripeId
                }
            }
        }

        if (action === 'buy-group'){
            let group= this.props.groups.find(group => group.id === e.target.getAttribute("groupid"));
            if (group){
                cartDetails.groupId = group.id
                cartDetails.description = (i18n.language==='ru'?'Блок уроков: ':'Lessons block: ') + group.name[i18n.language]
                cartDetails.priceId = process.env.REACT_APP_STRIPE_ENV_TEST?group.price.testStripeId:group.price.prodStripeId
            }
        }



        if (cartDetails.priceId && cartDetails.description && (cartDetails.groupId || cartDetails.lessonId)){
            // ready to go checkout

            e.preventDefault();
            this.setState({ showLoginModal: this.state.showLoginModal, loading: true });
    
    
            fetch(
                '/.netlify/functions/create-checkout-session',
                {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.props.token
                    },
                    body: JSON.stringify(cartDetails),
                }
            )
                .then((res) => {
                    return res.json();
                })
                .then(async session => {
                    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
                    this.setState({ showLoginModal: this.state.showLoginModal, loading: false });
                    if (session.sessionId){
                        sessionStorage.setItem('checkout-stripe-session-id',session.sessionId)
                        stripe.redirectToCheckout(session)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ showLoginModal: this.state.showLoginModal, loading: false });
                });


        }

    }

    render() {
        const { loginWithRedirect } = this.context;

        let lessonId = sessionStorage.getItem('checkout-lesson-id')
        let lessonPub = this.props.lessons.find(lesson => lesson.id === lessonId);  

        // if lesson not found - redirect to lesson list 
        if (this.props.lessons.length > 0 && !lessonPub) {
            this.props.history.push('/lessons')
        }

        return (
          <Styles>
            {/* Main Wrapper */}
            <div className="main-wrapper checkout-page">
              {/* Header 2 */}
              <Header />

              {/* Gallery Area */}
              <section className="checkout-page-area">
                <Container>
                  <Row>
                    <Col md="12">
                      <div className="checkout-description-box text-center">
                        <h3>
                          {i18n.language === "ru"
                            ? "Как получить доступ?"
                            : "How to get an access?"}
                        </h3>
                        {i18n.language === "ru" ? (
                          <p>
                            Для получения доступа к записи {lessonId === "teachers_01" ? "курса" : "урока"} необходимо :
                            <br />
                            <br /> ✔️Зарегистрироваться на сайте и войти в свой
                            аккаунт
                            <br />
                          </p>
                        ) : (
                          <p>
                            For watching the lessons it's necessary:
                            <br />
                            <br /> ✔️Register on website and sign in
                            <br />
                          </p>
                        )}
                        {i18n.language === "ru" ? (
                        <p>
                        ✔️ Оплатить картой с помощью системы
                        Stripe, которая принимает карты выпущенные в 46
                        странах.
                        <br />
                        ☝Если вы не можете провести оплату -
                        <a
                          href="https://wa.me/447507690706"
                          target="_blank"
                          className="inlineLink"
                        >
                          свяжитесь со мной
                        </a>
                        для альтернативного способа оплаты.
                      </p>
                        ) : (
                            <p>
                            ✔️ Pay by card using the Stripe system, which accepts cards issued in 46 countries.
                            <br />
                            ☝If you are unable to complete a payment -
                            <a
                              href="https://wa.me/447507690706"
                              target="_blank"
                              className="inlineLink"
                            >contact me</a>
                            for an alternative payment method.
                          </p>
                        )}

                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Modal
                        show={this.state.showLoginModal}
                        onHide={this.closeLoginModal}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            {i18n.language === "ru"
                              ? "внимание"
                              : "log in required"}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>
                            {i18n.language === "ru"
                              ? "сначала необходимо зарегистрироваться на сайте и войти в свой аккаунт"
                              : "it's necessary to register on website and sign in first"}
                          </p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="primary"
                            onClick={() =>
                              loginWithRedirect({
                                appState: {
                                  returnTo: window.location.pathname,
                                },
                                ui_locales: i18n.language,
                              })
                            }
                          >
                            {i18n.language === "ru"
                              ? "войти / зарегистрироваться"
                              : " sign in / register"}
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={this.closeLoginModal}
                          >
                            {i18n.language === "ru" ? "Закрыть" : "Close"}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="12" md="12">
                      <ul className="pricing p-yel">
                        <li key="10">
                          <i className="far fa-thumbs-up"></i>
                          <big>
                            {" "}
                            {lessonId === "teachers_01"
                              ? "Курс для учителей"
                              : "Урок"}
                          </big>
                          <div className="pricing-medium">
                            {lessonPub ? lessonPub.title : ""}
                          </div>
                        </li>
                        <li key="12">
                          {lessonId === "teachers_01"
                            ? "Доступ ко всем урокам курса"
                            : "Доступ к записи прошедшего урока"}
                        </li>
                        <li key="14">
                          <h3>
                            £
                            {lessonPub &&
                              lessonPub.price &&
                              lessonPub.price.amount}
                          </h3>
                          <span>
                            {lessonId === "teachers_01"
                              ? "За полный курс"
                              : "За урок"}
                          </span>
                        </li>
                        <li key="15">
                          <button
                            disabled={!lessonPub || this.state.loading}
                            onClick={this.onBuyButtonClick.bind(this)}
                            action="buy-lesson"
                            lessonid={lessonPub ? lessonPub.id : ""}
                          >
                            Купить
                          </button>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Container>
              </section>

              {/* Footer*/}
              <Footer />
            </div>
          </Styles>
        );
    }
}

export default connect(mapStateToProps)(LessonCheckout)
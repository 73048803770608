export const colors = {

    bg1: "#182B49",
    bg2: "#F6F9FF",
    gr_bg: "linear-gradient(90deg, #11B67A 0%, #009444 100%)",
    gr_bg2: "linear-gradient(90deg, #009444 0%, #11B67A 100%)",
    gr_yl: "linear-gradient(90deg, #ff6f6f 0%, #ee2236 100%)",
    gr_yl2: "linear-gradient(90deg, #ee2236 0%, #ff6f6f 100%)",

    gr_blue: "linear-gradient(#333399 0%, #182B49 100%)",
    gr_grey: "linear-gradient(to right, #9E9993, #4a4946)",
    gr_grey_rev: "linear-gradient(to right, #4a4946,#9E9993)",
    grey_dark: "#4a4946",
    grey_light: "#9E9993",
    copy_bg: "#122340",
    socials: "#f4f3f3",
    blue: "#2c97ea",
    green: "#11B67A",
    green2: "#00a76a",
    red: "#ff6f6f",
    purple: "#84479c",
    yellow: "#fbab19",
    orange: "#da7456",
    black: "#000000",
    black1: "#182B49",
    black2: "#444444",
    white: "#ffffff",
    text1: "#555555",
    text2: "#666666",
    text3: "#969696",
    text4: "#aaaaaa",
    text5: "#cccccc",
    border1: "#eeeeee",
    border2: "#3e3e3e",
    border3: "#dddddd",
    footer1: "#1a1b25",
    footer2: "#16171f",
    ftext: "#8D8E92",
    custom1: "#17a2b8",

}

export const fonts = {

    roboto: "'Roboto Light', sans-serif-light",
    poppins: "'Poppins', sans-serif",
    playfair: "'Playfair Display Regular', sans-serif",
    carattere: "'Carattere', cursive",
}